import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../common/icons/menu.svg';
import MenuIcon from '@mui/icons-material/Menu';
import Person2Icon from '../../common/icons/profile-png-5.png';
import LogoIcon from '../../common/icons/Group 141.svg';
import Separator from '../../common/ui/Separator';
import CustomButton from '../../common/ui/CustomButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES, AUTH_ROUTES } from '../../common/constants/Routes';
import SvgConverter from '../../common/SvgConverter';
import { UserLogoutInProgress } from '../../common/redux/auth/action/authAction';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
const TopHeader = ({
  setDrawerOpen = () => {},
  setNotificationDrawer = () => {},
  contentHeight = 0,
}: {
  setDrawerOpen?: Function;
  setNotificationDrawer?: Function;
  contentHeight?: number | undefined;
}) => {
  const dispatch=useDispatch()
  const {t}=useTranslation()
  const theme = useTheme();
  const navigate = useNavigate();
  const handleLogOff = () => {
    localStorage.removeItem('user');
    navigate(APP_ROUTES.DASHBOARD);
    window.location.reload();
    dispatch(UserLogoutInProgress())
  };
  const name =localStorage.getItem("firstName")
  return (
    <Grid container className='dashBoard-header-menu  hide-on-print'>
      <Grid
        item
        className='mr-10 ml-15 menu-bar'
        display={'flex'}
        alignItems={'center'}
      >
        <IconButton
          // color='inherit'
          aria-label='open drawer'
          onClick={() => setDrawerOpen(true)}
          edge='start'
        >
          <MenuIcon />
        </IconButton>
      </Grid>
      <Grid item flex={1} className='mr-20'>
        <Grid
          item
          justifyContent={'space-between  '}
          className='header-profile'
          alignItems={'center'}
        >
          <Grid className='logo-icon mt-5 '>
            {/* <SvgConverter Icon={LogoIcon} /> */}
            <img src={LogoIcon} alt='logo' className='img-logo' />
          </Grid>
          <Separator
            color={theme.palette.primary.main}
            className='profile-res1 '
          />
          <Grid
            item
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid className='profile-img mt-5 mr-20'>
              <img src={Person2Icon} alt='logo' className='img-logo' />
            </Grid>

            <Grid my={1}>
              <Typography
                color={theme.palette.common.black}
                display={'flex'}
                fontSize={14}
                whiteSpace={'nowrap'}
              >
                Sivabakiam{' '}
                <Typography
                  color={theme.palette.common.black}
                  fontStyle={'italic'}
                  fontSize={14}
                >
                  ({name}  )
                </Typography>
              </Typography>

              <Link to={'#'} onClick={handleLogOff}>
                <Typography
                  display={'flex'}
                  alignItems={'center'}
                  color={theme.palette.grey[600]}
                  fontSize={14}
                >
                  {t("Log_Out")} &nbsp; <PowerSettingsNewIcon className='f-15 mt-2' />
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Separator color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  );
};

export default TopHeader;
