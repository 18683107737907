import React from 'react';
import VolunteerForm from './VolunteerForm';
import { useForm } from 'react-hook-form';

const AddVolunteer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      contact: '',
      gender: '',
      dob: '',
      bloodGroup: '',
      address: '',
    },
  });
  return (
    <div>
      <VolunteerForm
        text={'Add Volunteer'}
        reset={reset}
        errors={errors}
        control={control}
        register={register}
        handleSubmit={handleSubmit}
        setValue={setValue}
      />
    </div>
  );
};

export default AddVolunteer;
