import { message } from "antd";

const Message = ({ type, content }) => {
  switch (type) {
    case "success":
      {
        message.success(content, [2.5]);
       
      }
      break;

    case "error":
      {
        message.error(content, [2.5]);
      }

      break;
    case "info":
      {
        message.info(content, [2.5]);
      }

      break;
    case "warning":
      {
        message.warning(content, [2.5]);
      }

      break;
    case "loading":
      {
        message.loading(content, [2.5]);
      }

      break;
    default:
      {
        message.info(content, [2.5]);
      }
      break;
  }
};
export default Message;
