import React from 'react';
import CommonModal from './CommonModal';
import CustomButton from '../CustomButton';
import { Grid, Typography } from '@mui/material';
import { deleteDONERInProgress, getDonerListInProgress } from '../../redux/doner/action/donerAction';
import { useDispatch } from 'react-redux';
import { deleteTeamMemberInProgress, getTeamMemberListInProgress } from '../../redux/teamMembers/action/teamMemberAction';
import { deleteROLESInProgress, getRolesListInProgress } from '../../redux/roles/action/rolesAction';

const DeleteModel = ({ open, setOpen ,success,id,type,userId}: { open: any; setOpen: any;success:any ,id?:any,type?:any,userId?:any}) => {
  const dispatch=useDispatch()
  
  const handledelete=()=>{
    const payload ={
      pageNo:1
    }
    
    setOpen(false)
    if(type==="teamMember"){
    const handleSuccess=()=>{
      dispatch(getTeamMemberListInProgress(payload))
    }
      const paylod = {
        id: id,
        handleSuccess,
        userId
      };
      dispatch(deleteTeamMemberInProgress(paylod));
    }
    else if(type==="roles"){
      const payload ={
        pageNo:1
      }
      const handleSuccess=()=>{
          dispatch(getRolesListInProgress(payload))
        }
     
      const paylod = {
        id: id,
        handleSuccess
       
      };
      dispatch(deleteROLESInProgress(paylod))
    }
    else{
      
    const payload ={
      pageNo:1,
      pagination:true
    }
      const handleSuccess=()=>{
        dispatch(getDonerListInProgress(payload))
      }
      const paylod = {
        id: id,
        handleSuccess
      };
      dispatch(deleteDONERInProgress(paylod));
    }
    

  }
  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName='px-20 py-20'
      boxProps={{ width: 300, height: 150 }}
    >
  
      <Grid>
        <Typography textAlign={'center'} mb={5}>
          Are You Sure Want to delete?
        </Typography>
      </Grid>
      <Grid display={'flex'} justifyContent={'space-around'}>
        <CustomButton
          text={'Cancel'}
          className='px-5 py-3'
          color={'warning'}
          onClick={() => setOpen(false)}
        />
        <CustomButton text={'Delete'} className='px-5 py-3 brown-color' onClick={handledelete} />
      </Grid>
    </CommonModal>
  );
};

export default DeleteModel;
