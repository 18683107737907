import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
  ADD_TEAM_MEMBER_IN_PROGRESS,
  DELETE_TEAM_MEMBER_IN_PROGRESS,
  GET_ROLES_IN_PROGRESS,
GET_TEAM_MEMBER_IN_PROGRESS,
UPDATE_TEAM_MEMBER_IN_PROGRESS


} from "../../constants/ActionType";
import { getErrorMessageFromAPI } from "../../../utils/utilz";
import { message } from "antd";
import {


getTeamMemberListInSuccess,getTeamMemberListInError, addTeamMemberSuccess, addTeamMemberInError, getRolesListInSuccess, updateTeamMemberSuccess, updateTeamMemberInError, deleteTeamMemberSuccess, deleteTeamMemberError, getRolesListInError


} from "../teamMembers/action/teamMemberAction";
import {
  addTeamMemberInstanceListEffect,
  deleteTeamMemberInstanceListEffect,
  getRolesInstanceListEffect,
getTeamMemberInstanceListEffect,
updateTeamMemberInstanceListEffect


} from "../teamMembers/saga/effects/teamMemberEffects";
import Message from "../../../components/Message/Message";

function* getTeamMemberList({ payload }:any) {
  try {
    const { data } = yield call(getTeamMemberInstanceListEffect, payload);

    yield put(
        getTeamMemberListInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    yield put(
        getTeamMemberListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addTeamMember({ payload }:any) {
  try {
  
    let { data } = yield call(addTeamMemberInstanceListEffect, payload.data);

    yield put(
      addTeamMemberSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSucces
      ){
      payload.handleSucces()
    }
  } catch (e:any) {
    yield put(
      addTeamMemberInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
  }
}
function* getRolesList({ payload }:any) {
  try {
    const { data } = yield call(getRolesInstanceListEffect, payload);

    yield put(
        getRolesListInSuccess({
        data: data,
        // message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    yield put(
        getRolesListInError({
        // message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    // message.error(getErrorMessageFromAPI(e));
  }
}
function* updateTeamMember({ payload }:any) {
  try {
  
    let { data } = yield call(updateTeamMemberInstanceListEffect, payload.data);

    yield put(
      updateTeamMemberSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSucces
      ){
      payload.handleSucces()
    }
  } catch (e:any) {
    yield put(
      updateTeamMemberInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
  }
}
function* deleteTeamMember({ payload }:any) {
  try {
    let { data } = yield call(deleteTeamMemberInstanceListEffect, payload);

    yield put(
      deleteTeamMemberSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
  } catch (e:any) {
    yield put(
      deleteTeamMemberError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
  
    }
  }
}
export default function* teamMemberSaga() {
    yield takeLatest(GET_TEAM_MEMBER_IN_PROGRESS, getTeamMemberList);
    yield takeLatest(ADD_TEAM_MEMBER_IN_PROGRESS, addTeamMember);
    yield takeLatest(GET_ROLES_IN_PROGRESS, getRolesList);
    yield takeLatest(UPDATE_TEAM_MEMBER_IN_PROGRESS, updateTeamMember);
    yield takeLatest(DELETE_TEAM_MEMBER_IN_PROGRESS, deleteTeamMember);

  }
