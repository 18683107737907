import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../common/ui/CustomButton';
import { GridValueGetterParams } from '@mui/x-data-grid';
import Table from '../../../common/TableComponent';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import RoleHeader from './RoleHeader';
import DeleteModel from '../../../common/ui/Modal/DeleteModel';
import SuccessModal from '../../../common/ui/Modal/SuccessModal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getRolesListInProgress } from '../../../common/redux/roles/action/rolesAction';
import TableComponent from '../../../common/TableComponent';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
const Role = () => {
  const navigate = useNavigate();
  const {t}=useTranslation()
  const dispatch=useDispatch()
  const [open, setOpen] = useState(false);
  const [id,setId]=useState("")
  const [Succesopen, setSuccesopen] = useState(false);
  const  {roles}= useSelector((state:any) => state);
  const rolesData = roles?.rolesList?.data?.data || [];
  const totalRecords = roles?.rolesList?.data?.totalRecords || [];
  const rolesProgressing = roles?.rolesList?.progressing


  
  useEffect(()=>{
    const paylod ={
      pageNo:1
    }

    dispatch(getRolesListInProgress(paylod))
  },[])
 
  const success = () => {
    setSuccesopen(true);
  };
  const handleDelete = () => {
    setOpen(true);
  };
  const handleDeleteAndSetId = (id:any) => {
    handleDelete();
    setId(id);
  };
  const columns = [
    {
      dataIndex: 'roleId',
      title: `${t("Roles_Id")}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'role',
      title: `${t("Role")}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'status',
      title: `${t("Status")}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },

    {
      dataIndex: 'Action',
      title: `${t("Action")}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 190,
      disableColumnMenu: true,
      render: (_:any, record: any) => (
        <>
          <CustomButton
            text={`${t("EDIT")}`}
            className='mx-5 '
            color={'warning'}
            onClick={() => {
              navigate(APP_ROUTES.ROLEEDIT, { state: { role: record,type:"edit"} });
            }}
          />
          <CustomButton
            text={`${t("DELETE")}`}
           
            className='mx-3 color-gray'
            onClick={() => handleDeleteAndSetId(record.id)}
          />
        </>
      ),
    },
  ];
  const handlePageChange = (page:any) => {
    dispatch(getRolesListInProgress({ pageNo: page}));
  };
  const rows = rolesData?.map((role:any, index:any) => ({
    roleId:role?.roles_serial_no,
    id: role.id,
    status: 'ACTIVE', // You may need to get this value from your role object
    role: role?.name,
    permissions:role?.permissions,
    isPrimary:role?.isPrimary
  }));
  return (
    rolesProgressing?<Grid className='main-spinner'>
    <Spin size="large"  /> </Grid>:  
    <Grid container>
      <Grid xs={12} >
        <RoleHeader />
      </Grid>
  

      <Grid xs={12} my={5}>
                    
<TableComponent
        columns={columns}
        dataSource={rows}
        pagination={{
        
          total: totalRecords,
          onChange: handlePageChange
        }}
        type="role"
   
      view={true}
      
        // onChange={onChange}
        // ref={tableRef}
      />
      </Grid>
      <DeleteModel open={open} setOpen={setOpen} success={success} id={id} type={"roles"}/>
      <SuccessModal Succesopen={Succesopen} SuccessetOpen={setSuccesopen} />
    </Grid>
  );
};

export default Role;
