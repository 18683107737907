import { Grid, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../../common/ui/CustomButton';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import cryptoEncryptionDecryption from '../../../common/crypo';
import { useTranslation } from 'react-i18next';
function DonationHeader() {
  const {t}=useTranslation()
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(APP_ROUTES.DASHBOARD);
  };
  const permissions= localStorage.getItem("permissions");
  const decrptedPermissions= cryptoEncryptionDecryption.Decrypt(permissions)
  const decryptedPermissions = JSON.parse(decrptedPermissions);
 
  const decryptedPermissions3 =JSON.parse(decryptedPermissions)


  return (
    <Grid className='donation-header'>
      <Typography fontSize={20} fontWeight={700}>
        {t('Manage_Donation')}
      </Typography>
      <CustomButton
        type='button'
        text={`${t('Add_donation')}`}
        startIcon={AddIcon}
        className=' px-10 py-5 my-20 add-donation-button'
        fontSize={14}
        onClick={() => {
          navigate(APP_ROUTES.DONATE_ADD);
        }}
        disabled={!decryptedPermissions3?.Donate?.add}
        color={'warning'}
      />
    </Grid>
  );
}

export default DonationHeader;
