import {
  FORGET_PASSWORD_IN_ERROR,
  FORGET_PASSWORD_IN_PROGRESS,
  FORGET_PASSWORD_IN_SUCCESS,
  LOGIN_USER_ERROR,
  LOGIN_USER_IN_PROGRESS,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_IN_PROGRESS,
  LOGOUT_USER_SUCCESS,
  OTP_SEND_ERROR,
  OTP_SEND_IN_PROGRESS,
  OTP_SEND_SUCCESS,
  OTP_VERIFICATION_IN_ERROR,
  OTP_VERIFICATION_IN_PROGRESS,
  OTP_VERIFICATION_IN_SUCCESS,
} from '../../../constants/ActionType';

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  authData: { ...defaultStatus },
  registerData: { ...defaultStatus },
  otpSend: { ...defaultStatus },
  otpVerification: { ...defaultStatus },
  forgetPassword: { ...defaultStatus },
};

const AuthReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_USER_IN_PROGRESS:
      return {
        ...state,
        authData: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        authData: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        authData: { ...defaultStatus, ...payload, error: true },
      };
    case LOGOUT_USER_IN_PROGRESS:
      return {
        ...state,
        authData: {
          ...defaultStatus,
        },
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        authData: {
          ...defaultStatus,
          success: false,
          error: true,
        },
      };
    //OTP SEND
    case OTP_SEND_IN_PROGRESS:
      return {
        ...state,
        otpSend: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case OTP_SEND_SUCCESS:
      return {
        ...state,
        otpSend: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case OTP_SEND_ERROR:
      return {
        ...state,
        otpSend: { ...defaultStatus, ...payload, error: true },
      };
    //OTP VERIFICATION
    case OTP_VERIFICATION_IN_PROGRESS:
      return {
        ...state,
        otpVerification: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case OTP_VERIFICATION_IN_SUCCESS:
      return {
        ...state,
        otpVerification: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case OTP_VERIFICATION_IN_ERROR:
      return {
        ...state,
        otpVerification: { ...defaultStatus, ...payload, error: true },
      };
    //FORGET PASSWORD
    case FORGET_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        forgetPassword: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case FORGET_PASSWORD_IN_SUCCESS:
      return {
        ...state,
        forgetPassword: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case FORGET_PASSWORD_IN_ERROR:
      return {
        ...state,
        forgetPassword: { ...defaultStatus, ...payload, error: true },
      };

    default:
      return state;
  }
};

export default AuthReducer;
