  import { Grid, Switch, Typography } from '@mui/material';
  import Select from 'react-select';

  import LabelWithIcon from '../../../common/ui/LabelWithIcon';
  import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
  import CustomButton from '../../../common/ui/CustomButton';
  import { Controller, useForm, useWatch } from 'react-hook-form';
  import theme from '../../../common/theme';
  import DonationTypes from './DonationTypes';
  import MaterialDonate from './MaterialDonate';
  import { useLocation, useNavigate } from 'react-router-dom';
  import './donation.scss';
  import { APP_ROUTES } from '../../../common/constants/Routes';
  import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
  import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
  import { PaymentType, familyMember, idProofs } from '../../../common/constants/dropDownList';
  import FormCheckBox from '../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
  import DonationTime from './DonationTime';
  import { useEffect, useState } from 'react';
  import { addDonationInProgress, getFamilyInProgress, updateDonationInProgress } from '../../../common/redux/Donations/actions/donationAction';
  import { useDispatch } from 'react-redux';
  import { useSelector } from 'react-redux';
import { getDonerListInProgress } from '../../../common/redux/doner/action/donerAction';
import { useTranslation } from 'react-i18next';

  const AddDonate = () => {
    const {t}=useTranslation()
    const navigate = useNavigate();
    const location =useLocation();
    const dispatch=useDispatch()
    const initialLabel: string | undefined = ""; 


    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      trigger,
unregister,
      watch,
      clearErrors,
      setValue,
    } = useForm({
      defaultValues: {
        donatedFor:location?.state?.data?.donatedFor||"",
        donateType:location?.state?.data?.donateType||"",
        donatedBy: location?.state?.data?.donatedBy_id||"",
        recurringDonation:location?.state?.data?.recurringDonation||false,
        familyMember:location?.state?.data?.familyMember||"",
        donationTime: [],
        donatedDate:location?.state?.data?.donatedDate||"",
        additionalInstructionsGeneral:location?.state?.data?.additionalInstructionsGeneral||"",
        idProofNumber: location.state?.data?.idProofNumber || '',
        idProofType:location.state?.data?.idProofType || '',
        firstName:location.state?.data?.firstName|| '',
        mobileNo:location.state?.data?.mobileNo|| '',
        dob:location.state?.data?.dob|| '',
        address:location.state?.data?.address||'',
        email:location.state?.data?.email||''
      },
    });

    const donorName = watch('donatedBy');
    const idProofType = watch('idProofType');
 const [label, setLabel] = useState<string | undefined>(initialLabel);
    const handleidProofTypeOption= (value: any) => {
   
      setValue('idProofType', value, {
        shouldValidate: true,
      });
  
      setLabel(idProofs[value-1]?.Description || undefined);
  
  
  
    };
    useEffect(() => {
  
      if(donorName!=="89d1720b-a23c-4b22-8c44-1a997007ecd5"){
       
        setValue('firstName','');
        setValue('mobileNo', '');
        setValue('dob', null);
        setValue('address', "");
        setValue('email', '');
       
      }
    }, [donorName]);


    useEffect(()=>{

      const paylod ={
        pageNo:1,
        pagination_required:"false"
      }
      dispatch(getDonerListInProgress(paylod))
    },[])
    const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      // Allow only alphabets and space
      const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
      setValue('firstName', sanitizedValue, { shouldValidate: true });
    };
    const  {doner} = useSelector((state:any) => state);
    const   {donation} = useSelector((state:any) => state);
    const donorData = doner?.donerList?.data?.data || [];

    const familyList = donation?.
    familyList?.data?.data || [];
 




    useEffect(() => {
      // trigger('donateType')
      trigger('recurringDonation');
      if(location?.state?.data?.donateType){
  setValue('donateType', location?.state?.data?.donateType==="Cash"?1:location?.state?.data?.donateType==="Material"?2:location?.state?.data?.donateType==="Cheque"?3:location?.state?.data?.donateType==="Online"?4:"");
      }
   
    
    }, []);
    useEffect(()=>{
      if(donorName){
        dispatch(getFamilyInProgress(donorName))
      }
    
    },[donorName])


    const option: any = [
      {
        Code: 1,
        Description: 'Birthday',
        Category: 'Birthday',
      },
      {
        Code: 2,
        Description: 'Anniversary',
        Category: 'Anniversary',
      },
      {
        Code: 3,
        Description: 'Leaders Birthday',
        Category: 'LeadersBirthday',
      },
      {
        Code: 4,
        Description: 'Others',
        Category: 'Others',
      },
    ];

    const mobile = watch('mobileNo');

  const parseMobileNo = (mobileNo: any) => {
    const allowedFirstDigits = ['6', '7', '8', '9'];
    const numberPattern = /\d+/g;
    const matches: any[] | null = String(mobile).match(numberPattern);
    if (matches) {
      const digits = matches.join('');
      if (allowedFirstDigits.includes(digits.charAt(0))) {
        return digits.substring(0, 10);
      }
    }
    return '';
  };
  useEffect(() => {
    if (mobile) {
      setValue('mobileNo', parseMobileNo(mobile));
    }
  }, [mobile]);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {

		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};
 


    const onSubmit = (data: any) => {
      data.id = location.state?.data?.id;
      data.dob= data.dob? data.dob:null;
      if(location?.state?.text === 'Edit Donation'){
        const handleSucces=()=>{
          navigate(APP_ROUTES.DONATION_LIST)
         }
         const payload={
          handleSucces,
          data
        }
      
        
        dispatch(updateDonationInProgress(payload))
      }
      else{ 
        const handleSucces=()=>{
          navigate(APP_ROUTES.DONATION_LIST)
         }
         const payload={
          handleSucces,
          data
        }
         dispatch(addDonationInProgress(payload))}
    
    };
    const handleDonatedBy = (value:any) => {
      setValue('donatedBy', value, { shouldValidate: true });
      setValue('familyMember', '');
    };
 

  
    const handleDonateType = (value: any) => {
      setValue('donateType', value, {
        shouldValidate: true,
      });
    };
    const donateType = watch('donateType');
  

    return (
      <Grid className='donations-form'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            display='flex'
            justifyContent='center'
            // className='login-form'
          >
            <Grid container xs={12} p={2}>
              <Grid item xs={6} alignItems={'center'} display={'flex'}>
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color={theme.palette.info.dark}
                >
                  {t('Donations')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                alignItems={'center'}
                justifyContent={'flex-end'}
                display={'flex'}
              >
                <CustomButton
                  type='button'
                  text={`${t('Go_Back')}`}
                  startIcon={SubdirectoryArrowRightIcon}
                  color={'primary'}
                  className='p-8 color-gray'
                  onClick={() => {
                    navigate(APP_ROUTES.DONATION_LIST);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
              <LabelWithIcon
                label={t('Donated_by')}
                justifyContent='flex-start'
                className='mb-6'
              />
              <Controller
                control={control}
                name='donatedBy'
                render={({ field }) => (
                  <Select
                    options={donorData}
                    isSearchable
                    placeholder={t('Select_Donor')}
                    value={
                      donorData.find(
                        (option: any) => option.id === watch('donatedBy')
                      ) || null
                    }
                    getOptionLabel={(option: any) => `${option.firstName}(${option.phone})  `}
                    getOptionValue={(option: any) => option.id}
                    onChange={(selectedOption: any) => {
                      handleDonatedBy(selectedOption?.id || '');
                      field.onChange(selectedOption?.id || '');
                    }}
                    onBlur={() => field.onBlur()}
                    isClearable
                    defaultInputValue={location?.state?.data?.donatedBy}
                  />
                  
                )}
                rules={{ required: `${t('Donor_is_Required')}` }}
              />
              {errors?.donatedBy?.message ? (
                <Typography color={theme.palette.error.main} fontSize={12}>
                <>{errors?.donatedBy?.message}</> 
                </Typography>
              ) : null}
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
                  label={t('Donated_For')}
                  justifyContent='flex-start'
                  className='mb-6 '
                />
                <FormInput
                  name='donatedFor'
                  type='text'
                  placeholder={t('Donated_For')}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                      value: true,
                      message: `${t('Donated_for_is_required')}`,
                    },
                  }}
                  inputProps={{
                    ...register('donatedFor', {}),
                  }}
                  errors={errors}
                
                />
         
            </Grid>
            {donorName && (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <Typography variant="h6">{t('Family_Member')}</Typography>
         
    <ul>
      {familyList.map((member:any) => (
        <li key={member.id}>
          {member.firstName} {member.lastName}-{familyMember[member.relationship_code
].Description}
        </li>
      ))}
    </ul>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
              <LabelWithIcon
                label={t('Donated_date')}
                justifyContent='flex-start'
                className='mb-6 '
              />
              <FormInput
                name='donatedDate'
                type='date'
                placeholder={`${t('Donated_date')}...`}
                control={control}
                className='input-from'
                rules={{
                  required: {
                    value: true,
                    message: `${t('Please_Select_Date')}`,
                  },
                }}
                errors={errors}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              p={2}
              mt={2.5}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Grid item>
                <Typography ml={1}>{t("Recurring_Donation")}?</Typography>
              </Grid>
              <Grid item>
                <Switch
                  {...register('recurringDonation')}
                  color='warning'
                  defaultChecked={location?.state?.data?.recurringDonation||false}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
            label={t('Additional_Instructions')}
            justifyContent='flex-start'
            className='mb-6'
          />
         
            <textarea
            
              placeholder=""
              className="input-from"
              {...register('additionalInstructionsGeneral')}
              defaultValue={location?.state?.data?.additionalInstructionsGeneral||""}
              style={{ width: '100%' }} 
            />
            {errors.additionalInstructionsGeneral && (
              <Typography color={theme.palette.error.main} fontSize={12}>
                Please enter Additional Instructions
              </Typography>
            )}
    </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
              <LabelWithIcon
                label={t('Donate_Type')}
                justifyContent='flex-start'
                className='mb-6'
              />
              <Controller
                control={control}
                name='donateType'
                render={({ field }) => (
                  <Select
                    options={PaymentType}
                    isSearchable
                    placeholder={`${t("Select_Donate_Type")}`}
                    value={
                      PaymentType.find(
                        (option: any) => option.Code === watch('donateType')
                      ) || null
                    }
                    getOptionLabel={(option: any) => option.Description}
                    getOptionValue={(option: any) => option.Code}
                    onChange={(selectedOption: any) => {
                      handleDonateType(selectedOption?.Code || '');
                      field.onChange(selectedOption?.Code || '');
                    }}
                    defaultInputValue={location?.state?.data?.donateType||""}
                    onBlur={() => field.onBlur()}
                    isClearable
                    isDisabled={location?.state?.text === 'Edit Donation'}
                  
                  />
                )}
                rules={{ required: `${t("Donate_Type_is_required")}` }}
              />
              {errors.donateType?.message ? (
                <Typography color={theme.palette.error.main} fontSize={12} ml={2}>
                <>{errors.donateType?.message}</> 
                </Typography>
              ) : null}
            </Grid>
            
            <Grid item xs={24} sm={24} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('Id_proof_type')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <CustomSelectBox
              options={idProofs}
              ClassName='form-select'
              placeholder={`${t('Id_proof_type')}`}
              name={'idProofType'}
              inputProps={register('idProofType', {
              
              })}
              setSelectedOption={handleidProofTypeOption}
              error={errors}
              defaultValue={`${location.state?.data?.idProofType}`}
            />
            {idProofType>=1&&(
                  <Grid mt={1}>
                  <LabelWithIcon
                    label={`${label}`}
                    justifyContent='flex-start'
                    className='mb-6 ml-3'
                  />
                  <FormInput
                    name='idProofNumber'
                    type='text'
                    placeholder={`${label}`}
                    control={control}
                    className='input-from'
                   
                    inputProps={{
                      ...register('idProofNumber', {}),
                    }}
                    errors={errors}
                    defaultValue={`${location.state?.data?.idProofNumber}`}
                  />
                  </Grid>
            )}
        
          </Grid>
            <Grid item xs={24} sm={24} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
                label={t("Donation_Time")}
                justifyContent='flex-start'
                className='mb-6 '
              />
              <DonationTime register={register} watch={watch} control={control} defaultValues={location?.state?.data}/>
            </Grid>
         
            {donorName==="89d1720b-a23c-4b22-8c44-1a997007ecd5"&&(
              <>
                 <Grid
          container
          display='flex'
          justifyContent='flex-start'
          // className='login-form'
        >
                   <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                   <LabelWithIcon
                    label={`${t('First_Name')}`}
                     justifyContent='flex-start'
                     className='mb-6  '
                   />
                   <FormInput
                     name='firstName'
                     type='text'
                     placeholder={`${t('First_Name')}...`}
                     control={control}
                     className='input-from'
                     rules={{
                       required: {
                         value: true,
                         message: `${t('PLEASE_ENTER_FIRST_NAME')}`,
                       },
                     }}
                     inputProps={{
                       ...register('firstName', {}),
                       onChange: nameChange,
                     }}
                     errors={errors}
                   />
                 </Grid>
                   <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                   <LabelWithIcon
                       label={`${t('MOBILE_NUMBER')}`}
                     justifyContent='flex-start'
                     className='mb-6 ml-3'
                   />
                   <FormInput
                     name='mobileNo'
                     type='text'
                     placeholder={`${t('MOBILE_NUMBER')}...`}
                     control={control}
                     className='input-from'
                     rules={{
                       required: {
                           value: true,
                           message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
                       },
                       maxLength: {
                           value: 10,
                           message: `${t('MOBILE_NUMBER_SHOULD_NOT_EXCEED')}`,
                       },
                       pattern: {
                         value: /^[0-9]*$/,
                         message: `${t('ONLY_NUMERIC_CHARACTERS_ALLOWED')}`,
                     },
                     minLength: {
                       value: 10,
                       message: `${t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG')}`,
                   },}}
                     inputProps={{
                       ...register('mobileNo', {}),
                     }}
                     errors={errors}
                   />
                 </Grid>
                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
               label={`${t('DOB')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name='dob'
              type='date'
              placeholder={`${t('DOB')}...`}
              control={control}
              className='input-from'
              rules={{
                // required: {
                //   value: true,
                //   message: 'Please enter DOB',
                // },
              }}
              inputProps={{
                ...register('dob', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
               label={`${t('EMAIL')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name='email'
              type='text'
              placeholder={`${t('EMAIL')}...`}
              control={control}
              className='input-from'
              rules={{
                // required: {
                //     value: true,
                //     message: 'Please enter an Email',
                // },
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: `${t('INVALID_EMAIL_ADDRESS')}`,
                },
            }}
              inputProps={{
                ...register('email', {}),
                onChange: handleEmailChange 
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
             label={`${t('ADDRESS')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              hideCountButton
              multipleLine
              name='address'
              type='text'
              placeholder={`${t('ADDRESS')}...`}
              control={control}
              className='input-from-text'
              rules={{
                // required: {
                //   value: true,
                //   message: 'Please enter a Adderss',
                // },
              }}
              inputProps={{
                ...register('address', {}),
              }}
              errors={errors}
            />
          </Grid>
        
                 </Grid>
                 </>
            )}
          </Grid>
       
          <Grid>
            { donateType=== "Cash"|| donateType=== "Cheque"||donateType=== "Online"||donateType=== 1||donateType=== 3 ||donateType=== 4? (
              <>
             
             
             <DonationTypes
                setValue={setValue}
                watch={watch}
                control={control}
                register={register}
                errors={errors}
                submit={onSubmit}
                trigger={trigger}
                donationType={donateType}
                defaultValues={location?.state?.data}
              />  
               <div style={{visibility:"hidden"}}>
              <MaterialDonate
              setValue={setValue}
              watch={watch}
              unregister={unregister}
              control={control}
              register={register}
              clearErrors={clearErrors}
              errors={errors}
              submit={onSubmit}
              defaultValues={location?.state?.data}
            /></div>
            </>
            ) :donateType=== "Material"||donateType=== 2? (
              <>
              
              <MaterialDonate
                setValue={setValue}
                watch={watch}
                control={control}
                register={register}
                unregister={unregister}
                clearErrors={clearErrors}
                errors={errors}
                submit={onSubmit}
                defaultValues={location?.state?.data}
              />
              <div style={{visibility:"hidden"}}>
              <DonationTypes
                setValue={setValue}
                watch={watch}
                control={control}
                register={register}
                errors={errors}
                submit={onSubmit}
                trigger={trigger}
                defaultValues={location?.state?.data}
              /></div>
              </>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'end'}
            my={5}
            mx={1}
          >
            <CustomButton
              type='button'
              text={`${t('BACK')}`}
              // startIcon={ArrowBackIcon}
              color={'primary'}
              className='p-12 mx-10 color-gray'
              width={120}
              onClick={() => {
                navigate(-1);
              }}
            />
            <CustomButton
              type='submit'
              text={`${t('SUBMIT')}`}
              color={'warning'}
              className='p-12'
              width={120}
            />
          </Grid>
         
        </form>
      </Grid>
    );
  };

  export default AddDonate;
