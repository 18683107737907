import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AUTH_ROUTES, PUBLIC_ROUTES } from '../common/constants/Routes';
import AuthLayout from './AuthLayout';
import Login from '../features/Auth/Login/Login';
import { useTheme } from '@mui/material';
import OtpScreen from '../features/Auth/common/OtpScreen';
import ForgetPassword from '../features/Auth/Login/ForgetPassword';

const AuthRoutes = ({ isLogged }: any) => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <Routes>
      {isLogged === false ? (
        <Route path='/' element={<AuthLayout />}>
          <Route path={AUTH_ROUTES.LOGIN} element={<Login />} />
          <Route path={AUTH_ROUTES.LOGIN_OTP_VERIFY} element={<OtpScreen />} />
          <Route
            path={AUTH_ROUTES.FORGET_PASSWORD}
            element={<ForgetPassword />}
          />
          {!Object.values(PUBLIC_ROUTES).includes(location.pathname) ? (
            <Route path='*' />
          ) : (
            <></>
          )}

          {/* <Route path={"*"} element={<Navigate to={APP_ROUTES.DASHBOARD} />} /> */}
        </Route>
      ) : (
        <></>
      )}
    </Routes>
  );
};
export default AuthRoutes;
