import {
GET_DONER_LIST_IN_PROGRESS,
GET_DONER_LIST_SUCCESS,
GET_DONER_LIST_ERROR,
ADD_DONER_IN_PROGRESS,
ADD_DONER_IN_SUCCESS,
ADD_DONER_IN_ERROR,
DELETE_DONER_IN_PROGRESS,
DELETE_DONER_IN_SUCCESS,
DELETE_DONER_IN_ERROR,
UPDATE_DONER_IN_PROGRESS,
UPDATE_DONER_IN_SUCCESS,
UPDATE_DONER_IN_ERROR,
ADD_CSV_IN_PROGRESS,
ADD_CSV_IN_SUCCESS,
ADD_CSV_IN_ERROR
  } from "../../../constants/ActionType";
  
  const defaultStatus = {
    progressing: false,
    success: false,
    error: false,
    data: [],
  };
  const initialState = {
    donerList: { ...defaultStatus },
    addDoner: { ...defaultStatus },
    updateDoner: { ...defaultStatus },
    deleteDoner: { ...defaultStatus },
    addCsv:{...defaultStatus}
   
  };
  const donerReducer = (state = initialState, action:any) => {
    const { type, payload } = action;
    switch (type) {
      //Doner
      case GET_DONER_LIST_IN_PROGRESS:
        return {
          ...state,
          donerList: { ...defaultStatus, ...payload, progressing: true },
        };
      case GET_DONER_LIST_SUCCESS:
        return {
          ...state,
          donerList: { ...defaultStatus, ...payload, success: true },
        };
      case GET_DONER_LIST_ERROR:
        return {
          ...state,
          donerList: {
            ...defaultStatus,
            ...payload,
            error: true,
          },
        };
        //add doner 
        case ADD_DONER_IN_PROGRESS:
          return {
            ...state,
            addDoner: { ...defaultStatus, ...payload, progressing: true },
          };
        case ADD_DONER_IN_SUCCESS:
          return {
            ...state,
            addDoner: { ...defaultStatus, ...payload, success: true },
          };
        case ADD_DONER_IN_ERROR:
          return {
            ...state,
            addDoner: { ...defaultStatus, ...payload, error: true },
          };
          
    //DELETE_DONOR
    case DELETE_DONER_IN_PROGRESS:
      return {
        ...state,
        deleteDoner: { ...defaultStatus, ...payload, progressing: true },
      };
    case DELETE_DONER_IN_SUCCESS:
      return {
        ...state,
        deleteDoner: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_DONER_IN_ERROR:
      return {
        ...state,
        deleteDoner: { ...defaultStatus, ...payload, error: true },
      };
       //UPDATE_DONOR
    case  UPDATE_DONER_IN_PROGRESS:
      return {
        ...state,
        updateDoner: { ...defaultStatus, ...payload, progressing: true },
      };
    case UPDATE_DONER_IN_SUCCESS:
      return {
        ...state,
        updateDoner: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_DONER_IN_ERROR:
      return {
        ...state,
        updateDoner: { ...defaultStatus, ...payload, error: true },
      };
        //add CSV
        case ADD_CSV_IN_PROGRESS:
          return {
            ...state,
            addCsv: { ...defaultStatus, ...payload, progressing: true },
          };
        case ADD_CSV_IN_SUCCESS:
          return {
            ...state,
            addCsv: { ...defaultStatus, ...payload, success: true },
          };
        case ADD_CSV_IN_ERROR:
          return {
            ...state,
            addCsv: { ...defaultStatus, ...payload, error: true },
          };
       default:
        return state;
    }
  };
  
  export default donerReducer;
  