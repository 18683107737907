/* eslint-disable react/require-default-props */
// import Button from "@mui/material/Button";

import { LoadingButton } from '@mui/lab';
import SvgConverter from '../SvgConverter';
// import { ReactComponent as RightArrow } from '../icons/right_arrow-icon.svg';

interface ButtonInterface {
  text: string | any;
  color?:
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'primary'
    | 'warning'
    | 'inherit';

  endIcon?: any;
  startIcon?: any;
  className?: string | undefined;
  iconClassName?: string | undefined;
  startIconClassName?: string | undefined;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'contained' | 'outlined';
  onClick?: any;
  type?: 'button' | 'submit' | undefined;
  loading?: true | false;
  fontSize?: number | undefined;
  fontWeight?: number | undefined;
  disabled?: boolean | undefined;
  showIcon?: true | false | undefined;
  textAlign?: any;
  width?: any;
}

const CustomButton = ({
  text,
  size,
  onClick,
  variant = 'contained',
  color,
  className,
  startIcon,
  endIcon,
  iconClassName,
  startIconClassName,
  type = 'button',
  loading = false,
  fontSize = 14,
  showIcon = true,
  fontWeight,
  disabled = false,
  textAlign,
  width,
}: ButtonInterface) => (
  <LoadingButton
    sx={{
      padding: 0,
      textAlign: { textAlign },
      width: width ? `${width}px` : 'auto',
    }}
    variant={variant}
    size={size}
    disabled={disabled}
    onClick={onClick}
    color={color}
    className={`fw-${fontWeight} f-${fontSize}  ${className}  `}
    startIcon={
      <SvgConverter Icon={startIcon} className={`${startIconClassName}`} />
    }
    endIcon={
      showIcon === true ? (
        <SvgConverter
          Icon={endIcon}
          className={`right-arrow ${iconClassName}`}
        />
      ) : (
        <></>
      )
    }
    type={type}
    loading={loading}
    disableRipple
  >
    {text}
  </LoadingButton>
);

export default CustomButton;
