import { Grid, Typography, useTheme } from '@mui/material';

import { useForm, useWatch } from 'react-hook-form';
import React, { useState } from 'react';

import './Login.scss';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import CustomButton from '../../../common/ui/CustomButton';
import image from '../../../common/icons/image3 1.png';
import { AUTH_ROUTES } from '../../../common/constants/Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { forgetPasswordInProgress } from '../../../common/redux/auth/action/authAction';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
const ForgetPassword = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation()

  const [password, setPassword] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues
  } = useForm({
    defaultValues: {
    password: '',
      ConfirmPassword: '',
    },
  });

  const [passwordMatch, setPasswordMatch] = useState(true);
  const navigate =useNavigate()
  const dispatch =useDispatch()
  const handePassword = () => {
    // setModel(true);
    setPassword(false);
    // handleSubmit(onSubmit);
  };
  const handleChange = (e:any) => {
    const { name, value } = e.target;
    const newPassword = getValues('password');
    if (name === 'ConfirmPassword' && newPassword !== value) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };
  const onSubmit = (data:any) => {
   
    if(passwordMatch){
      const handleSuccess=()=>{
        navigate(AUTH_ROUTES.LOGIN)
      }
      data.email=location.state
      const payload ={
        data,
        handleSuccess
  
      }
    dispatch(forgetPasswordInProgress(payload))
    }
    
   
  };

  return (
    <Grid className='forget-password'>
      <Grid className='forget-password-form'>
        <Grid justifyContent='center' display={'flex'} my={2}>
          <img src={image} alt='logo' className='img-forget-password' />
        </Grid>
        <form   onSubmit={handleSubmit(onSubmit)}>
          <Grid>
          <Grid my={1}>
              <LabelWithIcon
                	label={t('NEW_PASSWORD')}
                labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
                justifyContent='flex-start'
              />
            </Grid>
            <Grid item my={1}>
              <FormInput
                placeholder={`${t('ENTER_NEW_PASSWORD')}...`}
                className='username-input'
                name='password'
                type='password'
                control={control}
                rules={{
                  required: t('PLEASE_ENTER_NEW_PASSWORD'),
                }}
                inputProps={{
                  className: 'text-center',
                  ...register('password'),
                  onChange: handleChange
                }}
                errors={errors}
              />
            </Grid>
            <Grid my={1}>
              <LabelWithIcon
                label={t('CONFIRM_NEW_PASSWORD')}
                labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
                justifyContent='flex-start'
              />
            </Grid>
            <Grid item my={1}>
              <FormInput
                placeholder={`${t('CONFIRM_YOUR_PASSWORD')}...`}
                className='username-input'
                name='confirmPassword'
                type='password'
                control={control}
                rules={{
                  required: t('PLEASE_ENTER_CONFIRM_PASSWORD'),
                }}
                inputProps={{
                  className: 'text-center',
                  ...register('ConfirmPassword'),
                  onChange: handleChange
                }}
                errors={errors}
              />
               {!passwordMatch && <Typography color={"red"}>{`${t('PASSWORD_NOT_MATCH')}`}</Typography>}
            </Grid>

            <Grid justifyContent='center' display={'flex'} my={5}>
              <CustomButton
                type='submit'
                text='Submit'
                width={200}
               color={"warning"}
               className='forget-password-button'
                disabled={!passwordMatch}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
      {/* <OtpModel open={model} setOpen={setModel} /> */}
    </Grid>
  );
};

export default ForgetPassword;
