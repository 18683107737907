import { Grid, Typography, useTheme } from '@mui/material';

import { useForm, useWatch } from 'react-hook-form';
import React, { useState } from 'react';

import './Login.scss';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import CustomButton from '../../../common/ui/CustomButton';
import SvgConverter from '../../../common/SvgConverter';
import { ReactComponent as icon } from '../../../common/icons/Group 141.svg';
import { APP_ROUTES, AUTH_ROUTES } from '../../../common/constants/Routes';
import { Link, useNavigate } from 'react-router-dom';
import OtpModel from '../common/OtpModel';
import { UserLoginInProgress, otpSendInProgress } from '../../../common/redux/auth/action/authAction';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const LoginForm = () => {
  const dispatch =useDispatch()
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [password, setPassword] = useState(true);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [model, setModel] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const username = watch('email');

  const onSubmit = (data: any) => {
  
    const handleSuccess=()=>{
      navigate(APP_ROUTES.DASHBOARD);
      localStorage.setItem('user', data?.userName);
  window.location.reload();
}
    

    const payload={
      data,
      handleSuccess
    }
    dispatch(UserLoginInProgress(payload));
  };
  const handleForgotPassword = () => {
  

const data ={ email: watch('email') ,}

const handleSuccess=()=>{
  navigate(AUTH_ROUTES.LOGIN_OTP_VERIFY,{state:data});

}
const payload={
  data,
  handleSuccess
}
    dispatch(otpSendInProgress(payload));


  };


  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          display='flex'
          flexDirection='column'
          justifyContent='center'
          className='login-form'
        >
          <Grid justifyContent='center' display={'flex'} my={2}>
            <SvgConverter
              Icon={icon}
              width={400}
              className='sivabakkiyam-logo'
            />
          </Grid>

          <Grid my={1}>
            <LabelWithIcon
      label={t('EMAIL_/_MOBILE_NUMBER')}
              // iconProps={{ height: 33 }}
              labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
              justifyContent={'flex-start'}
              // className='dob-input'
            />
          </Grid>
          <Grid item my={1}>
          <FormInput
  placeholder={`${t('ENTER_YOUR_EMAIL_/_MOBILE_NUMBER')}...`}
  className='username-input'
  name='email'
  control={control}
  rules={{
    required: t('PLEASE_ENTER_YOUR_MOBILE_NUMBER_EMAIL'),
    pattern: {
      value: /^([^\s@]+@[^\s@]+\.[^\s@]+)|(\d{10})$/,
      message: t('INVALID_EMAIL_ADDRESS'),
    },
  }}
  inputProps={{
    className: 'text-center',
    ...register('email', {}),
  }}
  errors={errors}
/>


          </Grid>

          <Grid my={1}>
            <LabelWithIcon
             	label={t('PASSWORD')}
              // iconProps={{ height: 33 }}
              labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
              justifyContent={'flex-start'}
              // className='dob-input'
            />
          </Grid>
          <Grid item my={1}>
            <FormInput
              	placeholder={`${t('ENTER_YOUR_PASSWORD')}...`}
              className='username-input'
              name='password'
              type='password'
              control={control}
              rules={{
                required: {
                  value: password,
                  message: t('PLEASE_ENTER_YOUR_PASSWORD'),
                },
              }}
              inputProps={{
                className: 'text-center',
                ...register('password', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid justifyContent='flex-end' display={'flex'} my={1}>
            <Link
              to={
""
              }
              onClick={() => {
                handleForgotPassword()
              }}
            >
              <Typography color={theme.palette.common.white}>
              {`${t('FORGET_PASSWORD')}?`}
              </Typography>
            </Link>
          </Grid>
          <Grid justifyContent='center' display={'flex'} my={5}>
            <CustomButton
              type='submit'
              text={t('LOGIN')}
              className='login-button'
              color={'primary'}
            />
          </Grid>
        </Grid>
        <LanguageSelector
				open={showLanguageModal}
				setOpen={setShowLanguageModal}
				contentType="DASHBOARD"
			/>
      </form>
      {/* <OtpModel open={model} setOpen={setModel} /> */}
    </Grid>
  );
};

export default LoginForm;
