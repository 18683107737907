import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import Select from 'react-select';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import theme from '../../../common/theme';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useTranslation } from 'react-i18next';

interface DonationTypesProps {
  setValue: any;
  watch: (name: string, defaultValue?: any) => any;
  control: any;
  register: any;
  errors: any;
  submit?: any;
  defaultValues?:any;
  unregister:any
  clearErrors:any
}

const MaterialDonate: React.FC<DonationTypesProps> = ({
  setValue,
  watch,
  control,
  register,
  errors,
  submit,
  defaultValues,
  unregister,
  clearErrors
}) => {
  const navigate = useNavigate();
  const donateType = watch('donateType');
  const {t}=useTranslation()

  useEffect(() => {
    
    if (donateType === "Cash" || donateType === 1 ||donateType===3||donateType===4) {
    
      setValue('materialType',"", {
        shouldValidate: false,
      });
      setValue('count',"", {
        shouldValidate: false,
      });
      setValue('additionalInstructionsMaterial',"", {
        shouldValidate: false,
      });
    }
  }, [donateType]);
  
  return (
    <Grid>
      <Grid container display='flex' justifyContent='center'>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={t('Material_Type')}
            justifyContent='flex-start'
            className='mb-6'
          />
          <FormInput
            name='materialType'
            type='text'
            placeholder={`${t('Enter_The_Material_Type')}`}
            control={control}
            className='input-from'
            rules={{
              required: {
                value:donateType === "Cash" || donateType === 1 ||donateType===3||donateType===4? false:true,
                message: `${t('Enter_The_Material_Type')}`,
              },
            }}
            inputProps={{
              ...register('materialType', {}),
            }}
            errors={errors}
            defaultValue={defaultValues?.materialType}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={t('Count')}
            justifyContent='flex-start'
            className='mb-6'
          />
          <FormInput
            name='count'
            type='number'
            placeholder={`${t('Enter_the_count')}`}
            control={control}
            className='input-from'
            rules={{
              required: donateType === "Cash" || donateType === 1 ||donateType===3||donateType===4 ?undefined : {
                value: true,
                message: `${t('Enter_the_count')}`,
              } ,
            }}
            inputProps={{
              ...register('count', {}),
            }}
            defaultValue={defaultValues?.count}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={t("Additional_Instructions")}
            justifyContent='flex-start'
            className='mb-6'
          />
         
           <textarea
              name="Additional Instructions Materials "
              placeholder={`${t("Additional_Instructions")}`}
              className="input-from"
              {...register('additionalInstructionsMaterial', )}
              defaultValue={defaultValues?.additionalInstructionsMaterial}
              style={{ width: '100%' }} 
            />
            {errors.additionalInstructionsMaterial && (
              <Typography color={theme.palette.error.main} fontSize={12}>
                Please enter Additional Instructions
              </Typography>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}></Grid>
      </Grid>
    </Grid>
  );
};

export default MaterialDonate;
