import { Grid, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useTranslation } from 'react-i18next';
const PaymentReminderHeader = () => {
  const navigate = useNavigate();
  const {t}=useTranslation()

  return (
    <Grid
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className='donation-header'
    >
      <Typography fontSize={20} fontWeight={700}>
     {t('Recent_donations')}
      </Typography>

      <CustomButton
        type='button'
        text={`${t('Go_Back')}`}
        startIcon={SubdirectoryArrowRightIcon}
        color={'primary'}
        className='p-8 color-gray'
        onClick={() => {
          navigate(APP_ROUTES.DASHBOARD);
        }}
      />
    </Grid>
  );
};

export default PaymentReminderHeader;
