import React, { useEffect } from 'react';
import VolunteerForm from './VolunteerForm';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

const EditVolunteer = () => {
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,

    reset,
  } = useForm({
    defaultValues: {
      firstName: location.state?.data?.firstName,
      lastName: location.state?.data?.lastName,
      email: location.state?.data?.email,
      contact: location.state?.data?.contact,
      gender: location.state?.data?.gender,
      dob: location.state?.data?.dob,
      bloodGroup: location.state?.data?.bloodGroup,
      address: location.state?.data?.address,
    },
  });

  return (
    <div>
      {' '}
      <VolunteerForm
        reset={reset}
        errors={errors}
        control={control}
        text={'Edit Volunteer'}
        register={register}
        handleSubmit={handleSubmit}
        setValue={setValue}
      />
    </div>
  );
};

export default EditVolunteer;
