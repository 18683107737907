import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import VolunteerHeader from './TeamMemberHeader';
import Table from '../../../common/TableComponent';
import { GridValueGetterParams } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';

import theme from '../../../common/theme';
import { bloodGroup } from '../../../common/constants/dropDownList';
import DeleteModel from '../../../common/ui/Modal/DeleteModel';
import SuccessModal from '../../../common/ui/Modal/SuccessModal';
import { useDispatch } from 'react-redux';
import { getTeamMemberRolesListInProgress, getTeamMemberListInProgress } from '../../../common/redux/teamMembers/action/teamMemberAction';
import { useSelector } from 'react-redux';
import TableComponent from '../../../common/TableComponent';
import cryptoEncryptionDecryption from '../../../common/crypo';
import { Spin } from 'antd';
import { convertDateFormat } from '../../../utils/utilz';
import { useTranslation } from 'react-i18next';
function TeamMember() {
  const navigate = useNavigate();
  const {t}=useTranslation()
  const dispatch=useDispatch()
  const [tableRow, setTableRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [Succesopen, setSuccesopen] = useState(false);
  const [id,setId]=useState("")


  const success = () => {
    setSuccesopen(true);
  };
  useEffect(()=>{
    const paylod ={
      pageNo:1
    }
dispatch(getTeamMemberListInProgress(paylod))
// dispatch( getTeamMemberRolesListInProgress(paylod))  
  },[])
  const  {teamMember} = useSelector((state:any) => state);
  const teamMemberData = teamMember?.teamMemberList?.data?.data || [];
  const totalRecords = teamMember?.teamMemberList?.data?.totalRecords || [];
// console.log("teamMember",teamMemberData)
// console.log("id",id)
const handlePageChange = (page:any) => {
  dispatch(getTeamMemberListInProgress({ pageNo: page}));
};
const permissions= localStorage.getItem("permissions");
const decrptedPermissions= cryptoEncryptionDecryption.Decrypt(permissions)
const decryptedPermissions = JSON.parse(decrptedPermissions);
 
const decryptedPermissions3 =JSON.parse(decryptedPermissions)

const teamMemberProgressing=teamMember?.teamMemberList?.progressing
const handleDelete = () => {
  setOpen(true);
  
};

const handleDeleteAndSetId = (id:any) => {
  handleDelete();
  setId(id);
};
  const columns = [
    {
      dataIndex: 'memberId',
      title: `${t('Member_id')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'firstName',
      title: `${t('First_Name')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'lastName',
      title: `${t('Last_Name')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'email',
      title: `${t('EMAIL')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      dataIndex: 'contact',
      title: `${t('MOBILE_NUMBER')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      dataIndex: 'dob',
      title: `${t('DOB')}`,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
   
    {
        dataIndex: 'Role',
        title: `${t('Role')}`,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        dataIndex: 'Status',
        title: `${t('Status')}`,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
      },
    {
      dataIndex: 'Action',
      title: `${t('Action')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 190,
      disableColumnMenu: true,
      render: (_:any,record:any) => (
        <>
            
          <CustomButton
            text={`${t('EDIT')}`}
            className='mx-5'
            color={'warning'}
            disabled={!decryptedPermissions3?.TeamMember?.edit}
            onClick={() => {
                navigate(APP_ROUTES.TEAM_MEMBER_EDIT, {
                  state: {
                    data: record,
                    type: 'edit',
                  },
                });
              }}
          />
          <CustomButton
            text={`${t('DELETE')}`}
            disabled={!decryptedPermissions3?.TeamMember?.delete}
            className='mx-3  color-gray'
            onClick={() => handleDeleteAndSetId(record.id)}
          />
        </>
      ),
    },
  ];

const rows = teamMemberData?.map((member: any) => ({
  memberId:member.members_serial_no,
  id: member.id,
  lastName: member.lastName,
  firstName: member.firstName,
  email: member.email,
  contact: member.phone,
  dob: convertDateFormat(member.dob),
  bloodGroup: member.blood_group_id, 
  Role: member.role_name, 
  Status: member.status === 1 ? 'Active' : 'Inactive', 
  gender_type:member.gender_type,
  address:member.address,
  password:member.password,
  user_id:member.user_id,
  role_id:member.role_id

  
  
}));
  useEffect(() => {
    setTableRow(rows);
  }, []);
 

  return (
    teamMemberProgressing? 
  <Grid className='main-spinner'>
  <Spin size="large"  /> </Grid>:  
    <Grid container>
      <Grid xs={12}>
        <VolunteerHeader />
      </Grid>
    
      <Grid xs={12} my={5}>
              
<TableComponent
        columns={columns}
        dataSource={rows}
        pagination={{
        
          total: totalRecords,
          onChange: handlePageChange
        }}
   
        view={decryptedPermissions3?.TeamMember?.view}
        type="teamMember"
      
        // onChange={onChange}
        // ref={tableRef}
      />
      </Grid>
      <DeleteModel open={open} setOpen={setOpen} success={success} type="teamMember" id={id}/>
      <SuccessModal Succesopen={Succesopen} SuccessetOpen={setSuccesopen} />
    </Grid>
  );
}

export default TeamMember;
