import React from 'react';
import { Grid, useTheme } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import './Layout.scss';
import { AUTH_ROUTES } from '../common/constants/Routes';

const AuthLayout = () => {
  const location = useLocation();
  const theme = useTheme();
  return (
    <Grid
      container
      component='main'
      justifyContent='center'
      className='auth-layout-container'
      sx={{
        backgroundColor:
          location?.pathname === AUTH_ROUTES.LOGIN
            ? '#F36B21'
            : theme.palette.common.white,
      }}
    >
      <Grid item xs={12} className='h-100 '>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
