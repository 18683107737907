import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import VolunteerHeader from './VolunteerHeader';
import Table from '../../../common/TableComponent';
import { GridValueGetterParams } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import './volunteer.scss';
import theme from '../../../common/theme';
import { bloodGroup } from '../../../common/constants/dropDownList';
import DeleteModel from '../../../common/ui/Modal/DeleteModel';
import SuccessModal from '../../../common/ui/Modal/SuccessModal';
function Volunteer() {
  const navigate = useNavigate();
  const [tableRow, setTableRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [Succesopen, setSuccesopen] = useState(false);
  const success = () => {
    setSuccesopen(true);
  };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: 'First name',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email ID',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'contact',
      headerName: 'Phone',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'dob',
      headerName: 'DOB',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'bloodGroup',
      headerName: 'Blood Group',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }: any) => (
        <>
          {
            bloodGroup.find((data: any) => data.Code === row.bloodGroup)
              ?.Description
          }
        </>
      ),
    },

    {
      field: 'Action',
      headerName: 'Action',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 190,
      disableColumnMenu: true,
      renderCell: ({ row }: any) => (
        <>
          <CustomButton
            text={'Edit'}
            className='mx-5'
            color={'warning'}
            onClick={() => {
              navigate(APP_ROUTES.VOLUNTEEREDIT, {
                state: {
                  data: row,
                },
              });
            }}
          />
          <CustomButton
            text={'Delete'}
            className='mx-3  color-gray'
            onClick={handleDelete}
          />
        </>
      ),
    },
  ];
  const rows: any = [
    {
      id: 1,
      lastName: 'Snow',
      firstName: 'Jon',
      email: 'Jon@gmail.com',
      contact: '82220894345',
      dob: '1998-05-09',
      bloodGroup: 1,
      gender: 1,
      address: '1/10a , rasipuram , namakkal',
    },
    {
      id: 2,
      lastName: 'Lannister',
      firstName: 'Cersei',
      email: 'Lannister@gamil.com',
      contact: '82220884345',
      dob: '1998-05-09',
      bloodGroup: 4,
      gender: 1,
      address: '1/10a , rasipuram , namakkal',
    },
    {
      id: 3,
      lastName: 'Lannister',
      firstName: 'Jaime',
      email: 'Jaime@gamil.com',
      contact: '82250894345',
      dob: '1998-05-09',
      bloodGroup: 1,
      gender: 1,
      address: '1/10a , rasipuram , namakkal',
    },
    {
      id: 4,
      lastName: 'Stark',
      firstName: 'Arya',
      email: 'Arya@gamil.com',
      contact: '92220894345',
      dob: '1998-05-09',
      bloodGroup: 3,
      gender: 1,
      address: '1/10a , rasipuram , namakkal',
    },
    {
      id: 5,
      lastName: 'Targaryen',
      firstName: 'Daenerys',
      email: 'Daenerys@gamil.com',
      contact: '62220894345',
      dob: '1998-05-09',
      bloodGroup: 1,
      gender: 1,
      address: '1/10a , rasipuram , namakkal',
    },
    {
      id: 6,
      lastName: 'Melisandre',
      firstName: 'mell',
      email: 'Melisandre@gamil.com',
      contact: '95220894345',
      dob: '1998-05-09',
      bloodGroup: 8,
      gender: 1,
      address: '1/10a , rasipuram , namakkal',
    },
    {
      id: 7,
      lastName: 'Clifford',
      firstName: 'Ferrara',
      email: 'Ferrara@gamil.com',
      contact: '98562894345',
      dob: '1998-05-09',
      bloodGroup: 5,
      gender: 1,
      address: '1/10a , rasipuram , namakkal',
    },
    {
      id: 8,
      lastName: 'Frances',
      firstName: 'Rossini',
      email: 'Rossini@gamil.com',
      contact: '985294345',
      dob: '1998-05-09',
      bloodGroup: 6,
      gender: 1,
      address: '1/10a , rasipuram , namakkal',
    },
    {
      id: 9,
      lastName: 'Roxie',
      firstName: 'Harvey',
      email: 'Harvey@gamil.com',
      contact: '82220894345',
      dob: '1998-05-09',
      bloodGroup: 7,
      gender: 1,
      address: '1/10a , rasipuram , namakkal',
    },
  ];
  useEffect(() => {
    setTableRow(rows);
  }, []);
  const handleDelete = () => {
    setOpen(true);
  };
  return (
    <Grid container>
      <Grid xs={12}>
        <VolunteerHeader />
      </Grid>
      <Grid
        xs={12}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid xs={12} justifyContent={'center'} display={'flex'}>
          <CustomButton text={'EXCEL'} className='px-5 py-3 mx-3 color-gray' />
          <CustomButton
            text={'PDF'}
            className='px-5 py-3 mx-3'
            startIcon={PictureAsPdfIcon}
            color={'warning'}
          />
          <CustomButton text={'CSV'} className='px-5 py-3 mx-3 color-gray' />
        </Grid>
      </Grid>
      <Grid xs={12} my={5}>
        {/* <Table columns={columns} rows={tableRow} /> */}
      </Grid>
      <DeleteModel open={open} setOpen={setOpen} success={success} />
      <SuccessModal Succesopen={Succesopen} SuccessetOpen={setSuccesopen} />
    </Grid>
  );
}

export default Volunteer;
