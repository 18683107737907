import {
  ADD_ROLES_IN_PROGRESS,
  ADD_ROLES_IN_SUCCESS,
  ADD_ROLES_IN_ERROR,
  GET_ROLESlIST_IN_PROGRESS,
  GET_ROLESlIST_IN_SUCCESS,
  GET_ROLESlIST_IN_ERROR,
  DELETE_ROLESlIST_IN_PROGRESS,
  DELETE_ROLESlIST_IN_SUCCESS,
  DELETE_ROLESlIST_IN_ERROR,
  UPDATE_ROLESlIST_IN_PROGRESS,
  UPDATE_ROLESlIST_IN_SUCCESS,
  UPDATE_ROLESlIST_IN_ERROR,

       } from "../../../constants/ActionType";
      
  
      

      // ADD ROLES
    export const addRolesInProgress = (payload:any) => ({
      type: ADD_ROLES_IN_PROGRESS,
      payload: payload,
    });
    
    export const addRolesSuccess = (payload:any) => ({
      type: ADD_ROLES_IN_SUCCESS,
      payload: payload,
    });
    
    export const addRolesError = (payload:any) => ({
      type: ADD_ROLES_IN_ERROR,
      payload: payload,
    });
     // GET ROLES
           
 export const getRolesListInProgress = (payload:any) => ({
  type: GET_ROLESlIST_IN_PROGRESS,
  payload: payload,
});

export const getRolesListInSuccess = (payload:any) => ({
  type: GET_ROLESlIST_IN_SUCCESS,
  payload: payload,
});

export const getRolesListInError = (payload:any) => ({
  type: GET_ROLESlIST_IN_ERROR,
  payload: payload,
});
// Delete DONER
export const deleteROLESInProgress = (payload:any) => ({
  type: DELETE_ROLESlIST_IN_PROGRESS,
  payload: payload,
});

export const deleteROLESSuccess = (payload:any) => ({
  type: DELETE_ROLESlIST_IN_SUCCESS,
  payload: payload,
});

export const deleteROLESError = (payload:any) => ({
  type: DELETE_ROLESlIST_IN_ERROR,
  payload: payload,
});
   // UPDATE ROLES
   export const updateRolesInProgress = (payload:any) => ({
    type: UPDATE_ROLESlIST_IN_PROGRESS,
    payload: payload,
  });
  
  export const updateRolesSuccess = (payload:any) => ({
    type: UPDATE_ROLESlIST_IN_SUCCESS,
    payload: payload,
  });
  
  export const updateRolesError = (payload:any) => ({
    type: UPDATE_ROLESlIST_IN_ERROR,
    payload: payload,
  });
    
    
      