  import { Grid, Typography, useTheme } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import LabelWithIcon from '../../../common/ui/LabelWithIcon';
  import CustomButton from '../../../common/ui/CustomButton';
  import Table from '../../../common/TableComponent';
  import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
  import { useForm } from 'react-hook-form';
  import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
  import { useLocation, useNavigate } from 'react-router-dom';
  import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
  import { APP_ROUTES } from '../../../common/constants/Routes';
  import { addRolesInProgress, updateRolesInProgress } from '../../../common/redux/roles/action/rolesAction';
  import { useDispatch } from 'react-redux';
  import TableComponent from '../../../common/TableComponent';
import ErrorModal from '../../../common/ui/Modal/ErrorModal';
import { useTranslation } from 'react-i18next';
  
  const RoleEdit = () => {
    const theme = useTheme();
    const {t}=useTranslation()
    const navigate = useNavigate();
    const locations = useLocation();
    const [errorOpen,setErrorOpen]=useState(false)
    const [formErrors, setFormErrors] = useState({});

    // const roleName = locations?.state?.role;
    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      watch,
      setValue,
      reset,
    } = useForm({
      // defaultValues:{}
     
    });
    const permissions = locations?.state?.role?.permissions;
    const decryptedPermissions =permissions&& JSON.parse(permissions);
    const type =locations?.state?.type;
    const id =locations?.state?.role?.id    
    useEffect(() => {
      if (decryptedPermissions) {

        Object.keys(decryptedPermissions).forEach(module => {
        
          Object.keys(decryptedPermissions[module]).forEach(permission => {
          
            setValue(`${module}[0].${permission}`, decryptedPermissions[module][permission]);
          });
        });
      }
    }, []);
    const validateForm = () => {
      const errors :any= {};
  
      // Validate each module
      const modules = [ 'Donor', 'TeamMember', 'Donate'];
      modules.forEach(module => {
        const moduleData = watch(`${module}[0]`);
  
        const isAnyChecked = moduleData.edit || moduleData.view || moduleData.add || moduleData.delete || moduleData.allaccess || moduleData.noaccess;
  
        if (!isAnyChecked) {
          errors[module] = 'At least one checkbox should be checked';
        } else {
          delete errors[module]; 
        }
      });
  
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const volunteerNoAccess: any = watch('volunteer[0].noaccess');
    const volunteerAdd:any=watch('volunteer[0].add');
    const volunteerDelete:any=watch('volunteer[0].delete');
    const volunteerEdit:any=watch('volunteer[0].edit');
    const volunteerView:any=watch('volunteer[0].view');
    const volunteerAll:any=watch('volunteer[0].allaccess');
    const DonorNoAccess: any = watch('Donor[0].noaccess');
    const DonorAdd:any=watch('Donor[0].add');
    const DonorDelete:any=watch('Donor[0].delete');
    const DonorEdit:any=watch('Donor[0].edit');
    const DonorView:any=watch('Donor[0].view');
    const DonorAll:any=watch('Donor[0].allaccess');
    const TeamMemberNoAccess: any = watch('TeamMember[0].noaccess');
    const TeamMemberAdd:any=watch('TeamMember[0].add');
    const TeamMemberDelete:any=watch('TeamMember[0].delete');
    const TeamMemberEdit:any=watch('TeamMember[0].edit');
    const TeamMemberView:any=watch('TeamMember[0].view');
    const TeamMemberAll:any=watch('TeamMember[0].allaccess');
    const DonateNoAccess: any = watch('Donate[0].noaccess');
    const DonateAdd:any=watch('Donate[0].add');
    const DonateDelete:any=watch('Donate[0].delete');
    const DonateEdit:any=watch('Donate[0].edit');
    const DonateView:any=watch('Donate[0].view');
    const DonateAll:any=watch('Donate[0].allaccess');
    const dispatch =useDispatch()

    
    const handleAllAccessChange = (moduleName:any,event:any) => {

      const isChecked = event;
    
      setValue(`${moduleName}[0].allaccess`, event);
    
      if (!isChecked) {
        setValue(`${moduleName}[0].add`, false);
        setValue(`${moduleName}[0].delete`, false);
        setValue(`${moduleName}[0].edit`, false);
        setValue(`${moduleName}[0].view`, false);
      }
      if (isChecked) {
        setValue(`${moduleName}[0].add`, true);
        setValue(`${moduleName}[0].delete`, true);
        setValue(`${moduleName}[0].edit`, true);
        setValue(`${moduleName}[0].view`, true);
        setValue(`${moduleName}[0].noaccess`, false);
      }
    };
    const handleNoAccessChange = (moduleName:any,event:any) => {
      const isChecked = event;
      if (isChecked) {
        setValue(`${moduleName}[0].add`, false);
        setValue(`${moduleName}[0].delete`, false);
        setValue(`${moduleName}[0].edit`, false);
        setValue(`${moduleName}[0].view`, false);
        setValue(`${moduleName}[0].allaccess`, false);
      }
    };
    const handleAddChange=(moduleName:any,event:any)=>{
      const isChecked = event;
      setValue(`${moduleName}[0].add`, isChecked);
     
  
      if(moduleName==="Donor"){
  const allChecked=DonorDelete&&
      DonorEdit&&
      DonorView
      if(allChecked){
        setValue(`${moduleName}[0].allaccess`, allChecked);
      }
      }
      if(moduleName==="volunteer"){
        const allChecked=volunteerDelete&&
          volunteerEdit&&
          volunteerView
          if(allChecked){
            setValue(`${moduleName}[0].allaccess`, allChecked);
          }
          }
          if(moduleName==="TeamMember"){
            const allChecked=TeamMemberDelete&&
              TeamMemberEdit&&
              TeamMemberView
              if(allChecked){
                setValue(`${moduleName}[0].allaccess`, allChecked);
              }
              }
              if(moduleName==="Donate"){
                const allChecked=DonateDelete&&
                  DonateEdit&&
                  DonateView
                  if(allChecked){
                    setValue(`${moduleName}[0].allaccess`, allChecked);
                  }
                  }
        
    
    
      if (!isChecked) {
        setValue(`${moduleName}[0].allaccess`, false);
      }
      if(isChecked){
        setValue(`${moduleName}[0].noaccess`, false);
      }
  
    

    }
    const handleViewChange=(moduleName:any,event:any)=>{
      const isChecked = event;
      setValue(`${moduleName}[0].view`, isChecked);
      if(isChecked){
        setValue(`${moduleName}[0].noaccess`, false);
      }
  
      if(moduleName==="Donor"){
        const allChecked=DonorDelete&&
          DonorEdit&&
          DonorAdd
          if(allChecked){
            setValue(`${moduleName}[0].allaccess`, allChecked);
          }
          }
          if(moduleName==="volunteer"){
            const allChecked=volunteerDelete&&
              volunteerEdit&&
              volunteerAdd
                if(allChecked){
                  setValue(`${moduleName}[0].allaccess`, allChecked);
                }
                }
                if(moduleName==="TeamMember"){
                const allChecked=TeamMemberDelete&&
                  TeamMemberEdit&&
                  TeamMemberAdd
                    if(allChecked){
                      setValue(`${moduleName}[0].allaccess`, allChecked);
                    }
                    }
                    if(moduleName==="Donate"){
                    const allChecked=DonateDelete&&
                      DonateEdit&&
                      DonateAdd
                        if(allChecked){
                          setValue(`${moduleName}[0].allaccess`, allChecked);
                        }
                        }
      
      if (!isChecked) {
        setValue(`${moduleName}[0].allaccess`, false);
      }

    }
    const handleEditChange=(moduleName:any,event:any)=>{
      const isChecked = event;
      setValue(`${moduleName}[0].edit`, isChecked);
      if(isChecked){
        setValue(`${moduleName}[0].noaccess`, false);
      }
  
      if(moduleName==="Donor"){
        const allChecked=DonorDelete&&
          DonorView&&
          DonorAdd
          if(allChecked){
            setValue(`${moduleName}[0].allaccess`, allChecked);
          }
          }
          if(moduleName==="volunteer"){
            const allChecked=volunteerDelete&&
              volunteerView&&
              volunteerAdd
                if(allChecked){
                  setValue(`${moduleName}[0].allaccess`, allChecked);
                }
                }
                if(moduleName==="TeamMember"){
                const allChecked=TeamMemberDelete&&
                  TeamMemberView&&
                  TeamMemberAdd
                    if(allChecked){
                      setValue(`${moduleName}[0].allaccess`, allChecked);
                    }
                    }
                    if(moduleName==="Donate"){
                    const allChecked=DonateDelete&&
                      DonateView&&
                      DonateAdd
                        if(allChecked){
                          setValue(`${moduleName}[0].allaccess`, allChecked);
                        }
                        }
      if (!isChecked) {
        setValue(`${moduleName}[0].allaccess`, false);
      }

    }
    const handleDeleteChange=(moduleName:any,event:any)=>{
      const isChecked = event;
      setValue(`${moduleName}[0].delete`, isChecked);
      if(isChecked){
        setValue(`${moduleName}[0].noaccess`, false);
      }
  
      if(moduleName==="Donor"){
        const allChecked=DonorEdit&&
          DonorView&&
          DonorAdd
          if(allChecked){
            setValue(`${moduleName}[0].allaccess`, allChecked);
          }
          }
          if(moduleName==="volunteer"){
            const allChecked=volunteerEdit&&
              volunteerView&&
              volunteerAdd
                if(allChecked){
                  setValue(`${moduleName}[0].allaccess`, allChecked);
                }
                }
                if(moduleName==="TeamMember"){
                const allChecked=TeamMemberEdit&&
                  TeamMemberView&&
                  TeamMemberAdd
                    if(allChecked){
                      setValue(`${moduleName}[0].allaccess`, allChecked);
                    }
                    }
                    if(moduleName==="Donate"){
                    const allChecked=DonateEdit&&
                      DonateView&&
                      DonateAdd
                        if(allChecked){
                          setValue(`${moduleName}[0].allaccess`, allChecked);
                        }
                        }
      if (!isChecked) {
        setValue(`${moduleName}[0].allaccess`, false);
      }

    }

    
    
    const columns= [
      {
        dataIndex: 'module',
        title: `${t('Module_access')}`,
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        minWidth: 150,
      },
      {
        dataIndex: 'add',
        title: `${t('Add')}`,
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        flex: 1,
        minWidth: 100,
        render: (_:any,record:any) => (
          <>
            {/* <>{console.log('row', row?.noaccess)}</> */}
            <FormInput
              name={`${record?.module}[0].add`}
              type='checkbox'
              control={control}
              inputProps={{
                ...register(`${record?.module}[0].add`, {}),
                onChange: (e:any) => handleAddChange(record.module, e.target.checked)
              }}
              errors={errors}
            />
          </>
        ),
      },
      {
        dataIndex: 'view',
        title: `${t('View')}`,
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        flex: 1,
        minWidth: 100,
        render: (_:any,record:any) => (
          <>
            <FormInput
              name={`${record?.module}[0].view`}
              type='checkbox'
              control={control}
              inputProps={{
                ...register(`${record?.module}[0].view`, {}),
                onChange: (e:any) => handleViewChange(record.module, e.target.checked)
              }}
              errors={errors}
            />
          </>
        ),
      },
      {
        dataIndex: 'edit',
        title: `${t('EDIT')}`,
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        flex: 1,
        minWidth: 100,
        render: (_:any,record:any) => (
          <>
            <FormInput
              name={`${record?.module}[0].edit`}
              type='checkbox'
              control={control}
              inputProps={{
                ...register(`${record?.module}[0].edit`, {}),
                onChange: (e:any) => handleEditChange(record.module, e.target.checked)
              }}
              errors={errors}
            />
          </>
        ),
      },
      {
        dataIndex: 'delete',
        title: `${t('DELETE')}`,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        flex: 1,
        minWidth: 100,
        render: (_:any,record:any) => (
          <>
            <FormInput
              name={`${record?.module}[0].delete`}
              type='checkbox'
              control={control}
              inputProps={{
                ...register(`${record?.module}[0].delete`, {}),
                onChange: (e:any) => handleDeleteChange(record.module, e.target.checked)
              }}
              errors={errors}
            />
          </>
        ),
      },
      {
        dataIndex: 'AllAccess',
        title: `${t('All_Access')}`,
        align: 'center',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 100,
        render: (_:any,record:any) => (
          <>
            <FormInput
              name={`${record?.module}[0].allaccess`}
              type='checkbox'
              control={control}
              inputProps={{
                ...register(`${record?.module}[0].allaccess`, {}),
                onChange: (e:any) => handleAllAccessChange(record.module, e.target.checked)
              }}
              errors={errors}
              defaultValue={true}
            />
          </>
        ),
      },
      {
        dataIndex: 'noaccess',
        title: `${t('No_Access')}`,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        flex: 1,
        minWidth: 100,
        render: (_:any,record:any) => (
          <>
            <FormInput
              name={`${record?.module}[0].noaccess`}
              type='checkbox'
              control={control}
              inputProps={{
                ...register(`${record?.module}[0].noaccess`, {}),
                onChange: (e:any) => handleNoAccessChange(record.module, e.target.checked)
              }}
              errors={errors}
              defaultValue={record?.noaccess}
            />
          </>
        ),
      },
    ];

    const rows = [
      // {
      //   id: 1,
      //   module: 'volunteer',
      //   add: true,
      //   view: false,
      //   edit: false,
      //   delete: false,
      //   accessAll: false,
      //   noaccess: true,
      // },
      {
        id: 2,
        module: 'Donor',
        add: true,
        view: false,
        edit: false,
        delete: false,
        accessAll: false,
        noaccess: true,
      },
      {
        id: 3,
        module: 'TeamMember',
        add: true,
        view: false,
        edit: false,
        delete: false,
        accessAll: false,
        noaccess: true,
      },
      {
        id: 4,
        module: 'Donate',
        add: true,
        view: false,
        edit: false,
        delete: false,
        accessAll: false,
        noaccess: true,
      },
    ];
    const onSubmit = async (data: any) => {
    
      const isFormValid = validateForm();
  
      // If the form is valid, proceed with dispatch
      if (isFormValid) {
        if (type === "edit") {
          const handleSuccess = () => {
            navigate(APP_ROUTES.ROLE);
          };
          const payload = [{
            id: id,
            name: data?.role,
            permissions: {
              // volunteer: data?.volunteer[0],
              Donor: data?.Donor[0],
              TeamMember: data?.TeamMember[0],
              Donate: data?.Donate[0]
            },
          }];
          dispatch(updateRolesInProgress({ payload, handleSuccess }))
        } else {
          const handleSuccess = () => {
            navigate(APP_ROUTES.ROLE);
          };
          const payload = [{
            name: data?.role,
            permissions: {
              // volunteer: data?.volunteer[0],
              Donor: data?.Donor[0],
              TeamMember: data?.TeamMember[0],
              Donate: data?.Donate[0]
            },
          }];
          dispatch(addRolesInProgress({ payload, handleSuccess }))
        }
      } else {
        // If the form is invalid, set error state or handle accordingly
        setErrorOpen(true);
      }
    };
    

    return (
      <div>
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}
          xs={12}
          my={2}
        >
          {type==="edit"?( <Typography
            fontSize={20}
            fontWeight={600}
            color={theme.palette.info.dark}
            display={'flex'}
            my={{ xs: 1, sm: 0, md: 0 }}
          >
             {t("Edit_Role")}:&nbsp;{' '}
            <Typography fontSize={20} fontWeight={600}>
              {' '}
              {locations?.state?.role?.role }
            </Typography>
          </Typography>):( <Typography
            fontSize={20}
            fontWeight={600}
            color={theme.palette.info.dark}
            display={'flex'}
            my={{ xs: 1, sm: 0, md: 0 }}
          >
           {t("Add_Role")}
            
          </Typography>)}
        

          <CustomButton
            type='button'
            text={`${t("Go_Back")}`}
            startIcon={SubdirectoryArrowRightIcon}
            color={'primary'}
            className='p-8 color-gray'
            onClick={() => {
              navigate(-1);
            }}
          />
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container xs={4}>
            <LabelWithIcon
              label={`${t("Role_Name")}`}
              justifyContent='flex-start'
              className='mb-6'
              labelProps={{ fontSize: 18, fontWeight: 600 }}
            />
            <FormInput
              name='role'
              type='text'
              placeholder={`${t("Role_Name")}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: 'Please enter a First Name',
                },
              }}
              inputProps={{
                ...register('role', {}),
              }}
              errors={errors}
              defaultValue={locations?.state?.role?.role  ||""}
            />
          </Grid>
          <Grid xs={12} my={5}>
          <TableComponent

          columns={columns}
          dataSource={rows}
          type={"dashboard"}
        
      
        
        />
          </Grid>
          <Grid xs={12} my={5} justifyContent={'flex-end'} display={'flex'}>
            <CustomButton
              type='submit'
              // startIcon={AddIcon}
              text={`${t("Save_Role")}`}
              color={'warning'}
              className='p-12'
            />
          </Grid>
        </form>
        <ErrorModal Succesopen={errorOpen} SuccessetOpen={setErrorOpen}/>
      </div>
    );
  };

  export default RoleEdit;
