/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */
/* eslint-disable default-param-last */
/* eslint-disable global-require */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import XLSX from 'xlsx';

export const convertToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
  export const exportToCsv = (csvData: string, fileName: string) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  

export const stripBase64Prefix = (base64: string) => base64.split(',')[1];
export const appendBase64PrefixForImage = (base64: string) =>
  `data:image/png/jpg/jpeg;base64,${base64}`;
