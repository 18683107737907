import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AuthReducer from './authReducer';
import Doner from '../../doner/reducer/donerreducer';
import Donation from '../../Donations/reducers/donationReducer';
import teamMember from '../../teamMembers/reducers/teamMemberReducer';
import roles from '../../roles/reducers/rolesReducer';
import user from '../../user/reducer/userReducer';

const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    doner: Doner,
    donation: Donation,
    teamMember: teamMember,
    roles: roles,
    user: user,
  });

export default rootReducer;
