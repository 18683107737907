import { Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Table from '../../../common/TableComponent';
import { GridValueGetterParams } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';

import DeleteModel from '../../../common/ui/Modal/DeleteModel';
import SuccessModal from '../../../common/ui/Modal/SuccessModal';
import ReportHeader from './ReportHeader';
import { useDispatch } from 'react-redux';
import { getDonationReportInProgress } from '../../../common/redux/Donations/actions/donationAction';
import { useSelector } from 'react-redux';
import TableComponent from '../../../common/TableComponent';
import cryptoEncryptionDecryption from '../../../common/crypo';
import { DatePicker, Spin, Typography } from 'antd';
import { convertDateFormat } from '../../../utils/utilz';
import { useTranslation } from 'react-i18next';
const Reports = () => {
  const {t}=useTranslation()
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [tableRow, setTableRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [date, setDate] = useState<moment.Moment | null | undefined>();
  const [toDate, setToDate] = useState<moment.Moment | null | undefined>();
  const [Succesopen, setSuccesopen] = useState(false);
  const permissions= localStorage.getItem("permissions");
  const decrptedPermissions= cryptoEncryptionDecryption.Decrypt(permissions)
  const decryptedPermissions = JSON.parse(decrptedPermissions);
 
  const decryptedPermissions3 =JSON.parse(decryptedPermissions)
  const success = () => {
    setSuccesopen(true);
  };
  useEffect(()=>{
  
    const payload={
      page:1
    }
    dispatch(getDonationReportInProgress(payload))
  },[])
  const  {donation} = useSelector((state:any) => state);
  const donationReport= donation?.getDonationReport?.data?.data || [];
  const totalRecords2= donation?.getDonationReport?.data?.totalRecords|| [];
  const recentDonationProgress = donation?.getDonationReport?.progressing
  const currentPage= donation?.getDonationReport?.data?.currentPage||0;

  const columns = [
   
    {
      dataIndex: 'date',
      title: `${t('Date')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'materialName',
      title: `${t('Material_Name')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      dataIndex: 'material',
      title: `${t('Material_Count')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      dataIndex: 'amount',
      title: `${t('Amount')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    // {
    //   dataIndex: 'Action',
    //   title: 'Action',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 190,
    //   disableColumnMenu: true,
    //   renderCell: ({ row }: any) => (
    //     <>
    //       <CustomButton text={'View'} className='mx-3  color-gray' />
    //     </>
    //   ),
    // },
  ];
  const handlePageChange2= (page:any) => {
    const payload = {
  
      pageNo: page
    };
    dispatch(getDonationReportInProgress(payload));
  };

  const rows = donationReport?.map((donationItem: any,index:any) => ({
  
    date:convertDateFormat(donationItem?.donatedDate),
    material: donationItem?.materialCount,
    amount: donationItem?.totalAmount,
    materialName:donationItem?.materialType
  }));
  useEffect(() => {
    setTableRow(rows);
  }, []);
  const handleSearchChange = (event:any) => {
    setSearchQuery(event.target.value);
  };
  const handleChange=(date:any)=>{
    setDate( date?.format('YYYY-MM-DD'))
  }
  const handleChange2=(date:any)=>{
    setToDate( date?.format('YYYY-MM-DD'))  
  }
  const  handleSearch =()=>{
   
     
    const payload = {
      page: 1,
      search_query: searchQuery ,
     from_date:date,
     to_date:toDate
    };
    dispatch(getDonationReportInProgress(payload));

 }
  return (
   
    <Grid container>
      <Grid xs={12}>
        <ReportHeader />
      </Grid>
     
      <Grid xs={12} my={5}>
      <Grid  container
            display='flex'
            alignItems={"center"}
            mt={2}>
    <Grid  mr={1} xs={12} sm={12} md={3} lg={2} xl={2} mb={3}>
      <TextField
           label={`${t('Search')}`}
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          
         
        />    
        </Grid>
      
        <Grid mr={2} xs={12} sm={12} md={3} lg={2} xl={2} mb={3}>
          <Typography className='from-date-text'>{t('From_Date')}:</Typography>
       <DatePicker placeholder={t('Select_Date')} style={{height:"55px",width:"200px"}} onChange={handleChange}/></Grid>
      <Grid xs={12} sm={12} md={3} lg={2.5} xl={2} mb={3}> 
      <Typography className='from-date-text'>{t('To_Date')}:</Typography>
        <DatePicker style={{height:"55px",width:"200px"}} onChange={handleChange2}/></Grid>
    
       <Grid xs={12} sm={12} md={2} lg={2} xl={2} mb={2}> <CustomButton text={t('Search')} className='px-5 py-3 mx-3'  color={'warning'} onClick={handleSearch}/></Grid>
        </Grid>
     
       {recentDonationProgress?<Grid className='main-spinner'>
    <Spin size="large"  /> </Grid>: 
      <TableComponent
        columns={columns}
        dataSource={rows}
        pagination={{ 
          total: totalRecords2,
          pageSize: 10, 
         current: currentPage, //
          onChange: handlePageChange2
         
        }}
        type ="report"
        view={decryptedPermissions3?.Donate?.view}
      
        // onChange={onChange}
        // ref={tableRef}
      />
      } 
      </Grid>
      <DeleteModel open={open} setOpen={setOpen} success={success} />
      <SuccessModal Succesopen={Succesopen} SuccessetOpen={setSuccesopen} />
    </Grid>
  );
};

export default Reports;
