import { Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SvgConverter from '../../../common/SvgConverter';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { useFieldArray, useForm } from 'react-hook-form';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import AddDonorFamilyMember from './AddDonorFamilyMember';
import {
  bloodGroup,
  familyMember,
  gender,
  idProofs,
} from '../../../common/constants/dropDownList';
import { useDispatch } from 'react-redux';
import { addDonertInProgress, updateDonertInProgress } from '../../../common/redux/doner/action/donerAction';
import { convertToYYYYMMDD } from '../../../utils/utilz';
import { useTranslation } from 'react-i18next';

const DonerForm = () => {
  const theme = useTheme();
  const location = useLocation();
  const{t}=useTranslation()
  const navigate = useNavigate();
 const dispatch=useDispatch()
 const initialLabel: string | undefined = ""; 



 const [label, setLabel] = useState<string | undefined>(initialLabel);


 
 let FamilyMembers = [];
 FamilyMembers = JSON.parse(location.state?.data?.familyMember || '[]');

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    trigger
  } = useForm({
    defaultValues: {
      firstName: location.state?.data?.firstName || '',
      lastName: location.state?.data?.lastName || '',
      email: location.state?.data?.email_id || '',
      phone: location.state?.data?.phone || '',
      gender_type: location.state?.data?.gender_type || '',
      dob:location?.state?.data?.age?convertToYYYYMMDD(location?.state?.data?.age) || '':"",
      blood_group_id: location.state?.data?.blood_group_id || '',
      address: location.state?.data?.address || '',
      alterNateNumber: location.state?.data?.alterNateNumber || '',
      idProofNumber: location.state?.data?.idProofNumber || '',
      idProofType:location.state?.data?.idProofType || '',
      
      familyMember: FamilyMembers.map((values: any) => ({
        firstName: values.firstName|| '',
        lastName: values.lastName|| '',
        email: values.email|| '',
        phone: values.phone|| '',
        gender_type: values.gender_type|| '',
        dob: values.dob|| '',
        blood_group_id: values.blood_group_id|| '',
        relationship_code: values.relationship_code|| '',
      })),
   
    },
  });


  const { fields, append, remove } = useFieldArray({
    control,
    name: 'familyMember',
  });
  

  const handleOption = (value: any) => {
    setValue('gender_type', value, {
      shouldValidate: true,
    });
  };
  const handleidProofTypeOption= (value: any) => {
   
    setValue('idProofType', value, {
      shouldValidate: true,
    });

    setLabel(idProofs[value-1]?.Description || undefined);



  };
  const onSubmit = (data: any) => {

   
    if(location?.state?.type === 'edit'){
      const handleSucces=()=>{
        navigate(APP_ROUTES.DONER)
       }
       data.id = location.state?.data?.id;
      const payload={
        handleSucces,
        data
      }
    
      
      dispatch(updateDonertInProgress(payload))
    }
    else{ 
      const handleSucces=()=>{
        navigate(APP_ROUTES.DONER)
       }
       const payload={
        handleSucces,
        data
      }
       dispatch(addDonertInProgress(payload))}
  

  };
  const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue('firstName', sanitizedValue, { shouldValidate: true });
	};
  const lastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue('lastName', sanitizedValue, { shouldValidate: true });
	};
  const mobileNo = watch('phone');
  const idProofType = watch('idProofType');
  const alternateMobileNo = watch('alterNateNumber');
  const parseMobileNo = (mobileNo: any) => {
    const allowedFirstDigits = ['6', '7', '8', '9'];
    const numberPattern = /\d+/g;
    const matches: any[] | null = String(mobileNo).match(numberPattern);
    if (matches) {
      const digits = matches.join('');
      if (allowedFirstDigits.includes(digits.charAt(0))) {
        return digits.substring(0, 10);
      }
    }
    return '';
  };
  useEffect(() => {
    if (mobileNo) {
      setValue('phone', parseMobileNo(mobileNo));
    }
  }, [mobileNo]);
  useEffect(() => {
    if (alternateMobileNo) {
      setValue('alterNateNumber', parseMobileNo(alternateMobileNo));
    }
  }, [alternateMobileNo]);
  const handleAddFamily = () => {
    const familyMembers: any = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender_type: '',
      dob: '',
      blood_group_id: '',
      relationship_code: '',
    };
    append(familyMembers);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {

		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};
  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          display='flex'
          justifyContent='flex-start'
          // className='login-form'
        >
          <Grid
            item
            xs={12}
            p={2}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Typography
              fontSize={20}
              fontWeight={600}
              color={theme.palette.info.dark}
            >
              {location?.state?.type === 'edit' ? `${t('Edit_Donor')}` : `${t('Add_Donor')}`}
            </Typography>
            <CustomButton
              type='button'
              text={`${t('Go_Back')}`}
              startIcon={SubdirectoryArrowRightIcon}
              color={'primary'}
              className='p-8 color-gray'
              onClick={() => {
                navigate(APP_ROUTES.DONER);
              }}
            />
          </Grid>
          <Grid item xs={12} p={2}>
            <CustomButton
              type='button'
              text={ `${t('Add_Family_Member')}`}
              startIcon={AddIcon}
              className='custom-button'
              onClick={handleAddFamily}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('First_Name')}`}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <FormInput
              name='firstName'
              type='text'
              placeholder={`${t('First_Name')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: `${t('PLEASE_ENTER_FIRST_NAME')}`,
                },
              }}
              inputProps={{
                ...register('firstName', {}),
                onChange: nameChange,
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('Last_Name')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name='lastName'
              type='text'
              placeholder={`${t('Last_Name')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message:`${t('PLEASE_ENTER_LAST_NAME')}`,
                },
              }}
              inputProps={{
                ...register('lastName', {}),
                onChange: lastNameChange,
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('EMAIL')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name='email'
              type='text'
              placeholder={`${t('EMAIL')}...`}
              control={control}
              className='input-from'
              rules={{
                // required: {
                //     value: true,
                //     message: 'Please enter an Email',
                // },
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: `${t('INVALID_EMAIL_ADDRESS')}`,
                },
            }}
              inputProps={{
                ...register('email', {}),
                onChange: handleEmailChange 
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
      
            <LabelWithIcon
              label={`${t('BLOOD_GROUP')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <CustomSelectBox
              options={bloodGroup}
              ClassName='form-select'
              placeholder={`${t('BLOOD_GROUP')}...`}
              name={'blood_group_id'}
              inputProps={register('blood_group_id', {
                // required: {
                //   value: true,
                //   message: 'select the blood group',
                // },
              })}
              setSelectedOption={handleOption}
              error={errors}
              defaultValue={`${location.state?.data?.blood_group_id}`}
            />
         
          
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('MOBILE_NUMBER')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name='phone'
              type='text'
              placeholder={`${t('MOBILE_NUMBER')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                    value: true,
                    message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
                },
                maxLength: {
                    value: 10,
                    message: `${t('MOBILE_NUMBER_SHOULD_NOT_EXCEED')}`,
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: `${t('ONLY_NUMERIC_CHARACTERS_ALLOWED')}`,
              },
              minLength: {
                value: 10,
                message: `${t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG')}`,
            },}}
              inputProps={{
                ...register('phone', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('Alternate_Mobile')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name='alterNateNumber'
              type='text'
              placeholder={`${t('Alternate_Mobile')}...`}
              control={control}
              className='input-from'
              inputProps={{
                ...register('alterNateNumber', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('Gender')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <CustomSelectBox
              options={gender}
              ClassName='form-select'
              placeholder={`${t('Gender')}...`}
              inputProps={register('gender_type', {
                // required: {
                //   value: true,
                //   message: 'select the gender',
                // },
              })}
              name={'gender_type'}
              error={errors}
              defaultValue={location.state?.data?.gender_type}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('DOB')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              name='dob'
              type='date'
              placeholder={`${t('DOB')}...`}
              control={control}
              className='input-from'
              rules={{
                // required: {
                //   value: true,
                //   message: 'Please enter DOB',
                // },
              }}
              inputProps={{
                ...register('dob', {}),
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('Id_proof_type')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <CustomSelectBox
              options={idProofs}
              ClassName='form-select'
              placeholder={`${t('Id_proof_type')}...`}
              name={'idProofType'}
              inputProps={register('idProofType', {
               
              })}
              setSelectedOption={handleidProofTypeOption}
              error={errors}
              defaultValue={`${location.state?.data?.idProofType}`}
            />
            {idProofType>=1&&(
                  <Grid mt={1}>
                  <LabelWithIcon
                    label={`${label}`}
                    justifyContent='flex-start'
                    className='mb-6 ml-3'
                  />
                  <FormInput
                    name='idProofNumber'
                    type='text'
                    placeholder={`${label}`}
                    control={control}
                    className='input-from'
                    
                    inputProps={{
                      ...register('idProofNumber', {}),
                    }}
                    errors={errors}
                    defaultValue={`${location.state?.data?.idProofNumber}`}
                  />
                  </Grid>
            )}
        
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('ADDRESS')}`}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <FormInput
              hideCountButton
              multipleLine
              name='address'
              type='text'
              placeholder={`${t('ADDRESS')}...`}
              control={control}
              className='input-from-text'
              rules={{
                // required: {
                //   value: true,
                //   message: 'Please enter a Adderss',
                // },
              }}
              inputProps={{
                ...register('address', {}),
              }}
              errors={errors}
            />
          </Grid>
          {fields.length>0&&(
            <>
               <Grid item xs={12} p={2}>
               <Typography color={theme.palette.info.dark} fontSize={18}>
                 {t('Family_Member')}
               </Typography>
             </Grid>
              <Grid item xs={12} p={2}>
              <CustomButton
                type='button'
                text= {t('Add_Family_Member')}
                startIcon={AddIcon}
                className='custom-button'
                onClick={()=>{handleAddFamily()}}
              />
            </Grid>
            </>
          )}
       
          {fields.map((item: any, index: number) => (
            <>
      
              <AddDonorFamilyMember
                index={index}
                control={control}
                errors={errors}
                setValue={setValue}
                register={register}
                remove={remove}
                defaultGender={item.gender_type} // Pass default gender
                defaultRelationship={item.relationship_code} // Pass default relationship
                defaultBloodGroup={item.blood_group_id} 
                watch={watch}
                trigger={trigger}
                handleAddFamily={handleAddFamily}
              />
            </>
          ))}

          <Grid item xs={12} justifyContent='flex-end' display={'flex'} my={5}>
            <CustomButton
              type='button'
              text={`${t('Reset')}`}
              // startIcon={ArrowBackIcon}
              color={'primary'}
              className='p-12 mx-10 color-gray'
              onClick={() => {}}
            />
            <CustomButton
              type='submit'
              // startIcon={AddIcon}
              text={`${t('Save_Donor')}`}
              color={'warning'}
              className='p-12'
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default DonerForm;
