export const AUTH_ROUTES = {
  LOGIN: '/',
  LOGIN_OTP_VERIFY: '/login/otp-verify',
  FORGET_PASSWORD: '/forgetPassword',
};

export const APP_ROUTES = {
  DASHBOARD: '/',
  VOLUNTEER: '/volunteer',
  VOLUNTEERFORM: '/volunteer/add',
  VOLUNTEEREDIT: '/volunteer/edit',
  DONER: '/donor',
  DONER_ADD: '/donor/add',
  DONER_EDIT: '/donor/edit',
  ROLE: '/role',
  ROLEFORM: '/role/add',
  ROLEEDIT: '/role/edit',
  DONATE_ADD: '/donate/add',
  DONATE_EDIT: '/donate/edit',
  DONATION_LIST: '/donation/list',
  PROFILE: '/profile',
  TEAM_MEMBER: '/team/list',
  TEAM_MEMBER_FORM: '/team/add',
  TEAM_MEMBER_EDIT: '/team/edit',
  REPORT: '/report',
  PAYMENTREMINDER: '/payment/reminder',
  CHANGE_PASSWORD: '/changePassword',
  DONATION_PRINT: '/donationPrint',
  CALENDAR: '/calendar',
};

export const PUBLIC_ROUTES = {};
