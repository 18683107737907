import React, { useEffect } from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import Select from 'react-select';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';

import { Controller } from 'react-hook-form';
import theme from '../../../common/theme';
import {
  paymentMethod,
  yourBank,
} from '../../../common/constants/dropDownList';
import { useTranslation } from 'react-i18next';

interface DonationTypesProps {
  setValue: any;
  watch: (name: string, defaultValue?: any) => any;
  control: any; // Adjust the type based on your specific needs
  register: any;
  errors: any;
  submit: any;
  defaultValues?:any;
  trigger?:any;
donationType?:any;
}

const DonationTypes: React.FC<DonationTypesProps> = ({
  setValue,
  watch,
  control,
  register,
  errors,
  submit,
  defaultValues,
  trigger,
  donationType
  
}) => {

const {t}=useTranslation()

  useEffect(() => {
    trigger('paymentNow');
    if(defaultValues?.paymentMethod){
      setValue('paymentMethod', defaultValues?.paymentMethod==="Cash"?1:defaultValues?.paymentMethod==="Cheque"?2:3 || '');
    }
   
if(defaultValues?.bankName){ setValue('bankName', defaultValues?.bankName==="Indian Bank"?1:2 || '');}
   
  
   
  }, [trigger]);
  const paymentMethods = watch('paymentMethod');
  const paymentNow = watch('paymentNow');
  const donateType = watch('donateType');

  useEffect(() => {
  
    if(donateType=== "Material"||Number(donateType)=== 2||paymentNow===false){
    
      setValue('paymentMethod','');
      setValue('donerBank', '');
      setValue('amount', '');
      setValue('paymentNow', false);
      setValue('chequeDate', '');
      setValue('bankName', '');
      setValue('bankBranch', '');
      setValue('upiID', '');
      setValue('additionalInstructions', '');
      setValue("chequeNo",'')
    }
  }, [donateType,paymentNow]);
  
  const handlebankName = (value:any) => {
    setValue('bankName', value, { shouldValidate: true });
  
  };
  const handlepaymentMethod = (value:any) => {
    setValue('paymentMethod', value, { shouldValidate: true });
  
  };
  return (
    <Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        p={2}
        mt={2.5}
        display={'flex'}
        alignItems={'center'}
      >
        <Grid item>
          <LabelWithIcon
            label={`${t("Payment_Now")}`}
            justifyContent='flex-start'
            className='mb-6  '
          />
        </Grid>
        <Grid item>
          <Switch
            {...register('paymentNow')}
            color='warning'
            defaultChecked={defaultValues?.paymentNow||false}
          />
        </Grid>
      </Grid>
      {paymentNow === true &&(
        <Grid container display='flex' justifyContent='center'>
          {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'Payment Method'}
              justifyContent='flex-start'
              className='mb-6'
            />
            <Controller
              control={control}
              name='paymentMethod'
              render={({ field }) => (
                <Select
                  options={paymentMethod}
                  isSearchable
                  placeholder={'Select Payment Method'}
                  value={
                    paymentMethod.find(
                      (option: any) => option.Code === watch('paymentMethod')
                    ) || null
                  }
                  getOptionLabel={(option: any) => option.Description}
                  getOptionValue={(option: any) => option.Code}
                  onChange={(selectedOption: any) => {
                    handlepaymentMethod(selectedOption?.Code || '');
                    field.onChange(selectedOption?.Code || '');
                  }}
                  defaultInputValue={defaultValues?.paymentMethod}
                />
              )}
              rules={{ required: 'Payment Method is required' }}
            />
            {errors.paymentMethod?.message ? (
              <Typography color={theme.palette.error.main} fontSize={12}>
                {errors.paymentMethod?.message}
              </Typography>
            ) : null}
          </Grid> */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t("Amount")}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <FormInput
              name='amount'
              type='number'
              placeholder={t("Amount")}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: `${t('Enter_the_amount')}`,
                },
              }}
              inputProps={{
                ...register('amount', {}),
              }}
              errors={errors}
              defaultValue={defaultValues?.amount}
            />
          </Grid>

          {Number(donationType) !== 1 && (
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <LabelWithIcon
            label={t('Bank_Name')}
            justifyContent='flex-start'
            className='mb-6 '
          />
          <Controller
            control={control}
            name='bankName'
            render={({ field }) => (
              <Select
                options={yourBank}
                isSearchable
                placeholder={`${t('Select_Your_Bank')}`}
                value={
                  yourBank.find(
                    (option: any) => option.Code === watch('bankName')
                  ) || null
                }
                getOptionLabel={(option: any) => option.Description}
                getOptionValue={(option: any) => option.Code}
                onChange={(selectedOption: any) => {
                  
                  handlebankName(selectedOption?.Code || '');
                  field.onChange(selectedOption?.Code || '');
                }}
                defaultInputValue={defaultValues?.bankName||""}
              />
            )}
            rules={{ required: `${t('Bank_Name_Is_Required')}` }}
          />
          {errors.bankName?.message ? (
            <Typography color={theme.palette.error.main} fontSize={12}>
              {errors.bankName?.message}
            </Typography>
          ) : null}
        </Grid>
          )}
          {donationType === "Cheque"||Number(donationType) === 3 ? (
            <>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={t('Cheque_Date')}
                  justifyContent='flex-start'
                  className='mb-6 '
                />
                <FormInput
                  name='chequeDate'
                  type='date'
                  placeholder={`${t('Enter_The_Cheque_Date')}...`}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                      value: true,
                      message: `${t('Enter_The_Cheque_Date')}...`,
                    },
                  }}
                  inputProps={{
                    ...register('chequeDate', {}),
                  }}
                  errors={errors}
                  defaultValue={defaultValues?.chequeDate}
                />
              </Grid>{' '}
              
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
              <LabelWithIcon
                label={t('Donor_Bank')}
                justifyContent='flex-start'
                className='mb-6'
              />
            <FormInput
              name='donerBank'
              type='Text'
              placeholder={`${t('Enter_the_Donor_Bank')}....`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: `${t('Enter_the_Donor_Bank')}`,
                },
              }}
              inputProps={{
                ...register('donerBank', {}),
              }}
              errors={errors}
              defaultValue={defaultValues?.donerBank||""}
            />
             
            </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={t('Bank_Branch')}
                  justifyContent='flex-start'
                  className='mb-6 '
                />
                <FormInput
                  name='bankBranch'
                  type='text'
                  placeholder={t('Enter_The_Bank_Branch')}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                      value: true,
                      message: `${t('Enter_The_Bank_Branch')}`,
                    },
                  }}
                  inputProps={{
                    ...register('bankBranch', {}),
                  }}
                  errors={errors}
                  defaultValue={defaultValues?.bankBranch}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={t('Cheque_Number')}
                  justifyContent='flex-start'
                  className='mb-6 '
                />
                <FormInput
                  name='chequeNo'
                  type='text'
                  placeholder={`${t('Enter_The_Cheque_Number')}`}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                      value: true,
                      message: `${t('Enter_The_Cheque_Number')}`,
                    },
                  }}
                  inputProps={{
                    ...register('chequeNo', {}),
                  }}
                  errors={errors}
                  defaultValue={defaultValues?.chequeNo||""}
                />
              </Grid>
            </>
          ) : donationType === "Online"|| Number(donationType) === 4 ? (
            <>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={t('UPI_ID')}
                  justifyContent='flex-start'
                  className='mb-6 '
                />
                <FormInput
                  name='upiID'
                  type='text'
                  placeholder={`${t('Enter_The_Upi_Id')}`}
                  control={control}
                  className='input-from'
                 
                  inputProps={{
                    ...register('upiID', {}),
                  }}
                  errors={errors}
                  defaultValue={defaultValues?.upiID||""}
                />
              </Grid>
            </>
          ) : null}

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={t('Additional_Instructions')}
              justifyContent='flex-start'
              className='mb-6  '
            />
            <textarea
              name="additionalInstructions"
              placeholder={`${t('Additional_Instructions')}...`}
              className="input-from"
              {...register('additionalInstructions', )}
              defaultValue={defaultValues?.additionalInstructions}
              style={{ width: '100%' }} 
            />
            {errors.additionalInstructions && (
              <Typography color={theme.palette.error.main} fontSize={12}>
                Please enter Additional Instructions
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}></Grid>
        </Grid>
      )}
 
    </Grid>
  );
};

export default DonationTypes;
