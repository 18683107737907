import axios from "axios";
import { getTokenfromLocalStorage } from "../../../../../utils/utilz";

const userInstance = axios.create({
  baseURL: `http://154.61.173.113:3000/`,
});
const token = getTokenfromLocalStorage();


export const updateUserInstanceListEffect = (formData:any) => {

  return userInstance.request({
    url: `/user/update`,
    method: "PUT",  
   data:formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updatePasswordInstanceEffect = (formData:any) => {
 
    return userInstance.request({
      url: `/user/updatePassword`,
      method: "POST",  
      data:formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  };
  export const getProfileInstanceEffect = (formData:any) => {
    return userInstance.request({
      url: `/user/getProfile`,
      method: "GET",
     
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };
  
