import { Grid, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../../common/ui/CustomButton';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
import cryptoEncryptionDecryption from '../../../common/crypo';
import { useTranslation } from 'react-i18next';
function TeamMemberHeader() {
  const navigate = useNavigate();
  const {t}=useTranslation()
  const handleNavigate = () => {

    navigate(APP_ROUTES.DASHBOARD);
  };
  const permissions= localStorage.getItem("permissions");
  const decrptedPermissions= cryptoEncryptionDecryption.Decrypt(permissions)
  const decryptedPermissions = JSON.parse(decrptedPermissions);
 
  const decryptedPermissions3 =JSON.parse(decryptedPermissions)

 
  return (
    <Grid
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className='donation-header'
    >
      <Typography fontSize={20} fontWeight={700}>
       { t("Manage_Team_Members")}
      </Typography>
      

<><CustomButton
        type='button'
        text={`${t("Add_Team_Member")}`}
        startIcon={AddIcon}
        className=' px-10 py-5 my-5'
        fontSize={14}
        disabled={!decryptedPermissions3?.TeamMember?.add}
        onClick={() => {
          navigate(APP_ROUTES.TEAM_MEMBER_FORM);
        }}
        color={'warning'}
      /></>
     
    </Grid>
  );
}

export default TeamMemberHeader;
