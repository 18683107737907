import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import VolunteerHeader from '../Volunteer/VolunteerHeader';
import Table from '../../../common/TableComponent';
import { GridValueGetterParams } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import '../Volunteer/volunteer.scss';
import DonerHeader from './DonerHeader';
import DeleteModel from '../../../common/ui/Modal/DeleteModel';
import SuccessModal from '../../../common/ui/Modal/SuccessModal';
import { useDispatch } from 'react-redux';
import { getDonerListInProgress } from '../../../common/redux/doner/action/donerAction';
import { useSelector } from 'react-redux';
import UploadModal from '../../../common/ui/Modal/UploadModal';
import TableComponent from '../../../common/TableComponent';
import cryptoEncryptionDecryption from '../../../common/crypo';
import { Spin } from 'antd';
import { convertDateFormat } from '../../../utils/utilz';
import { useTranslation } from 'react-i18next';
function Doners() {
  const navigate = useNavigate();
const dispatch=useDispatch()
const {t}=useTranslation()
  const [open, setOpen] = useState(false);
  const [uploadOpen,setUploadOpen]=useState(false)
  const [Succesopen, setSuccesopen] = useState(false);
  const [id,setId]=useState("")

  const success = () => {
    setSuccesopen(true);
  };
  const handleUpload = () => {
    setUploadOpen(true);
  };
  const  {doner} = useSelector((state:any) => state);
  const donorData = doner?.donerList?.data?.data || [];
  const totalRecords = doner?.donerList?.data?.totalRecords || [];
  const donorLoading = doner?.donerList?.progressing
  const currentPage  = doner?.donerList?.data?.currentPage || 0;


  const handlePageChange = (page:any) => {
    dispatch(getDonerListInProgress({ pageNo: page, pagination:true}));
  };
  useEffect(()=>{

    const paylod ={
      pageNo:1,
     pagination_required:"true"
    }
    dispatch(getDonerListInProgress(paylod))
  },[])
  const permissions= localStorage.getItem("permissions");
  const decrptedPermissions= cryptoEncryptionDecryption.Decrypt(permissions)
  const decryptedPermissions = JSON.parse(decrptedPermissions);
 
  const decryptedPermissions3 =JSON.parse(decryptedPermissions)
  const columns = [
    {
      dataIndex: 'donorId',
      title: `${t('Donor_Id')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'firstName',
      title: `${t('First_Name')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'lastName',
      title: `${t('Last_Name')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'email_id',
      title: `${t('EMAIL')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      dataIndex: 'phone',
      title: `${t('MOBILE_NUMBER')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      dataIndex: 'age',
      title: `${t('DOB')}`,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  
    {
    
      title: `${t('Action')}`,
      dataIndex:"Action",
     
      width: 200,

      render: (_:any,record:any) => (
        <>

          <CustomButton
             text={`${t('EDIT')}`}
            disabled={!decryptedPermissions3?.Donor?.edit}
            onClick={() => {
              navigate(APP_ROUTES.DONER_EDIT, {
                state: {
                  data: record,
                  type: 'edit',
                },
              });
            }}
            className='mx-5'
            color={'warning'}
          />
          <CustomButton
           text={`${t('DELETE')}`}
            disabled={!decryptedPermissions3?.Donor?.delete}
            className='mx-3 color-gray'
            onClick={() => {
              setOpen(true);
              setId(record.id)
            }}
       
          />
        </>
      ),
    },
  ];
  
  const filteredDonorData = donorData.filter((donor:any) => donor.others 
  !== Number(1));

  
  
  const rows2 = filteredDonorData.map((donor: any) => ({
    donorId: donor?.donors_serial_no,
    id: donor.id,
    firstName: donor.firstName,
    lastName: donor.lastName,
    email_id: donor.email,
    phone: donor.phone,
    age: convertDateFormat(donor.dob),
    gender_type: donor.gender_type,
    blood_group_id: donor.blood_group_id,
    address: donor.address,
    alterNateNumber: donor.alterNateNumber,
    idProofNumber: donor.idProofNumber,
    familyMember: donor.FamilyMembers ? JSON.stringify(donor.FamilyMembers) : "No family members",
    idProofType: donor.idProofType
  }));
  

  return (
    donorLoading?
    <Grid className='main-spinner'>
    <Spin size="large"  /> </Grid>:  
    <Grid container>
      <Grid xs={12}>
        <DonerHeader />
      </Grid>
      <Grid
        xs={12}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {/* <Grid xs={12} justifyContent={'center'} display={'flex'}>
          <CustomButton text={'EXCEL'} className='px-5 py-3 mx-3 color-gray' />
          <CustomButton
            text={'PDF'}
            className='px-5 py-3 mx-3'
            startIcon={PictureAsPdfIcon}
            color={'warning'}
          />
          <CustomButton text={'CSV'} className='px-5 py-3 mx-3 color-gray' />
       <CustomButton text={'UPLOAD'} className='px-5 py-3 mx-3'  color={'warning'} onClick={handleUpload}/>
        </Grid> */}
      </Grid>

      <Grid xs={12} my={5}>
       
<TableComponent
        columns={columns}
        dataSource={rows2}
        pagination={{
        
          total: totalRecords,
          pageSize: 10, 
          current: currentPage,
          onChange: handlePageChange
        }}
        // bordered={bordered}
        type="donor"
        add={decryptedPermissions3?.Donor?.add}
        view={decryptedPermissions3?.Donor?.view}
      
        // onChange={onChange}
        // ref={tableRef}
      />
      </Grid>
      <DeleteModel open={open} setOpen={setOpen} success={success} id={id}/>
      <SuccessModal Succesopen={Succesopen} SuccessetOpen={setSuccesopen} />
      <UploadModal open={uploadOpen} setOpen={setUploadOpen}/>
    </Grid>
  );
}

export default Doners;
