import * as React from 'react';

import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18next from '../../../i18n';
import './Login.scss';
import CommonModal from '../../../common/ui/Modal/CommonModal';
import theme from '../../../common/theme';
import CustomRadioGroup from '../../../common/ui/Forms/FormInput/RadioButton/RadioGroup';
import { APP_ROUTES, AUTH_ROUTES } from '../../../common/constants/Routes';
import CustomButton from '../../../common/ui/CustomButton';



interface UserProps {
	open?: any;
	setOpen?: any;
	contentType?: 'LOGIN' | 'DASHBOARD';
}

const LanguageSelector: React.FC<UserProps> = ({
	open,
	setOpen,
	contentType = 'LOGIN',
}) => {
	const { t } = useTranslation();

	const languageList = [
		{ value: 'en', label: 'English' },
		{ value: 'ta', label: 'தமிழ்' },
		,
	];
	const [selectedOption, setSelectedOption] = React.useState<any>(false);
	const handleOptionChange = (value: string) => {
		setSelectedOption(value);
	};
	const changeLanguage = () => {
		i18next.changeLanguage(selectedOption);
		localStorage.setItem('ln', selectedOption);
		setOpen(false);
	};
	React.useEffect(() => {
		if (contentType === 'LOGIN') {
			setSelectedOption('en');
		} else if (contentType === 'DASHBOARD') {
			setSelectedOption(localStorage.getItem('ln'));
		}
	}, []);
	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			modalClassName="px-20 py-20 "
			boxProps={{ width: 350, height: 440 }}
		>
			<Grid container>
				<Grid item xs={12} className="mt-20 ml-20">
					<Typography
						color={theme.palette?.common?.black}
						className="mb-10"
						fontSize={18}
						fontWeight={600}
					>
						Select Language
					</Typography>
				</Grid>
				<Grid item xs={12} className="mt-20 ml-20" height={250}>
					<CustomRadioGroup
						name="RadioGroup"
						options={languageList}
						value={selectedOption}
						onChange={handleOptionChange}
						size="small"
						inputClassName="my-4"
					/>
				</Grid>
				<Grid container justifyContent="space-between" className="mt-30 ml-20">
					<Grid className="py-5">
						<Typography
							color={theme.palette?.common?.black}
							className="mb-10 cursur-pointer"
							fontSize={18}
							onClick={() => setOpen(false)}
						>
							{contentType === 'LOGIN' ? 'BACK' : 'CANCEL'}
						</Typography>
					</Grid>
					<Link
						to={
							contentType === 'LOGIN'
								? AUTH_ROUTES.LOGIN
								: APP_ROUTES.DASHBOARD
						}
					>
						<Grid className="py-5 mr-30">
							<CustomButton
								text={contentType === 'LOGIN' ? t('CONTINUE') : 'CHANGE'}
								variant="text"
								color="primary"
								fontSize={18}
								fontWeight={600}
								onClick={changeLanguage}
								// onClick={() => setOpen(false)}
							/>
						</Grid>
					</Link>
				</Grid>
			</Grid>
		</CommonModal>
	);
};
export default LanguageSelector;
