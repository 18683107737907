import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
const availableLanguages = ['ta', 'en'];
// const apiKey = 'KcyX5H-rZUHLgisXju2Nug';
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
const option = {
  order: ['navigator', 'htmlTag', 'path', 'subdomain'],
  checkWhitelist: true,
};

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('ln'),
    fallbackLng: 'en',
    debug: false,
    whiteList: availableLanguages,
    detection: option,
    useSuspense: false,
    interpolation: {
      escapeValue: false,
    },
    // ns: ["default"],
    // defaultNS: "default",
    // supportedLngs: ["en", "ta", "hi"],
    // backend: {
    //   loadPath: loadPath,
    // },
  });

export default i18n;
