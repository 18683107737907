import { Grid, Typography, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';

import LoginForm from './LoginForm';
import './Login.scss';
import person from '../../../common/icons/disable.png';
const Login = () => {
  const theme = useTheme();
  return (
    <>
      <Grid container justifyContent='center' className='login'>
        <Grid item xs={6} className='login-image'>
          <img src={person} className='person-image' />
        </Grid>
        <Grid
          item
          xs={6}
          justifyContent='center'
          display={'flex'}
          alignItems={'center'}
          className='login-form-main'
        >
          <LoginForm />
        </Grid>
      </Grid>
    </>
  );
};
export default Login;
