import { Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import VolunteerHeader from '../Volunteer/VolunteerHeader';
import Table from '../../../common/TableComponent';
import { GridValueGetterParams } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import '../Volunteer/volunteer.scss';
// import DonerHeader from './DonerHeader';
import DeleteModel from '../../../common/ui/Modal/DeleteModel';
import SuccessModal from '../../../common/ui/Modal/SuccessModal';
import PaymentReminderHeader from './PaymentReminderHeader';
import { useSelector } from 'react-redux';
import { getDonationListInProgress, getDonationReportInProgress, getRecentDonationInProgress } from '../../../common/redux/Donations/actions/donationAction';
import { useDispatch } from 'react-redux';
import TableComponent from '../../../common/TableComponent';
import cryptoEncryptionDecryption from '../../../common/crypo';
import { DatePicker, Spin, Typography } from 'antd';
import { convertDateFormat } from '../../../utils/utilz';
import { useTranslation } from 'react-i18next';
// import DonationHeader from './DonationHeader';
const PaymentReminder = () => {
  const {t}=useTranslation()
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [date, setDate] = useState<moment.Moment | null | undefined>();
  const [toDate, setToDate] = useState<moment.Moment | null | undefined>();
  const dispatch =useDispatch()
  const [Succesopen, setSuccesopen] = useState(false);
  const  {donation} = useSelector((state:any) => state);
  useEffect(()=>{
    const paylod ={
      pageNo:1
    }

dispatch(getRecentDonationInProgress(paylod))  


  },[])
  const donorDatas = donation?.getRecentDonation?.data?.data || [];
  const totalRecords2= donation?.getRecentDonation?.data?.totalRecords|| [];
  const currentPage=donation?.getRecentDonation?.data?.currentPage||0;

  const permissions= localStorage.getItem("permissions");
  const decrptedPermissions= cryptoEncryptionDecryption.Decrypt(permissions)
  const decryptedPermissions = JSON.parse(decrptedPermissions);
 
  const decryptedPermissions3 =JSON.parse(decryptedPermissions)
  const recentDonationProgress = donation?.getRecentDonation?.progressing
  const success = () => {
    setSuccesopen(true);
  };
  const columns = [
    {
      dataIndex: 'id',
      title: `${t('Donation_Id')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'DonateType',
      title: `${t('Donate_Type')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'DonatedBY',
      title: `${t('Donated_by')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'DonationFor',
      title: `${t('Donated_For')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      dataIndex: 'donatedDate',
      title: `${t('Donated_date')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      dataIndex: 'PaymentDate',
      title: `${t('Payment_Date')}`,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];
  const rows2= donorDatas?.map((donationItem: any) => ({
 
    id: donationItem?.donations_serial_no,
    recurringDonation:donationItem?.id,
    DonationFor: donationItem?.donatedFor,
    DonatedBY: donationItem?.Doner_name,
    PaymentDate: convertDateFormat(donationItem?.donatedDate),
    DonateType: donationItem?.donateType === '1' ? 'Cash' : 'Material', 
    donatedDate:convertDateFormat(donationItem?.donatedDate),
    donationId:donationItem?.donations_serial_no,
  
    donatedFor: donationItem.donatedFor,
    donatedBy: donationItem.Doner_name,
  
    donateType: donationItem.donateType === '1' ? 'Cash' : 'Material', 
    familyMember:donationItem.familyMember,
   
    paymentNow: donationItem.paymentNow,
    amount: donationItem.amount,
    additionalInstructions:donationItem.additionalInstructions,
    paymentMethod: donationItem.paymentMethod=== '1'?"Cash": donationItem.paymentMethod?donationItem.paymentMethod==="2"?"Cheque":"Online":"",
    donerBank:donationItem.donerBank,
    chequeDate: donationItem.chequeDate,
    bankBranch: donationItem.bankBranch,
    bankName:donationItem.bankName=== '1'?"Indian Bank":donationItem.bankName==="2"?"Axis Bank":"",
    materialType: donationItem.materialType,
    count: donationItem.count,
    upiID:donationItem.upiID,
    additionalInstructionsMaterial: donationItem.additionalInstructionsMaterial,
    donationTime: donationItem.donationTime ? JSON.stringify(donationItem.donationTime) : "No Donation Time",
    donatedBy_id: donationItem.donatedBy,
    Doner_serial_no: donationItem.Doner_serial_no,
    Doner_address: donationItem.Doner_address,
    Doner_phone: donationItem.Doner_phone,
    additionalInstructionsGeneral:donationItem.additionalInstructionsGeneral,
    chequeNo:donationItem.chequeNo,
    idProofType:donationItem.idProofType,
    idProofNumber:donationItem.idProofNumber,
    Doner_Id:donationItem.Doner_Id    
  
  
    
    
  }));
  const handlePageChange2= (page:any) => {
    const payload = {
     
      pageNo: page
    };
    dispatch(getRecentDonationInProgress(payload));
  };
  const handleSearchChange = (event:any) => {
    setSearchQuery(event.target.value);
  };
  const handleChange=(date:any)=>{
    setDate( date?.format('YYYY-MM-DD'))
 
      // const payload = {
      //   page: 1,
      //   report_date: date?.format('YYYY-MM-DD')
      // };
      // dispatch(getRecentDonationInProgress(payload));

   
   
  }
  const  handleSearch =()=>{
   
     
      const payload = {
        page: 1,
        search_query: searchQuery ,
       from_date:date,
       to_date:toDate
      };
      dispatch(getRecentDonationInProgress(payload));
 
   }
   const handleChange2=(date:any)=>{
    setToDate( date?.format('YYYY-MM-DD'))  
  }

  return (
  
    <Grid container>
      <Grid xs={12}>
        <PaymentReminderHeader />
      </Grid>
      <Grid
        xs={12}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
       
      </Grid>
      <Grid   container
            display='flex'
            alignItems={"center"}
            mt={2}  >
    <Grid  mr={1} xs={12} sm={12} md={3} lg={2} xl={2} mb={3}>
      <TextField
           label={`${t('Search')}`}
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          
         
        />    
        </Grid>
    
        <Grid  mr={2} xs={12} sm={12} md={3} lg={2} xl={2} mb={3}>
          <Typography className='from-date-text'>{t('From_Date')}:</Typography>
       <DatePicker placeholder={t('Select_Date')} style={{height:"55px",width:"200px"}} onChange={handleChange}/></Grid>
      <Grid xs={12} sm={12} md={3} lg={2.5} xl={2} mb={3}> 
      <Typography className='from-date-text'>{t('To_Date')}:</Typography>
        <DatePicker placeholder={t('Select_Date')} style={{height:"55px",width:"200px"}} onChange={handleChange2}/></Grid>
     
       <Grid xs={12} sm={12} md={2} lg={2} xl={2} mb={2}> <CustomButton text={t('Search')} className='px-5 py-3 mx-3'  color={'warning'} onClick={handleSearch}/></Grid>
        </Grid>
   
      <Grid xs={12} my={5}>
      { recentDonationProgress? <Grid className='main-spinner'>
    <Spin size="large"  /> </Grid>:  
      <TableComponent
        columns={columns}
        dataSource={rows2}
        pagination={{
        
          total: totalRecords2,
          pageSize: 10, 
          current: currentPage, //
          onChange: handlePageChange2
        }}
        type="recentdonation"
        view={decryptedPermissions3?.Donate?.view}
       
       
        // onChange={onChange}
        // ref={tableRef}
      />}
      </Grid>
      <DeleteModel open={open} setOpen={setOpen} success={success} />
      <SuccessModal Succesopen={Succesopen} SuccessetOpen={setSuccesopen} />
    </Grid>
  );
};

export default PaymentReminder;
