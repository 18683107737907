import React, { useEffect } from 'react';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { Grid, Typography, useTheme } from '@mui/material';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import {
  bloodGroup,
  familyMember,
  gender,
} from '../../../common/constants/dropDownList';
import Separator from '../../../common/ui/Separator';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../../common/ui/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
const AddDonorFamilyMember = ({
  control,
  errors,
  setValue,
  index,
  register,
  remove,
  defaultGender,
  defaultRelationship,
  defaultBloodGroup,
  watch,
  trigger,
  handleAddFamily
}: {
  control: any;
  errors: any;
  setValue: any;
  index: any;
  register: any;
  remove: any;
  defaultGender: string; // Add these props
  defaultRelationship: string; // Add these props
  defaultBloodGroup: string; // Add these props
  watch?:any;
  trigger?:any;
  handleAddFamily?:any;
}) => {
  const {t}=useTranslation()
  const theme = useTheme();
  const handleOption = (value: any) => {
    setValue('gender_type', value, {
      shouldValidate: true,
    });
  };
  let stringNumber = defaultRelationship.toString();
  const mobileNo = watch(`familyMember.[${index}].phone`);
  const parseMobileNo = (mobileNo: any) => {
    const allowedFirstDigits = ['6', '7', '8', '9'];
    const numberPattern = /\d+/g;
    const matches: any[] | null = String(mobileNo).match(numberPattern);
    if (matches) {
      const digits = matches.join('');
      if (allowedFirstDigits.includes(digits.charAt(0))) {
        return digits.substring(0, 10);
      }
    }
    return '';
  };
  useEffect(() => {
    if (mobileNo) {
      setValue(`familyMember.[${index}].phone`, parseMobileNo(mobileNo));
    }
  }, [mobileNo]);
  const nameChange = (e: React.ChangeEvent<HTMLInputElement>,index:any) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue(`familyMember.[${index}].firstName`, sanitizedValue, { shouldValidate: true });
	};
  const lastNameChange = (e: React.ChangeEvent<HTMLInputElement>,index:any) => {
		const inputValue = e.target.value;
		// Allow only alphabets and space
		const sanitizedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
		setValue(`familyMember.[${index}].lastName`, sanitizedValue, { shouldValidate: true });
	};
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>,index:any) => {
 
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue(`familyMember.[${index}].email`, emailValue, { shouldValidate: true });
		trigger(`familyMember.[${index}].email`);
	};

  return (
    <>
   
     
     
          <Grid display={"flex"} alignItems={"center"} xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4} p={1}>
        <LabelWithIcon
            label={`${t('First_Name')}`}
          justifyContent='flex-start'
          className='mb-6  '
        />
        <FormInput
          name={`familyMember.[${index}].firstName`}
          type='text'
          placeholder={`${t('First_Name')}...`}
          control={control}
          className='input-from'
          rules={{
            required: {
              value: true,
              message: `${t('PLEASE_ENTER_FIRST_NAME')}`,
            },
          }}
          inputProps={{
            ...register(`familyMember.[${index}].firstName` as const),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            nameChange(e, index),
           

          }}
        
          errors={errors}
        />
        {errors && errors.familyMember && errors.familyMember[index] && errors.familyMember[index].firstName && (
  <Typography color={"red"}fontSize={12}>{errors.familyMember[index].firstName.message}</Typography>
)}
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4} p={1}>
      <LabelWithIcon
          label={`${t('Relationship')}`}
          justifyContent='flex-start'
          className='mb-6 ml-3'
        />
        <CustomSelectBox
          options={familyMember}
          ClassName='form-select'
          placeholder={`${t('Relationship')}...`}
          inputProps={register(
            `familyMember.[${index}].relationship_code` as const,
            {
              required: {
                value: true,
                message: 'select the relationShip',
              },
            }
          )}
          name={`familyMember.[${index}].relationship_code`}
          setSelectedOption={handleOption}
          error={errors?.familyMember}
          defaultValue={stringNumber}
          //   defaultValue={`${location.state?.data?.Gender}`}
          //   defaultValue={location.state.data.gender}
        />
                                       {errors && errors.familyMember && errors.familyMember[index] && errors.familyMember[index].relationship_code && (
  <Typography color={"red"}fontSize={12}>{errors.familyMember[index].relationship_code.message}</Typography>
)}
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4} p={1}>
      <CustomButton
          startIcon={CloseIcon}
          type='button'
          text={`${t("Remove_Relationship")}`}
          className='custom-button'
          onClick={() => {
            remove(index);
          }}
        />
      </Grid>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <LabelWithIcon
          label={'Email'}
          justifyContent='flex-start'
          className='mb-6 ml-3'
        />
        <FormInput
          name={`familyMember.[${index}].email`}
          type='text'
          placeholder='Email..'
          control={control}
          className='input-from'
          rules={{
            required: {
                value: true,
                message: 'Please enter an Email',
            },
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
            },
        }}
          inputProps={{
            ...register(`familyMember.[${index}].email` as const),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleEmailChange(e, index),
          }}
          errors={errors}
        />
                        {errors && errors.familyMember && errors.familyMember[index] && errors.familyMember[index].email && (
  <Typography color={"red"} fontSize={12}>{errors.familyMember[index].email.message}</Typography>
)}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <LabelWithIcon
          label={'Mobile number'}
          justifyContent='flex-start'
          className='mb-6 ml-3'
        />
        <FormInput
          name={`familyMember.[${index}].phone`}
          type='text'
          placeholder='Mobile..'
          control={control}
          className='input-from'
          rules={{
            required: {
                value: true,
                message: 'Please enter a Mobile number',
            },
            maxLength: {
                value: 10,
                message: 'Mobile number should not exceed 10 digits',
            },
            pattern: {
              value: /^[0-9]*$/,
              message: 'Only numeric characters are allowed',
          },
          minLength: {
            value: 10,
            message: 'Mobile number should be at least 10 digits long',
        },
        }}
          inputProps={{
            ...register(`familyMember.[${index}].phone` as const),
          }}
          errors={errors}
        />
                               {errors && errors.familyMember && errors.familyMember[index] && errors.familyMember[index].phone && (
  <Typography color={"red"}fontSize={12}>{errors.familyMember[index].phone.message}</Typography>
)}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <LabelWithIcon
          label={'RelationShip'}
          justifyContent='flex-start'
          className='mb-6 ml-3'
        />
        <CustomSelectBox
          options={familyMember}
          ClassName='form-select'
          placeholder={'relationShip'}
          inputProps={register(
            `familyMember.[${index}].relationship_code` as const,
            {
              required: {
                value: true,
                message: 'select the relationShip',
              },
            }
          )}
          name={`familyMember.[${index}].relationship_code`}
          setSelectedOption={handleOption}
          error={errors?.familyMember}
          defaultValue={stringNumber}
          //   defaultValue={`${location.state?.data?.Gender}`}
          //   defaultValue={location.state.data.gender}
        />
                                       {errors && errors.familyMember && errors.familyMember[index] && errors.familyMember[index].relationship_code && (
  <Typography color={"red"}fontSize={12}>{errors.familyMember[index].relationship_code.message}</Typography>
)}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <LabelWithIcon
          label={'Gender'}
          justifyContent='flex-start'
          className='mb-6 ml-3'
        />
        <CustomSelectBox
          options={gender}
          ClassName='form-select'
          placeholder={'gender_type'}
          inputProps={register(`familyMember.[${index}].gender_type` as const, {
            required: {
              value: true,
              message: 'select the gender',
            },
          })}
          name={`familyMember.[${index}].gender_type`}
          setSelectedOption={handleOption}
          error={errors}
          defaultValue={defaultGender}
          //   defaultValue={`${location.state?.data?.Gender}`}
          //   defaultValue={location.state.data.gender}
        />
                                               {errors && errors.familyMember && errors.familyMember[index] && errors.familyMember[index].gender_type && (
  <Typography color={"red"}fontSize={12}>{errors.familyMember[index].gender_type.message}</Typography>
)}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
        <LabelWithIcon
          label={'DOB'}
          justifyContent='flex-start'
          className='mb-6 ml-3'
        />
        <FormInput
          name={`familyMember.[${index}].dob`}
          type='date'
          placeholder='dob..'
          control={control}
          className='input-from'
          inputProps={{
            ...register(`familyMember.[${index}].dob` as const),
          }}
          rules={{
            required: {
              value: true,
              message: 'Please enter DOB',
            },
          }}
          errors={errors}
        />
                                                       {errors && errors.familyMember && errors.familyMember[index] && errors.familyMember[index].dob && (
  <Typography color={"red"}fontSize={12}>{errors.familyMember[index].dob.message}</Typography>
)}
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        p={2}
        justifyContent={'flex-start'}
      >
        <LabelWithIcon
          label={'Blood Group'}
          justifyContent='flex-start'
          className='mb-6 ml-3'
        />
        <CustomSelectBox
          options={bloodGroup}
          ClassName='form-select'
          placeholder={'Blood group'}
          name={`familyMember.[${index}].blood_group_id`}
          inputProps={register(`familyMember.[${index}].blood_group_id` as const, {
            required: {
              value: true,
              message: 'select the blood group',
            },
          })}
          setSelectedOption={handleOption}
          defaultValue={defaultBloodGroup}
          error={errors}
          //   defaultValue={`${location.state?.data?.bloodGroup}`}
        />
           {errors && errors.familyMember && errors.familyMember[index] && errors.familyMember[index].blood_group_id && (
  <Typography color={"red"}fontSize={12}>{errors.familyMember[index].blood_group_id.message}</Typography>
)}
      </Grid> */}
   
      
      <Grid item xs={12} p={2}>
        
      </Grid>
    </>
  );
};

export default AddDonorFamilyMember;
