import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
const AppFooter = () => {
  const theme = useTheme();

  return (
    <Box
      className='text-center py-14'
      // sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Grid container alignItems='center' justifyContent='center'></Grid>
    </Box>
  );
};

export default AppFooter;
