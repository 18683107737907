export const getErrorMessageFromAPI = ({ response }) => {
  if (response?.data?.message) {
    return response?.data?.message;
  } else if (response?.data?.errorFields) {
    return Object.keys(response?.data?.errorFields).length === 0
      ? "Kindly check later"
      : response?.data?.errorFields;
  } else {
    return response?.data;
  }
};

export const getStatusFromAPI = ({ response }) => {
  if (response?.data?.status === "pending") {
    console.log("Pending");
  } else if (response?.data?.status === "200") {
    console.log("Response");
  }
};

export const getTokenfromLocalStorage = () => {
  return localStorage.getItem("user_id");
};
export function convertDateFormat(originalDate) {
  // Check if originalDate is empty string or null
  if (!originalDate) {
    return "-";
  }

  // Parse original date string into a Date object
  const dateObj = new Date(originalDate);

  // Extract day, month, and year components
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1; // January is 0 in JavaScript
  const year = dateObj.getFullYear();

  // Format the date components into the desired format (DD-MM-YYYY)
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year}`;

  return formattedDate;
}

export function convertToYYYYMMDD(originalDate) {
  // Split the original date string by '-'
  const parts = originalDate.split("-");

  // Rearrange the parts to form the YYYY-MM-DD format
  const yyyy_mm_dd = `${parts[2]}-${parts[1]}-${parts[0]}`;

  return yyyy_mm_dd;
}
export const convertAmountToWords = (amount) => {
  const singleDigits = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const numToWords = (num) => {
    if (num < 10) return singleDigits[num];
    if (num < 20) return teens[num - 10];
    if (num < 100)
      return tens[Math.floor(num / 10)] + " " + singleDigits[num % 10];
    if (num < 1000)
      return (
        singleDigits[Math.floor(num / 100)] +
        " hundred " +
        numToWords(num % 100)
      );
    if (num < 1000000)
      return (
        numToWords(Math.floor(num / 1000)) +
        " thousand " +
        numToWords(num % 1000)
      );
    if (num < 1000000000)
      return (
        numToWords(Math.floor(num / 1000000)) +
        " million " +
        numToWords(num % 1000000)
      );
  };

  const words = numToWords(amount);
  return words?.charAt(0).toUpperCase() + words?.slice(1) + " rupees only";
};

// Usage
