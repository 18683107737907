import { Grid, IconButton, Typography, useTheme } from '@mui/material';

import './OtpScreen.css';
import CustomButton from '../../../common/ui/CustomButton';
import theme from '../../../common/theme';
import OTPInput from 'react-otp-input';
import { useState } from 'react';
import { APP_ROUTES, AUTH_ROUTES } from '../../../common/constants/Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { otpVerificationInProgress } from '../../../common/redux/auth/action/authAction';
import { useTranslation } from 'react-i18next';

const OtpScreen = () => {
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const { t } = useTranslation();
 
  const [isFilled, setIsFilled] = useState(false);
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const handleOtpChange = (otpValue: string) => {

    setOtp(otpValue);
    setIsFilled(otpValue.length === 6);
    // setIsValid(otpValue.length === 6);
  };
  const onSubmit=()=>{
    const handleSuccess=()=>{
      navigate(AUTH_ROUTES.FORGET_PASSWORD,{state:location?.state?.email})
    }
    const data ={
      email:location?.state?.email,
      verification_code:otp,
    }
    const payload={
     data,
      handleSuccess

    }
    dispatch(otpVerificationInProgress(payload))
  }
  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      mt={5}
    >
      <Grid xs={12} justifyContent={'center'} display={'flex'} my={3}>
      <Typography>{t('ENTER_THE_OTP')}</Typography>
      </Grid>
      <Grid xs={12} justifyContent={'center'} display={'flex'} my={2}>
        <Typography>
        {t('ONE_TIME_PASSWORD')}
        </Typography>
      </Grid>
      <OTPInput
        numInputs={6}
        placeholder='XXXXXX'     
        value={otp}
        onChange={handleOtpChange}
        inputStyle='otp-input-style'
        containerStyle='otp-input-container'
        renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} />}
      />
      <Grid xs={12} justifyContent={'center'} display={'flex'} my={3}>
      <Typography> {t('RESEND_OTP')}</Typography>
      </Grid>
      <Grid xs={12} justifyContent={'center'} display={'flex'}>
        <CustomButton
          type='button'
          text={t('BACK')}
          className='back-button'
          color='primary'
          onClick={() => {
            navigate(AUTH_ROUTES.LOGIN);
          }}
        />
        <CustomButton
          type='submit'
          text={t('VERFIY')}
          className='otp-button'
          onClick={onSubmit}
         disabled={!isFilled}
          //   color={'secondary'}
        />
      </Grid>
    </Grid>
  );
};

export default OtpScreen;
