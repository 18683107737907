import { Grid, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import VolunteerHeader from '../Volunteer/VolunteerHeader';
import { Col, DatePicker, Row, Spin, Table, Typography } from "antd";
import { GridValueGetterParams } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import '../Volunteer/volunteer.scss';
import DonationHeader from './DonationHeader';
import ViewModel from '../../../common/ui/Modal/ViewModel';
import { useDispatch } from 'react-redux';
import { getCsvInProgress, getDonationListInProgress } from '../../../common/redux/Donations/actions/donationAction';
import { useSelector } from 'react-redux';
import { TableProps } from 'antd/lib/table';
import TableComponent from '../../../common/TableComponent';
import cryptoEncryptionDecryption from '../../../common/crypo';
import { Margin } from '@mui/icons-material';
import { convertDateFormat } from '../../../utils/utilz';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import { DonateTypePayload, PaymentType, idProofs } from '../../../common/constants/dropDownList';
import ExcelJS from 'exceljs'; 
import { useTranslation } from 'react-i18next';

function DonationList() {
  const navigate = useNavigate();
  const {t}=useTranslation()
  const dispatch=useDispatch()
  const [date, setDate] = useState<moment.Moment | null | undefined>();    
  const [toDate, setToDate] = useState<moment.Moment | null | undefined>();
  const [donateTypeFilter, setDonateTypeFilter] = useState('');
  // const tableRef = useRef<Table<any> | null>(null);
  const [veiwOpen, setVeiwOpen] = useState(false);
  const [donationId, setdonationId] = useState("");
  const [donorId, setDonorId] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const  {donation} = useSelector((state:any) => state);
  const donorData = donation?.donationList?.data?.data || [];
  const donationData = donation?.donationList?.data?.totalRecords || 0;
  const currentPage  = donation?.donationList?.data?.currentPage || 0;
  const permissions= localStorage.getItem("permissions");
  const decrptedPermissions= cryptoEncryptionDecryption.Decrypt(permissions)
  const decryptedPermissions = JSON.parse(decrptedPermissions);
  const decryptedPermissions3 =JSON.parse(decryptedPermissions);

  const csvReport=donation?.getCsvReport?.data?.data || [];

 const donationProgressing=donation?.donationList?.progressing



  const handleModel = () => {
    setVeiwOpen(true);
  };
  useEffect(()=>{
    const paylod ={
      pageNo:1,
      pagination_required:"true"
    }
    const paylod2 ={
     
      pagination_required:"false"
    }
    dispatch(getDonationListInProgress(paylod)) 
    dispatch(getCsvInProgress(paylod2));
  },[])
  
 
  const columns = [
    {
      title: `${t('Donation_Id')}`,
      dataIndex: 'donationId',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      title: `${t('Donate_Type')}`,
      dataIndex: 'donateType',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
     
      title: `${t('Donated_by')}`,
      dataIndex: 'donatedBy',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
    
      title: `${t('Donated_For')}`,
      dataIndex: 'donatedFor',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
   
      title: `${t('Payment_Date')}`,
      dataIndex: 'PaymentDate',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
   
      title: `${t('Donated_date')}`,
      dataIndex: 'donatedDate',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      title: `${t('ACTION')}`,
      dataIndex: 'Action',
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      // width: 190,
      // disableColumnMenu: true,
      width: 300,
      render: (_:any, record:any) => (
        <>
          <CustomButton
            text={`${t('EDIT')}`}
            className='mx-3  color-gray'
            disabled={!decryptedPermissions3?.Donate?.edit}
          
            onClick={() => {
              navigate(APP_ROUTES.DONATE_EDIT, {
                state: { text: 'Edit Donation' ,data:record},
              });
            }}
          />
          <CustomButton
            text={`${t('View')}`}
            className='mx-3'
            color={'warning'}
            disabled={!decryptedPermissions3?.Donate?.view}
            onClick={() => {
              handleModel();
              setdonationId(record.id);
              setDonorId(record.Doner_Id)
            }}
            
          />
           <CustomButton
             text={`${t('Print')}`}
            className='mx-3  color-gray'
            disabled={!decryptedPermissions3?.Donate?.view}
           
            onClick={() => {
            navigate(APP_ROUTES.DONATION_PRINT, {state: { button: "true" ,data:record}})
            }}
            
          />
        </>
      ),
    },
  ];
  const handlePageChange = (page:any) => {
    dispatch(getDonationListInProgress({ pageNo: page,pagination_required:"true"}));
  };
  const handleidProofTypeOption= (value: any) => {
    setDonateTypeFilter(value)

  };

  const rows = donorData?.map((donationItem: any) => ({
    
    donationId:donationItem?.donations_serial_no,
    id: donationItem.id,
    recurringDonation:donationItem.recurringDonation,
    donatedFor: donationItem.donatedFor,
    donatedBy: donationItem.Doner_name,
    PaymentDate: convertDateFormat(donationItem.paymentDate),
    donateType:  `${PaymentType[Number(donationItem.donateType)-1]?.Description}`, 
    familyMember:donationItem.familyMember,
    donatedDate: donationItem.donatedDate,
    paymentNow: donationItem.paymentNow,
    amount: donationItem.amount,
    additionalInstructions:donationItem.additionalInstructions,
    paymentMethod: `${PaymentType[Number(donationItem.donateType)-1]?.Description}`,
    donerBank:donationItem.donerBank,
    chequeDate: donationItem.chequeDate,
    bankBranch: donationItem.bankBranch,
    bankName:donationItem.bankName=== '1'?"Indian Bank":donationItem.bankName==="2"?"ICICI Bank":"",
    materialType: donationItem.materialType,
    count: donationItem.count,
    upiID:donationItem.upiID,
    additionalInstructionsMaterial: donationItem.additionalInstructionsMaterial,
    donationTime: donationItem.donationTime ? JSON.stringify(donationItem.donationTime) : "No Donation Time",
    donatedBy_id: donationItem.donatedBy,
    Doner_serial_no: donationItem.Doner_serial_no,
    Doner_address: donationItem.Doner_address,
    Doner_phone: donationItem.Doner_phone,
    additionalInstructionsGeneral:donationItem.additionalInstructionsGeneral,
    chequeNo:donationItem.chequeNo,
    idProofType:donationItem.idProofType,
    idProofNumber:donationItem.idProofNumber,
    Doner_Id:donationItem.Doner_Id,

    others:"others",
        firstName:donationItem?.name|| '',
        mobileNo:donationItem?.mobileNo|| '',
        dob:donationItem?.dob|| '',
        address:donationItem?.address||'',
        email:donationItem?.email||'',
        indianBankChequeAmount:
        donationItem?.indianBankChequeAmount,
      indianBankEcsAmount:  donationItem?.indianBankEcsAmount,
      iciciBankChequeAmount:  donationItem?.iciciBankChequeAmount,
      iciciBankEcsAmount:  donationItem?.iciciBankEcsAmount,


  }));
  const rows2 = csvReport?.map((donationItem: any) => ({

    donationId:donationItem?.donations_serial_no,
    id: donationItem.id,
    recurringDonation:donationItem.recurringDonation,
    donatedFor: donationItem.donatedFor,
    donatedBy: donationItem.Doner_name,
    PaymentDate: convertDateFormat(donationItem.paymentDate),
    donateType: `${PaymentType[Number(donationItem.donateType)-1]?.Description}`, 
    familyMember:donationItem.familyMember,
    donatedDate: donationItem.donatedDate,
    paymentNow: donationItem.paymentNow,
    amount: donationItem.amount,
    additionalInstructions:donationItem.additionalInstructions,
    paymentMethod: `${PaymentType[Number(donationItem.donateType)-1]?.Description}`,
    donerBank:donationItem.donerBank,
    chequeDate: donationItem.chequeDate,
    bankBranch: donationItem.bankBranch,
    bankName:donationItem.bankName=== '1'?"Indian Bank":donationItem.bankName==="2"?"ICICI Bank":"",
    materialType: donationItem.materialType,
    count: donationItem.count,
    upiID:donationItem.upiID,
    additionalInstructionsMaterial: donationItem.additionalInstructionsMaterial,
    donationTime: donationItem.donationTime ? JSON.stringify(donationItem.donationTime) : "No Donation Time",
    donatedBy_id: donationItem.donatedBy,
    Doner_serial_no: donationItem.Doner_serial_no,
    Doner_address: donationItem.Doner_address,
    Doner_phone: donationItem.Doner_phone,
    additionalInstructionsGeneral:donationItem.additionalInstructionsGeneral,
    chequeNo:donationItem.chequeNo,
    idProofType:donationItem.idProofType,
    idProofNumber:donationItem.idProofNumber,
    Doner_Id:donationItem.Doner_Id,
   
    others:"others",
        firstName:donationItem?.name|| '',
        mobileNo:donationItem?.mobileNo|| '',
        dob:donationItem?.dob|| '',
        address:donationItem?.address||'',
        email:donationItem?.email||'',
        indianBankChequeAmount:
        donationItem?.indianBankChequeAmount,
      indianBankEcsAmount:  donationItem?.indianBankEcsAmount,
      iciciBankChequeAmount:  donationItem?.iciciBankChequeAmount,
      iciciBankEcsAmount:  donationItem?.iciciBankEcsAmount,
      idCode:`${idProofs[Number(donationItem.idProofType)-1]?.Description}`,


  }));
  const downloadExcel=()=>{

   

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    
  
    const columnsToIncludeDonations = [
      { header: 'ID Code', key: 'idCode' },
      { header: 'Unique Identification Number', key: 'idProofNumber' },
      { header: 'Name of donor', key: 'donatedBy', },
      { header: 'Address of donor', key: 'Doner_address', },
      { header: 'Donation Type', key: 'others',  },
      { header: 'Mode Of Receipt', key: 'donateType', },
      { header: 'Amount of donation (Indian rupees)', key: 'amount',  },
      { header: 'Bill No', key: 'donationId',  },
      { header: 'Date', key: 'PaymentDate', },
      { header: 'Indian Bank', key: '', },
      // { header: 'Cash', key: 'paymentMethod',  },
      { header: 'Cheque', key: 'indianBankChequeAmount',  },
      { header: 'ECS', key: 'indianBankEcsAmount',  },
      { header: 'ICICI Bank', key: '', },
      // { header: 'Cash', key: 'paymentMethod',  },
      { header: 'Cheque', key: 'iciciBankChequeAmount',  },
      { header: 'ECS', key: 'iciciBankEcsAmount',  },
     
   
    
      
    ];
  
    // Adding column headers and subtitles
    worksheet.columns = columnsToIncludeDonations.map(col => ({
      header: col.header,
      key: col.key,
      headerCount: 1,
      width: 15 
    }));
  
    worksheet.addRow({});
  
    rows2.forEach((row:any, rowIndex:any) => {
      const rowData :any= {};
      columnsToIncludeDonations.forEach(col => {
       
        rowData[col.key] = row[col.key];
      });
      worksheet.addRow(rowData);
   
    });
  
  
  
  
  
  
  
  
  
   
    worksheet.getRow(1).font = { bold: true };
    worksheet.eachRow({ includeEmpty: false }, row => {
      row.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
    worksheet.mergeCells('J1:L1');
    worksheet.mergeCells('M1:O1');
  
    worksheet.getCell('J1').value = 'Indian Bank'; // Set the label for merged cells
    worksheet.getCell('J2').value = 'Cash'; // Label for Cash
    worksheet.getCell('K2').value = 'Cheque'; // Label for Cheque
    worksheet.getCell('L2').value = 'ECS';
    worksheet.getCell('M1').value = 'ICICI BANK'; // Set the label for merged cells
    worksheet.getCell('M2').value = 'Cash'; // Label for Cash
    worksheet.getCell('N2').value = 'Cheque'; // Label for Cheque
    worksheet.getCell('O2').value = 'ECS';  // Label for ECS
    
    
    
  
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'sivaPakkiyam.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    });
   }
  const handleSearchChange = (event:any) => {
    setSearchQuery(event.target.value);
  };
  const handleChange=(date:any)=>{
    setDate( date?.format('YYYY-MM-DD'))  
  }
  const handleChange2=(date:any)=>{
    setToDate( date?.format('YYYY-MM-DD'))  
  }
  const  handleSearch =()=>{
   
     
    const payload = {
      page: 1,
      search_query: searchQuery ,
      from_date:date,
      to_date:toDate,
      donateType: donateTypeFilter,
      pagination_required:"true"
    };
    dispatch(getDonationListInProgress(payload));
    const payload2 = {
      page: 1,
      search_query: searchQuery ,
      from_date:date,
      to_date:toDate,
      donateType: donateTypeFilter,
      pagination_required:"false",
   
    };
    dispatch(getCsvInProgress(payload2));

 }
 const  handleDowload =()=>{
 
   
    downloadExcel()
    



}
 useEffect(() => {
  if (donateTypeFilter !== '') {
  
    const payload = {
      page: 1,
      search_query: searchQuery,
      from_date: date,
      to_date: toDate,
      donateType: donateTypeFilter,
      pagination_required:"true"
    };
    dispatch(getDonationListInProgress(payload));
    const payload2 = {
      page: 1,
      search_query: searchQuery ,
      from_date:date,
      to_date:toDate,
      donateType: donateTypeFilter,
      pagination_required:"false",
   
    };
    dispatch(getCsvInProgress(payload2));
  }
}, [donateTypeFilter])
  return (
 
  <Grid container>
      <Grid xs={12}>
        <DonationHeader />
      </Grid>
  

      <Grid xs={12} my={5}>
        {/* <Table columns={columns} rows={rows}   pagination={{
          totalPages:totalPages,
          total: donationData,
          onChange: handlePageChange
        }}/> */}
            <Grid
            container
            display='flex'
            alignItems={"center"}
            mb={2}
           
            // className='login-form'
          >
        <Grid mr={1} xs={12} sm={12} md={3} lg={2} xl={2} mb={3}>
        <Typography className='from-date-text'>{t('Text_Search')}:</Typography>
      <TextField
          label={`${t('Search')}`}
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          
         
        />    
        </Grid>
    
        <Grid mr={2} xs={12} sm={12} md={3} lg={2} xl={2} mb={3}>
          <Typography className='from-date-text'>{t('From_Date')}:</Typography>
       <DatePicker placeholder={t('Select_Date')} style={{height:"55px",width:"200px"}} onChange={handleChange}/></Grid>
      <Grid xs={12} sm={12} md={3} lg={2.5} xl={2} mb={3}> 
      <Typography className='from-date-text'>{t('To_Date')}:</Typography>
        <DatePicker  placeholder={t('Select_Date')} style={{height:"55px",width:"200px"}} onChange={handleChange2}/></Grid>
  
       <Grid xs={12} sm={12} md={2} lg={2} xl={2} mb={2}> <CustomButton text={t('Search')} className='px-5 py-3 mx-3'  color={'warning'} onClick={handleSearch}/></Grid>
        </Grid>
        <Grid xs={8.5} sm={3.5} md={2} lg={2} xl={1.3} mb={2}>
        <Typography className='from-date-text'>{t('Search_Filter')}:</Typography>
        <CustomSelectBox
              options={DonateTypePayload}
              ClassName='form-select'
              placeholder={'Choose Option To filter'}
              name={'idProofType'}
                error={""}
              setSelectedOption={handleidProofTypeOption}
    
            />
            </Grid>
       { donationProgressing? 
  <Grid className='main-spinner'>
  <Spin size="large"  /> </Grid>: 
      <TableComponent
        columns={columns}
        dataSource={rows}
        tableData={donorData}
        csvData={rows2}
        pagination={{
          total: donationData,
          pageSize: 10, 
          current: currentPage, //
          onChange: handlePageChange
        }}
        // bordered={bordered}
        type="donate"
        handleDowload={handleDowload}
        view={decryptedPermissions3?.Donate?.view}
        // onChange={onChange}
        // ref={tableRef}
      />}
      </Grid>

      <ViewModel open={veiwOpen} setOpen={setVeiwOpen} donationId={donationId} donorId={donorId} />
    </Grid>
   
  )
  
}

export default DonationList;
