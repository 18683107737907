import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
GET_DONATION_LIST_IN_PROGRESS,
UPDATE_DONATION_IN_PROGRESS,
ADD_DONATION_IN_PROGRESS,
GET_FAMILY_IN_PROGRESS,
GET_DONATION_IN_PROGRESS,
DONATION_BY_DATE_IN_PROGRESS,
TODAY_DONATION_IN_PROGRESS,
DONATION_REPORT_IN_PROGRESS,
GET_RECENT_DONATIONS_IN_PROGRESS,
GET_CSV_IN_PROGRESS,
GET_REMAINDER_DONATION_IN_PROGRESS


} from "../../constants/ActionType";
import { getErrorMessageFromAPI } from "../../../utils/utilz";
import { message } from "antd";
import {

getDonationListInSuccess,
getDonationListInError,
updateDonationListSuccess,
updateDonationInError,
addDonationListSuccess,
addDonationInError,
getFamilyListSuccess,
getFamilyInError,
getDonationSuccess,
getDonationInError,
getDonationByDateSuccess,
getDonationByDateInError,
getTodayDonationSuccess,
getDonationReportSuccess,
getDonationReportInError,
getRecentDonationSuccess,
getRecentDonationInError,
getCsvSuccess,
getCsvInError,
getRemainderDonationInSuccess,
getRemainderDonationInError

} from "../Donations/actions/donationAction";
import {
  getdonationInstanceListEffect,
  updateDonationInstanceListEffect,
  addDonationInstanceListEffect,
  getFamilyInstanceListEffect,
  getDonationInstanceEffect,
  DonationByDateInstanceEffect,
  todayDonationInstanceEffect,
  donationReportInstanceEffect,
  getRecentInstanceListEffect,
  getCvsInstanceListEffect,
  getRemainderDonationList

} from "../Donations/saga/effects/donationEffect";
import Message from "../../../components/Message/Message";

function* getDonationList({ payload }:any) {
  try {
    const { data } = yield call(getdonationInstanceListEffect, payload);

    yield put(
        getDonationListInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    yield put(
        getDonationListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
   
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    message.error(getErrorMessageFromAPI(e));
  }
}
function* updateDonation({ payload }:any) {
  try {

    let { data } = yield call(updateDonationInstanceListEffect, payload.data);

    yield put(
      updateDonationListSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSucces
      ){
      payload.handleSucces()
    }
   
  } catch (e:any) {
    yield put(
      updateDonationInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
  }
}
function* addDonation({ payload }:any) {
  try {

    let { data } = yield call(addDonationInstanceListEffect, payload.data);

    yield put(
      addDonationListSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSucces
      ){
      payload.handleSucces()
    }
   
  } catch (e:any) {
    yield put(
      addDonationInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
  }
}
function* getFamilyList({ payload }:any) {
  try {
    const { data } = yield call(getFamilyInstanceListEffect, payload);

    yield put(
        getFamilyListSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    // yield put(
    //     getFamilyInError({
    //     message: getErrorMessageFromAPI(e),
    //     error: true,
    //   })
    // );
    // message.error(getErrorMessageFromAPI(e));
  }
}
function* getDonation({ payload }:any) {
  try {
    const { data } = yield call(getDonationInstanceEffect, payload);

    yield put(
        getDonationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    // yield put(
    //     getDonationInError({
    //     message: getErrorMessageFromAPI(e),
    //     error: true,
    //   })
    // );
    // message.error(getErrorMessageFromAPI(e));
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
  }
}
function* getDonationByDate({ payload }:any) {
  try {
    
    const { data } = yield call(DonationByDateInstanceEffect, payload);

    yield put(
        getDonationByDateSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    yield put(
        getDonationByDateInError({
        // message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    // message.error(getErrorMessageFromAPI(e));
  }
}
function* getTodayDonation({ payload }:any) {
  try {
  
    const { data } = yield call(todayDonationInstanceEffect, payload);

    yield put(
        getTodayDonationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    // yield put(
    //     getDonationByDateInError({
    //     message: getErrorMessageFromAPI(e),
    //     error: true,
    //   })
    // );
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getDonationInProgress({ payload }:any) {
  try {
  
    const { data } = yield call(donationReportInstanceEffect, payload);

    yield put(
        getDonationReportSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    yield put(
        getDonationReportInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getRecentDonationInProgress({ payload }:any) {
  try {
 
    const { data } = yield call(getRecentInstanceListEffect, payload);

    yield put(
        getRecentDonationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    yield put(
        getRecentDonationInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getCsvList({ payload }:any) {
  try {
    const { data } = yield call(getCvsInstanceListEffect, payload);

    yield put(
        getCsvSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );

    if(payload.handleSuccess){

      setTimeout(payload.handleSuccess(), 5000);
     
    }
  } catch (e:any) {
    yield put(
        getCsvInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getRemainderDonation({ payload }:any) {
  try {
    const { data } = yield call(getRemainderDonationList, payload);

    yield put(
        getRemainderDonationInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );

    
  } catch (e:any) {
    yield put(
        getRemainderDonationInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    if(e?.response?.data?.expired==="Y"){
      localStorage.removeItem("permissions");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      localStorage.removeItem("email");
      localStorage.removeItem('user');
      window.location.reload();
    }
    message.error(getErrorMessageFromAPI(e));
  }
}

export default function* donationSaga() {
    yield takeLatest(GET_DONATION_LIST_IN_PROGRESS, getDonationList);
    yield takeLatest(UPDATE_DONATION_IN_PROGRESS, updateDonation);
    yield takeLatest(ADD_DONATION_IN_PROGRESS, addDonation);
    yield takeLatest(GET_FAMILY_IN_PROGRESS, getFamilyList);
    yield takeLatest(GET_DONATION_IN_PROGRESS, getDonation);
    yield takeLatest(DONATION_BY_DATE_IN_PROGRESS, getDonationByDate);
    yield takeLatest(TODAY_DONATION_IN_PROGRESS, getTodayDonation);
    yield takeLatest(DONATION_REPORT_IN_PROGRESS, getDonationInProgress);
    yield takeLatest(GET_RECENT_DONATIONS_IN_PROGRESS, getRecentDonationInProgress);
    yield takeLatest(GET_CSV_IN_PROGRESS, getCsvList);
    yield takeLatest(GET_REMAINDER_DONATION_IN_PROGRESS, getRemainderDonation);

  }
