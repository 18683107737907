import React, { useEffect, useState } from 'react';
import CommonModal from './CommonModal';
import CustomButton from '../CustomButton';
import { Grid, Typography } from '@mui/material';
import LabelWithIcon from '../LabelWithIcon';
import FormInput from '../Forms/FormInput/FormInput';
import { useDispatch } from 'react-redux';
import { addCsvInProgress, getDonerListInProgress } from '../../redux/doner/action/donerAction';
import { message } from 'antd';

  const UploadModal = ({ open,setOpen}: { open: any,setOpen:any }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const dispatch=useDispatch()
  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
    
      if (file) {
        setSelectedFile(file);
      }
    };
 
    const handleUpload = () => {
        if (selectedFile) {
          const formData = new FormData();
          formData.append('file', selectedFile); 
          const handleSuccess=()=>{
            setOpen(false)
            const paylod ={
              pageNo:1,
              pagination:true
            }
            dispatch(getDonerListInProgress(paylod))
          }
          const payload={
            formData,
            handleSuccess
          }
          dispatch(addCsvInProgress(payload));
        } else {
          message.error("Please Select the file")
        }
      };
      
     const handleClose =()=>{
      setOpen(false)
      setSelectedFile(null)
     } 
      
  
    return (
      <CommonModal
        onClose={() => setOpen(false)}
        open={open}
        modalClassName='px-20 py-20'
        // boxProps={{ width: 300, height: 150 }}
      >
        <Grid>
          <Typography textAlign={'center'} >
            Upload Your File Here
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={'File Upload'}
              justifyContent='flex-start'
              className='mb-6 ml-3'
            />
            <input type="file" accept=".csv" onChange={handleFileChange} />
          </Grid>
        </Grid>
        <Grid container display={"flex"} justifyContent={"space-between"}>
          <CustomButton text={'Upload'} className='px-5 py-3 brown-color' onClick={handleUpload} />
          <CustomButton text={'Close'} className='px-5 py-3'color='warning' onClick={handleClose} />
        </Grid>
      </CommonModal>
    );
  };
  
  export default UploadModal;