/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { useTheme } from '@mui/system';

import AuthRoutes from '../Layout/AuthRoutes';
import AppRoutes from '../Layout/AppRoutes';
import { changeCssColors } from '../common/theme';
import './colors.css';
import './App.scss';

const App = () => {
  const theme = useTheme();

  useEffect(() => {
    changeCssColors(theme);
  }, [theme]);

  const isLogged = useMemo(
    () => !!localStorage.getItem('user'),
    [localStorage.getItem('user')]
  );
  console.log('isLogged', isLogged);
  return (
    <>
      <Router>
        {isLogged ? (
          <AppRoutes isLogged={isLogged} />
        ) : (
          <AuthRoutes isLogged={isLogged} />
        )}
      </Router>
    </>
  );
};

export default App;
