import {all} from 'redux-saga/effects';
import AuthSaga from '../auth/authSaga';
import donerSaga from "../doner/donerSaga";
import donationSaga from "../Donations/donationSaga";
import teamMemberSaga from "../teamMembers/teamMemberSaga";
import rolesSaga from "../roles/rolesSaga";
import userSaga from "../user/userSaga";



function* rootSaga() {

  yield all([AuthSaga(),donerSaga(),donationSaga(),teamMemberSaga(),rolesSaga(),userSaga()]);
 
  
}
export default rootSaga;