import { Button, Col, Grid, Row, Spin, Table, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  LeftOutlined,
  PlusCircleFilled,
  PrinterFilled,
  RightOutlined,
} from '@ant-design/icons';

import { getDonationListInProgress } from './redux/Donations/actions/donationAction';
import { useRef } from 'react';
import { APP_ROUTES } from './constants/Routes';
import ButtonComponent from './ButtonComponent';
import image from '../../src/common/icons/image3 1.png';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Rupeee from '../../src/common/icons/75.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { MobileOffOutlined, PhoneOutlined } from '@mui/icons-material';
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded';
import html2canvas from 'html2canvas';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { idProofs, paymentMethod } from './constants/dropDownList';
import { convertAmountToWords, convertDateFormat } from '../utils/utilz';

const DonationPrint = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const invoiceData = location.state?.record;
  const navigateButton = location.state?.button;

  const donationData = location.state?.data;
  const pdfContentRef = useRef(null);
  const donation_date = new Date().toISOString().split('T')[0];

  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate(-1);
  };

  const columns = [
    {
      title: 'donationId',
      dataIndex: 'donationId',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      title: 'Donate Type',
      dataIndex: 'donateType',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      title: 'Donated By',
      dataIndex: 'donatedBy',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      title: 'Donation For',
      dataIndex: 'donatedFor',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      title: 'Recurring Donation',
      dataIndex: 'recurringDonation',
      render: (recurringDonation: any) => (recurringDonation ? 'Yes' : 'No'),
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      title: 'PaymentDate',
      dataIndex: 'PaymentDate',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    if (navigateButton === 'false') {
      handlePrint();
    }
  }, []);
  const { donation } = useSelector((state: any) => state);

  const handleDownloadPDF = async () => {
    const content: any = pdfContentRef.current;

    const options = {
      paperSize: 'Letter',
      scale: 1,
      margin: { left: '1cm', right: '1cm' }, // Adjust margins as needed
      landscape: true,
      // Set a fixed DPI for screen resolution, e.g., 96 DPI
      pdf: {
        autoTable: { useCss: true },
        avoidLinks: true,
        format: 'Letter',
        scale: 1,
      },
    };

    const group = await drawDOM(content, options);
    exportPDF(group).then((data) => {
      saveAs(data, 'download.pdf');
    });
  };

  return (
    <Spin spinning={donation?.donationList?.inProgress === true}>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {navigateButton === 'true' && (
            <>
              <Row justify={'space-between'} className='hide-on-print'>
                <ButtonComponent
                  type='primary'
                  label='Go Back'
                  icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />}
                  onClick={goBackHandler}
                  style={{ backgroundColor: '#ef7025', borderColor: '#ef7025' }}
                />
                <Row>
                  <ButtonComponent
                    type='primary'
                    label='Print'
                    icon={<PrinterFilled style={{ fontSize: '20px' }} />}
                    onClick={handlePrint}
                    className={'mr-20'}
                    style={{
                      backgroundColor: '#525252',
                      borderColor: '#525252',
                    }}
                  />
                  <ButtonComponent
                    type='primary'
                    label='Download'
                    className='blue-color'
                    onClick={handleDownloadPDF}
                    icon={<ArrowDownOutlined style={{ fontSize: '20px' }} />}
                    style={{
                      backgroundColor: '#525252',
                      borderColor: '#525252',
                    }}
                  />
                </Row>
                <Link to={APP_ROUTES.DONATE_ADD}>
                  <ButtonComponent
                    type='primary'
                    label='Add Donation'
                    className='blue-color'
                    icon={<PlusCircleFilled style={{ fontSize: '16px' }} />}
                    style={{
                      backgroundColor: '#ef7025',
                      borderColor: '#ef7025',
                    }}
                  />
                </Link>
              </Row>
            </>
          )}
          <Row ref={pdfContentRef} className='invoice-content'>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row className='invoice-content-row'>
                <Col sm={5} className='logo-col'>
                  <img src={image} alt='logo' className='img-print-logo' />
                </Col>
                <Col sm={14}>
                  <Typography className='invoice-heading'>
                    SIVABAKKIAM REHABILITATION <br />
                    CENTER FOR DISABLED PERSON
                  </Typography>
                </Col>
                <Col sm={5}>
                  <Typography className='invoice-heading-number'>
                    <PhoneOutlined className='phone-icon' />: +91 04286-268009,
                    <br />
                    <PhoneIphoneRoundedIcon className='phone-icon' />
                    :94882 00300
                  </Typography>
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Typography className='invoice-heading-address'>
                    (Registered as Indian Trust Act 1882 with Regn.No:113/2003){' '}
                    <br />
                    Regd. office: 2/89, Ayakkattupalayam,Manathi
                    Post,Tiruchengode Taluk, Namakkal District, TN-637212 <br />
                    E-mail: sivabakkiamrcd@gmail.com, Website:
                    www.sivabakkiamorphanage.org <br />
                    Donations are Exempted u/s 80G of the Income Tax Act 1961,
                    PAN No: AAETS4471P
                  </Typography>
                </Col>
              </Row>

              <Row className='invoice-content-body'>
                <div className='receipt-text-box'>
                  <Typography className=''>RECEIPT</Typography>
                </div>
                <Col sm={24} className='logo-col'>
                  <Row className='receipt-no-row'>
                    <Col>
                      <Typography className='invoice-heading-number'>
                        No:{donationData.donationId}
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className='invoice-heading-number'>
                        Date:{convertDateFormat(donation_date)}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col sm={24} className=''>
                  <Row className='received-with-col'>
                    <Col>
                      <Typography className='invoice-received-with'>
                        Received With thanks from:
                        <span className='invoice-received-with-adrress'>
                          {donationData?.firstName
                            ? donationData.firstName
                            : donationData?.donatedBy}
                        </span>{' '}
                        <span className='invoice-received-with-adrress'>
                          {donationData?.address
                            ? donationData.address
                            : donationData.Doner_address}
                        </span>
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col sm={24} className=''>
                  <Row className='received-with-col'>
                    <Col>
                      <Typography className='invoice-received-with'>
                        Mobile No:
                        <span className='invoice-received-with-adrress'>
                          {donationData.Doner_phone
                            ? donationData?.Doner_phone
                            : donationData?.mobileNo}
                        </span>
                      </Typography>
                    </Col>
                  </Row>
                  <Row className='received-with-col'>
                    <Col>
                      <Typography className='invoice-received-with'>
                        {`${
                          idProofs[Number(donationData.idProofType) - 1]
                            ?.Description
                        }`}
                        :
                        <span className='invoice-received-with-adrress'>
                          {donationData.idProofNumber}
                        </span>
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col sm={24} className=''>
                  <Row className='received-with-col'>
                    <Col>
                      <Typography className='invoice-received-with'>
                        sum of rupees:
                        <span className='invoice-received-with-adrress'>
                          {donationData?.amount &&
                            convertAmountToWords(
                              donationData?.amount
                            ).toUpperCase()}
                        </span>
                      </Typography>
                    </Col>
                  </Row>
                  <Row className='received-with-col'>
                    <Col>
                      <Typography className='invoice-received-with'>
                        towards donation by {`${donationData.paymentMethod}`}:
                        <span className='invoice-received-with-adrress'>
                          {donationData.paymentMethod === 'Cash'
                            ? donationData.amount
                            : donationData.paymentMethod === 'Cheque'
                            ? donationData.chequeNo
                            : donationData.paymentMethod === 'Online'
                            ? donationData.upiID
                            : ''}
                        </span>
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col sm={24} className='logo-col'>
                  <Row className='receipt-no-row'>
                    <Col>
                      <Typography className='invoice-heading-number'>
                        Date:
                        <span className='invoice-received-with-adrress'>
                          {convertDateFormat(donation_date)}
                        </span>
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className='invoice-heading-number'>
                        Bank:
                        <span className='invoice-received-with-adrress'>
                          {donationData?.donerBank
                            ? donationData?.donerBank
                            : donationData?.bankName}
                        </span>
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className='invoice-heading-number'>
                        Branch:
                        <span className='invoice-received-with-adrress'>
                          {donationData.bankBranch}
                        </span>
                      </Typography>
                    </Col>
                    <Col>
                      <Typography className='invoice-heading-number'>
                        UPI No:
                        <span className='invoice-received-with-adrress'>
                          {donationData.upiID}
                        </span>
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col sm={24} className='logo-col last'>
                  <Row className='receipt-no-row'>
                    <Col sm={8}>
                      <div className='cash-text-box'>
                        <div className='cash-text-box-sub'>
                          <div>
                            <img src={Rupeee} className='rupee-icon'></img>
                          </div>
                          <div className='cash-text-box-sub-2'>
                            <Typography className='amount-text'>
                              {donationData.amount}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={8}>
                      <Typography className='invoice-PayerSignature'>
                        PayerSignature
                        <span className='invoice-received-with-adrress'></span>
                      </Typography>
                    </Col>
                    <Col sm={8}>
                      <Typography className='invoice-heading-number'>
                        For:
                        <span className='invoice-received-for-text'>
                          {' '}
                          Sivabakkiam Rehabilitation Centre for Disabled Person
                        </span>
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default DonationPrint;
