import {
UPDATE_USER_IN_PROGRESS,
UPDATE_DONATION_IN_SUCCESS,
UPDATE_TEAM_MEMBER_IN_ERROR,
UPDATE_USER_IN_SUCCESS,
UPDATE_USER_IN_ERROR,
UPDATE_PASSWORD_IN_PROGRESS,
UPDATE_PASSWORD_IN_SUCCESS,
UPDATE_PASSWORD_IN_ERROR,
GET_PROFILE_IN_PROGRESS,
GET_PROFILE_IN_SUCCESS,
GET_PROFILE_IN_ERROR
       } from "../../../constants/ActionType";
       
       const defaultStatus = {
         progressing: false,
         success: false,
         error: false,
         data: [],
       };
       const initialState = {
       
         updateUsers:{...defaultStatus},
         updatePassword:{...defaultStatus},
         getProfile:{...defaultStatus}
     
        
       };
       const rolesReducer = (state = initialState, action:any) => {
         const { type, payload } = action;
         switch (type) {
        
        
        //UPDATE USERS
             case UPDATE_USER_IN_PROGRESS:
               return {
                 ...state,
                  updateUsers: { ...defaultStatus, ...payload, inProgress: true },
               };
             case UPDATE_USER_IN_SUCCESS:
               return {
                 ...state,
                  updateUsers: { ...defaultStatus, ...payload, success: true },
               };
             case UPDATE_USER_IN_ERROR:
               return {
                 ...state,
                  updateUsers: { ...defaultStatus, ...payload, error: true },
               };
               //UPDATE PASSWORD
             case UPDATE_PASSWORD_IN_PROGRESS:
              return {
                ...state,
                updatePassword: { ...defaultStatus, ...payload, inProgress: true },
              };
            case UPDATE_PASSWORD_IN_SUCCESS:
              return {
                ...state,
                updatePassword: { ...defaultStatus, ...payload, success: true },
              };
            case UPDATE_PASSWORD_IN_ERROR:
              return {
                ...state,
                updatePassword: { ...defaultStatus, ...payload, error: true },
              };
                 //GET PROFILE 
             case GET_PROFILE_IN_PROGRESS:
              return {
                ...state,
               getProfile: { ...defaultStatus, ...payload, inProgress: true },
              };
            case GET_PROFILE_IN_SUCCESS:
              return {
                ...state,
               getProfile: { ...defaultStatus, ...payload, success: true },
              };
            case GET_PROFILE_IN_ERROR:
              return {
                ...state,
               getProfile: { ...defaultStatus, ...payload, error: true },
              };
               
            default:
             return state;
         }
       };
       
       export default rolesReducer;
       