import { Button } from "antd";
import React, { ReactNode } from "react";

interface ButtonComponentProps {
  label: any;
  type?: any;
  className?: any;
  onClick?: () => any;
  size?: any;
  icon?: any;
  htmlType?: any;
  loading?: boolean;
  shape?: any;
  style?: any;
  disabled?: boolean;
  rightIcon?: any;
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  label,
  type,
  className,
  onClick,
  size,
  icon,
  htmlType,
  loading,
  shape,
  style,
  disabled,
  rightIcon,
}) => {
  return (
    <Button
      type={type}
      className={className}
      icon={icon}
      onClick={onClick}
      size={size}
      htmlType={htmlType}
      shape={shape}
      style={style}
      loading={loading}
      disabled={disabled}
    >
      {label}
      {rightIcon}
    </Button>
  );
};

export default ButtonComponent;
