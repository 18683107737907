import { Grid, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../../common/ui/CustomButton';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';
function VolunteerHeader() {
  const navigate = useNavigate();
  const handleNavigate = () => {
   
    navigate(APP_ROUTES.DASHBOARD);
  };
  return (
    <Grid
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className='donation-header'
    >
      <Typography fontSize={20} fontWeight={700}>
        Manage Volunteer
      </Typography>

      <CustomButton
        type='button'
        text='ADD Volunteer'
        startIcon={AddIcon}
        className=' px-10 py-5 my-20'
        fontSize={14}
        onClick={() => {
          navigate(APP_ROUTES.VOLUNTEERFORM);
        }}
        color={'warning'}
      />
    </Grid>
  );
}

export default VolunteerHeader;
