import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import EditProfile from './EditProfile';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getProfileInProgress } from '../../../common/redux/user/action/userAction';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ProfileScreen = () => {
  const dispatch=useDispatch()
  useEffect(()=>{
    const paylod={
      page:1
    }
    dispatch(getProfileInProgress(paylod))
  },[])
const {t}=useTranslation()
  const  {user}= useSelector((state:any) => state);
  const userData = user?.getProfile
  ?.data?.data || [];   
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,

    reset,
  } = useForm({
    defaultValues: {
      firstName:userData?.firstName?userData?.firstName:"",
      lastName:userData?.lastName||"",
      email:userData?.email||"",
      phone:userData?.phone||"",
      gender_type:userData?.gender_type||"",
      dob:userData?.dob||"",
      blood_group_id:userData?.blood_group_id||"",
      address:userData?.address||""



    },
  });
 

  return (
    <div>
      <Grid>
        <EditProfile
          handleSubmit={handleSubmit}
          text={`${t('MY_PROFILE')}`}
          register={register}
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          watch={watch}
        />
      </Grid>
    </div>
  );
};

export default ProfileScreen;
