import {
GET_TEAM_MEMBER_IN_PROGRESS,
GET_TEAM_MEMBER_IN_SUCCESS,
GET_TEAM_MEMBER_IN_ERROR,
ADD_TEAM_MEMBER_IN_PROGRESS,
ADD_TEAM_MEMBER_IN_SUCCESS,
ADD_TEAM_MEMBER_IN_ERROR,
GET_ROLES_IN_PROGRESS,

GET_ROLES_IN_SUCCESS,
GET_ROLES_IN_ERROR,
UPDATE_TEAM_MEMBER_IN_PROGRESS,
UPDATE_DONATION_IN_SUCCESS,
UPDATE_DONATION_IN_ERROR,
DELETE_TEAM_MEMBER_IN_PROGRESS,
DELETE_DONER_IN_SUCCESS,
DELETE_TEAM_MEMBER_IN_ERROR
  
         
           } from "../../../constants/ActionType";
          
          // Get All TeamMember List
          export const getTeamMemberListInProgress = (payload:any) => ({
            type: GET_TEAM_MEMBER_IN_PROGRESS,
            payload: payload,
          });
          
          export const getTeamMemberListInSuccess = (payload:any) => ({
            type: GET_TEAM_MEMBER_IN_SUCCESS,
            payload: payload,
          });
          
          export const getTeamMemberListInError = (payload:any) => ({
            type: GET_TEAM_MEMBER_IN_ERROR,
            payload: payload,
          });
          // Add Team Member
           
export const addTeamMemberInProgress = (payload:any) => ({
  type: ADD_TEAM_MEMBER_IN_PROGRESS,
  payload: payload,
});

export const addTeamMemberSuccess = (payload:any) => ({
  type: ADD_TEAM_MEMBER_IN_SUCCESS,
  payload: payload,
});

export const addTeamMemberInError = (payload:any) => ({
  type: ADD_TEAM_MEMBER_IN_ERROR,
  payload: payload,
});
 // GET ROLES
           
 export const getTeamMemberRolesListInProgress = (payload:any) => ({
  type: GET_ROLES_IN_PROGRESS,
  payload: payload,
});

export const getRolesListInSuccess = (payload:any) => ({
  type: GET_ROLES_IN_SUCCESS,
  payload: payload,
});

export const getRolesListInError = (payload:any) => ({
  type: GET_ROLES_IN_ERROR,
  payload: payload,
});

          // update Team Member
           
          export const updateTeamMemberInProgress = (payload:any) => ({
            type: UPDATE_TEAM_MEMBER_IN_PROGRESS,
            payload: payload,
          });
          
          export const updateTeamMemberSuccess = (payload:any) => ({
            type: UPDATE_DONATION_IN_SUCCESS,
            payload: payload,
          });
          
          export const updateTeamMemberInError = (payload:any) => ({
            type: UPDATE_DONATION_IN_ERROR,
            payload: payload,
          });
          //delete teamMember
          export const deleteTeamMemberInProgress = (payload:any) => ({
            type: DELETE_TEAM_MEMBER_IN_PROGRESS,
            payload: payload,
          });
          
          export const deleteTeamMemberSuccess = (payload:any) => ({
            type: DELETE_DONER_IN_SUCCESS,
            payload: payload,
          });
          
          export const deleteTeamMemberError = (payload:any) => ({
            type: DELETE_TEAM_MEMBER_IN_ERROR,
            payload: payload,
          });