import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Col, DatePicker, Row, Table } from "antd";
import jsPDF from "jspdf";
import XLSX from 'xlsx';
import ReactDOM from "react-dom";

import CustomButton from './ui/CustomButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { exportToCsv  } from './utils';
import { createObjectCsvWriter } from 'csv-writer';
import "jspdf-autotable";
import PrintTable from './PrintTable';
import UploadModal from './ui/Modal/UploadModal';
import { getDonationReportInProgress, getRecentDonationInProgress } from './redux/Donations/actions/donationAction';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ExcelJS from 'exceljs'; 
const TableComponent = ({
  dataSource,
  tableData,
  columns,
  pagination,
  type,
  view,
  add,
  handleDowload,
  csvData

}: {
  dataSource: any;
  columns: any;
  hideFooterPagination?: any;
  className?: any;
  pagination?:any;
  type?:any;
  view?:any;
  add?:any;
  tableData?:any;
  handleDowload?:any;
  csvData?:any

}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [date, setDate] = useState<moment.Moment | null | undefined>();


  const tableRef = useRef<any>(); 

  const dispatch =useDispatch()
  const handlePdf = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc :any= new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Details";
    const defaultColumnWidth = 60;
    let columnsToRemove = ["createdAt", "updatedAt", "familyMember","donationTime","permissions",
    "Doner_Id","donatedBy_id","additionalInstructions","additionalInstructionsMaterial","additionalInstructionsGeneral",
    "recurringDonation","donerBank","Doner_serial_no","Doner_address","paymentNow","user_id","role_id","password"];
    const cleanedDataSource = dataSource.map((obj:any) => {
      Object.values(obj).forEach((key) => {
        //  return key;
      });
      Object.keys(obj).forEach((key) => {
        if (columnsToRemove.includes(key) || key.toLowerCase().startsWith("id")) {
          delete obj[key];
        }
      });
      return obj;
    });
    const dataSourceKeys = Object.keys(cleanedDataSource[0]);
    const headers = [dataSourceKeys.map((key) => key)];
    const dataValues = cleanedDataSource.map((row:any) => {
      const rowData:any = [];
      headers[0].forEach((header) => {
        rowData.push(row[header]);
      });
      return rowData;
    });
    const totalColumns = headers.length;
    const columnStyles :any= {};
    for (let i = 0; i < totalColumns; i++) {
      columnStyles[i] = { columnWidth: defaultColumnWidth };
    }
    const content :any= {
      startY: 50,
      head: headers,
      body: dataValues,
      columnStyles: columnStyles,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("sivaPakkiyam.pdf");
  };
  const [uploadOpen,setUploadOpen]=useState(false)
  const orgName="Sivabakkiyam"
  const generateCsvContent = (data:any, columns:any) => {
    // Extracting CSV headers
    const csvHeaders = columns.map((column :any)=> column.title).join(',');
  
    // Extracting CSV data
    const csvData = data.map((row :any)=> (
      columns.map((column:any) => row[column.dataIndex]).join(',')
    )).join('\n');
  
    // Creating CSV content
    return `${csvHeaders}\n${csvData}`;
  };
  
  const handleCsv = () => {
if(type==="donate"){
  handleDowload()
}
else{
  const columnsToIncludeDonations = [
    { title: 'ID Code', dataIndex: 'idProofType' },
    { title: 'Unique Identification Number', dataIndex: 'idProofNumber' },
    { title: 'Name of donor', dataIndex: 'donatedBy' },
    { title: 'Address of donor', dataIndex: 'Doner_address' },
    { title: 'Donation Type', dataIndex: 'others' },
    { title: 'Mode Of Receipt', dataIndex: 'donateType' },

    { title: 'Amount of donation (Indian rupees)', dataIndex: 'amount' },
    { title: 'Bill No', dataIndex: 'donationId' },
    { title: 'Date', dataIndex: 'PaymentDate' ,subtitle: 'Subtitle9'},
   
 
   
  ];
  const columnsToIncludeDonors = [

    { title: 'donorId', dataIndex: 'donorId' },
    { title: 'firstName', dataIndex: 'firstName' },
    { title: 'lastName', dataIndex: 'lastName' },
    { title: 'address', dataIndex: 'address' },
    { title: 'dob', dataIndex: 'age' },
    { title: 'email_id', dataIndex: 'email_id' },
    { title: 'gender_type', dataIndex: 'gender_type' },
    { title: 'idProofNumber', dataIndex: 'idProofNumber' },
    { title: 'phone', dataIndex: 'phone' }
 
  ];
  const columnsToIncludeTeamMembers = [

    { title: 'memberId', dataIndex: 'memberId' },
    { title: 'firstName', dataIndex: 'firstName' },
    { title: 'lastName', dataIndex: 'lastName' },
    { title: 'dob', dataIndex: 'dob' },
    { title: 'age', dataIndex: 'age' },
    { title: 'email', dataIndex: 'email' },
    { title: 'gender_type', dataIndex: 'gender_type' },
   
    { title: 'phone', dataIndex: 'phone' },
    { title: 'Role', dataIndex: 'Role' }
 
  ];
  const columnsToIncludeRoles = [
    { title: 'roles serial no', dataIndex: 'roleId' },
    { title: 'name', dataIndex: 'role' },
    { title: 'status', dataIndex: 'status' },
  ];
  const columnsToIncludeReports= [

    { title: 'amount', dataIndex: 'amount' },
    { title: 'date', dataIndex: 'date' },
    { title: 'material count', dataIndex: 'material' },
    { title: 'material name', dataIndex: 'materialName' },
    
 
  ];

if(type==="donate"){
  const csvContent = generateCsvContent(dataSource, columnsToIncludeDonations);
  exportToCsv(csvContent, 'sivaPakkiyam.csv');
}
if(type==="donor"){
  const csvContent = generateCsvContent(dataSource, columnsToIncludeDonors);
  exportToCsv(csvContent, 'sivaPakkiyam.csv');
}
if(type==="teamMember"){
  const csvContent = generateCsvContent(dataSource, columnsToIncludeTeamMembers);
  exportToCsv(csvContent, 'sivaPakkiyam.csv');
}
if(type==="role"){
  const csvContent = generateCsvContent(dataSource, columnsToIncludeRoles);
  exportToCsv(csvContent, 'sivaPakkiyam.csv');
}
if(type==="recentdonation"){
  const csvContent = generateCsvContent(dataSource, columnsToIncludeDonations);
  exportToCsv(csvContent, 'sivaPakkiyam.csv');
}
if(type==="report"){
  const csvContent = generateCsvContent(dataSource, columnsToIncludeReports);
  exportToCsv(csvContent, 'sivaPakkiyam.csv');
}
}
   
   
  };


  
  
  const handlePrint = () => {
    const selectedColumn = columns
      .filter(
        (column :any) => column.title !== "Options" && column.title !== "Action"
      )
      .map((column:any) => ({
        title: column.title,
        dataIndex: column.dataIndex,
        key: column.key,
      }));

    const newWindow = window.open("", "_blank");
    if (newWindow) {
      newWindow.document.write("<html><head><title>Print</title>");
      newWindow.document.write(
        "<link rel='stylesheet'  href='../../styles/modules/doctor.less'>"
      );
      newWindow.document.write("</head><body>");
      newWindow.document.write("<div id='print-content'>");
      ReactDOM.render(
        <PrintTable
          dataSource={dataSource}
          columns={selectedColumn}
          orgName={orgName}
        />,
        newWindow.document.getElementById("print-content")
      );

      newWindow.document.write("</div>");
      newWindow.document.write("</body></html>");
      newWindow.document.close();
      newWindow.onload = () => {
        newWindow.print();
      };
    } else {
      console.error("Failed to open new window for printing.");
    }
  };


  const handleUpload = () => {
    setUploadOpen(true);
  };
  
  
  
  

  return (
    <>
   <>    
   {type!=="dashboard"&&(  
   
   <Grid
   xs={12}
   display={'flex'}
   justifyContent={'space-between'}
   alignItems={'center'}
  pb={5}
 >
   <Grid xs={12} justifyContent={'center'} display={'flex'}>
     <CustomButton text={'PRINT'}  onClick={()=>{
        handlePrint()
       }}disabled={!view} className='px-5 py-3 mx-3 color-gray' />
     <CustomButton
       text={'PDF'}
       className='px-5 py-3 mx-3'
       startIcon={PictureAsPdfIcon}
       color={'warning'}
       onClick={()=>{
        handlePdf()
       }}
       disabled={!view}
     />
     <CustomButton text={'CSV'} onClick={()=>{
       handleCsv()
       }} 
       disabled={!view} className='px-5 py-3 mx-3 color-gray' />
       {type==="donor"&&
       (
       <CustomButton text={'UPLOAD'} className='px-5 py-3 mx-3' disabled={!add}  color={'warning'} onClick={handleUpload}/>
       )}
         
   </Grid>
 </Grid>)}
 </>

 {/* {type==="report"&&(
  
  <Grid display={"flex"}  marginBottom={1} alignItems={"center"}>
  <Grid mr={1}>
 <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          
         
        />    </Grid>
        <Grid mr={1}> <DatePicker style={{height:"55px",width:"200px"}} onChange={handleChange}/></Grid>
        <Grid mr={1}> <CustomButton text={'Search'} className='px-5 py-3 mx-3'  color={'warning'} onClick={handleSearch}/></Grid>
        </Grid>)
        }
         {type==="recentdonation"&&(
  <Grid display={"flex"} marginBottom={1} alignItems={"center"}>
    <Grid mr={1}>
      <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          
         
        />    
        </Grid>
       <Grid  mr={1}> <DatePicker style={{height:"55px",width:"200px"}} onChange={handleChange}/></Grid>
       <Grid> <CustomButton text={'Search'} className='px-5 py-3 mx-3'  color={'warning'} onClick={handleSearch}/></Grid>
        </Grid>)
        } */}
 
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={
          pagination}
        bordered={true}
        size={"middle"}
        scroll={{ x: true }}  
        // onChange={onChange}
        ref={tableRef}
      />
      <UploadModal open={uploadOpen} setOpen={setUploadOpen}/>
      </>
 
  );
};

export default TableComponent;
