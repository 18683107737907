import {
GET_DONER_LIST_IN_PROGRESS,
GET_DONER_LIST_SUCCESS,
GET_DONER_LIST_ERROR,
ADD_DONER_IN_PROGRESS,
ADD_DONER_IN_SUCCESS,
ADD_DONER_IN_ERROR,
DELETE_DONER_IN_PROGRESS,
DELETE_DONER_IN_SUCCESS,
DELETE_DONER_IN_ERROR,
UPDATE_DONER_IN_PROGRESS,
UPDATE_DONER_IN_SUCCESS,
UPDATE_DONER_IN_ERROR,
ADD_CSV_IN_PROGRESS,
ADD_CSV_IN_SUCCESS,
ADD_CSV_IN_ERROR
 
   } from "../../../constants/ActionType";
  
  // Get All DonerList
  export const getDonerListInProgress = (payload:any) => ({
    type: GET_DONER_LIST_IN_PROGRESS,
    payload: payload,
  });
  
  export const getDonerListInSuccess = (payload:any) => ({
    type: GET_DONER_LIST_SUCCESS,
    payload: payload,
  });
  
  export const getDonerListInError = (payload:any) => ({
    type: GET_DONER_LIST_ERROR,
    payload: payload,
  });
  // ADD DONER
export const addDonertInProgress = (payload:any) => ({
  type: ADD_DONER_IN_PROGRESS,
  payload: payload,
});

export const addDonerListSuccess = (payload:any) => ({
  type: ADD_DONER_IN_SUCCESS,
  payload: payload,
});

export const addDonerInError = (payload:any) => ({
  type: ADD_DONER_IN_ERROR,
  payload: payload,
});
// Delete DONER
export const deleteDONERInProgress = (payload:any) => ({
  type: DELETE_DONER_IN_PROGRESS,
  payload: payload,
});

export const deleteDONERSuccess = (payload:any) => ({
  type: DELETE_DONER_IN_SUCCESS,
  payload: payload,
});

export const deleteDONERError = (payload:any) => ({
  type: DELETE_DONER_IN_ERROR,
  payload: payload,
});
// UPDATE DONER
export const updateDonertInProgress = (payload:any) => ({
  type:UPDATE_DONER_IN_PROGRESS ,
  payload: payload,
});

export const updateDonerListSuccess = (payload:any) => ({
  type: UPDATE_DONER_IN_SUCCESS,
  payload: payload,
});

export const updateDonerInError = (payload:any) => ({
  type:UPDATE_DONER_IN_ERROR ,
  payload: payload,
});
  // ADD DONER
  export const addCsvInProgress = (payload:any) => ({
    type: ADD_CSV_IN_PROGRESS,
    payload: payload,
  });
  
  export const addCsvSuccess = (payload:any) => ({
    type: ADD_CSV_IN_SUCCESS,
    payload: payload,
  });
  
  export const addCsvError = (payload:any) => ({
    type: ADD_CSV_IN_ERROR,
    payload: payload,
  });

  