import React from 'react';
import FormCheckBox from '../../../common/ui/Forms/FormInput/CheckBox/FormCheckBox';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import { Grid } from '@mui/material';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import { useTranslation } from 'react-i18next';

const DonationTime = ({
  register,
  watch,
  control,
  defaultValues
}: {
  register: any;
  watch: any;
  control: any;
  defaultValues?:any;
}) => {
const {t}=useTranslation()

  const handleBreakFirst = () => {};
  const donationTimeValues = JSON.parse(defaultValues?.donationTime || '[]');
  const donationTime = donationTimeValues.length > 0 ? donationTimeValues[0] : {};

  
  return (
    <Grid className='chekbox-grid-head' mt={2}>
      <Grid className='chekbox-grid'>
        <FormInput
          name={`donationTime[0].breakFirst`}
          type='checkbox'
          control={control}
          inputProps={{
            ...register(`donationTime[0].breakFirst`, {}),
            defaultChecked: donationTime.breakFirst 
          }}
          className='w-18 h-18 mx-5'
          classNameFormGrid='checkbox-input-grid'
          label={t('Break_Fast')}
       
          // errors={errors}
        />
      </Grid>
      <Grid className='chekbox-grid'>
        <FormInput
          name={`donationTime[0].lunch`}
          type='checkbox'
          control={control}
          inputProps={{
            ...register(`donationTime[0].lunch`, {}),
            defaultChecked: donationTime.lunch 
          }}
          className='w-18 h-18 mx-5'
          classNameFormGrid='checkbox-input-grid'
          label={t('Lunch')}
        
          // errors={errors}
        />
      </Grid>{' '}
      <Grid className='chekbox-grid'>
        <FormInput
          name={`donationTime[0].evening`}
          type='checkbox'
          control={control}
          inputProps={{
            ...register(`donationTime[0].evening`, {}),
            defaultChecked: donationTime.evening
          }}
          className='w-18 h-18 mx-5'
          classNameFormGrid='checkbox-input-grid'
          label={t('Evening')}
        
          // errors={errors}
        />
      </Grid>{' '}
      <Grid className='chekbox-grid'>
        <FormInput
          name={`donationTime[0].dinner`}
          type='checkbox'
          control={control}
          inputProps={{
            ...register(`donationTime[0].dinner`, {}),
            defaultChecked: donationTime.dinner
          }}
          className='w-18 h-18 mx-5'
          classNameFormGrid='checkbox-input-grid'
          label={t('Dinner')}
          // errors={errors}
        />
      </Grid>
    </Grid>
  );
};

export default DonationTime;
