import {
 LOGIN_USER_IN_PROGRESS,
 LOGIN_USER_SUCCESS,
 LOGIN_USER_ERROR,
 LOGOUT_USER_IN_PROGRESS,
 LOGOUT_USER_SUCCESS,
 OTP_SEND_IN_PROGRESS,
 OTP_SEND_SUCCESS,
 OTP_SEND_ERROR,
 OTP_VERIFICATION_IN_PROGRESS,
 OTP_VERIFICATION_IN_SUCCESS,
 OTP_VERIFICATION_IN_ERROR,
 FORGET_PASSWORD_IN_PROGRESS,
 FORGET_PASSWORD_IN_SUCCESS,
 FORGET_PASSWORD_IN_ERROR

  } from "../../../constants/ActionType";
  
  export const UserLoginInProgress = (user:any) => {
    return {
      type: LOGIN_USER_IN_PROGRESS,
      payload: user,
    };
  };
  export const UserLoginSuccess = (user:any) => {
    return {
      type: LOGIN_USER_SUCCESS,
      payload: user,
    };
  };
  export const UserLoginError = (user:any) => {
    return {
      type: LOGIN_USER_ERROR,
      payload: user,
    };
  };
  export const UserLogoutInProgress = () => {
    return {
      type: LOGOUT_USER_IN_PROGRESS,
    };
  };
  export const UserLogoutSuccess = (payload:any) => {
    return {
      type: LOGOUT_USER_SUCCESS,
      payload: payload,
    };

  }
  export const otpSendInProgress = (user:any) => {
    return {
      type: OTP_SEND_IN_PROGRESS,
      payload: user,
    };
  };
  export const otpSendSuccess = (user:any) => {
    return {
      type: OTP_SEND_SUCCESS,
      payload: user,
    };
  };
  export const otpSendError = (user:any) => {
    return {
      type: OTP_SEND_ERROR,
      payload: user,
    };
  };
  export const otpVerificationInProgress = (user:any) => {
    return {
      type: OTP_VERIFICATION_IN_PROGRESS,
      payload: user,
    };
  };
  export const otpVerificationSuccess = (user:any) => {
    return {
      type: OTP_VERIFICATION_IN_SUCCESS,
      payload: user,
    };
  };
  export const otpVerificationError = (user:any) => {
    return {
      type: OTP_VERIFICATION_IN_ERROR,
      payload: user,
    };
  };
  export const forgetPasswordInProgress = (user:any) => {
    return {
      type: FORGET_PASSWORD_IN_PROGRESS,
      payload: user,
    };
  };
  export const forgetPasswordSuccess = (user:any) => {
    return {
      type: FORGET_PASSWORD_IN_SUCCESS,
      payload: user,
    };
  };
  export const forgetPasswordError = (user:any) => {
    return {
      type: FORGET_PASSWORD_IN_ERROR,
      payload: user,
    };
  };