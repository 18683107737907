import React from 'react';
import CommonModal from './CommonModal';
import CustomButton from '../CustomButton';
import { Grid, Typography } from '@mui/material';

const ErrorModal = ({ Succesopen, SuccessetOpen }: { Succesopen: any; SuccessetOpen: any }) => {
  return (
    <CommonModal
      onClose={() => SuccessetOpen(false)}
      open={Succesopen}
      modalClassName='px-20 py-20'
      boxProps={{ width: 300, height: 200 }}
    >
      <Grid>
        <Typography textAlign={'center'} mb={5}>
        At least one of the checkbox should be checked for each module 
        </Typography>
      </Grid>
      <Grid display={"flex"}justifyContent={"center"}>
      <CustomButton
          text={'ok'}
          className='px-5 py-3'
          color={'warning'}
          onClick={() => SuccessetOpen(false)}
        />
        </Grid>
      
    </CommonModal>
  );
};

export default ErrorModal;
