import {
GET_DONATION_LIST_IN_PROGRESS,
GET_DONATION_LIST_SUCCESS,
GET_DONATION_LIST_ERROR,
UPDATE_DONATION_IN_PROGRESS,
UPDATE_DONATION_IN_SUCCESS,
UPDATE_DONATION_IN_ERROR,
ADD_DONATION_IN_PROGRESS,
ADD_DONATION_IN_SUCCESS,
ADD_DONATION_IN_ERROR,
GET_FAMILY_IN_PROGRESS,GET_FAMILY_IN_SUCCESS,
GET_FAMILY_IN_ERROR,
GET_DONATION_IN_PROGRESS,
GET_DONATION_IN_SUCCESS,
GET_DONATION_IN_ERROR,
DONATION_BY_DATE_IN_PROGRESS,
DONATION_BY_DATE_IN_SUCCESS,
DONATION_BY_DATE_IN_ERROR,
TODAY_DONATION_IN_PROGRESS,
TODAY_DONATION_IN_SUCCESS,
TODAY_DONATION_IN_ERROR,
DONATION_REPORT_IN_PROGRESS,
DONATION_REPORT_IN_SUCCESS,
DONATION_REPORT_IN_ERROR,
GET_RECENT_DONATIONS_IN_PROGRESS,
GET_RECENT_DONATIONS_IN_SUCCESS,
GET_RECENT_DONATIONS_IN_ERROR,
GET_CSV_IN_PROGRESS,
GET_CSV_IN_SUCCESS,
GET_CSV_IN_ERROR,
GET_REMAINDER_DONATION_IN_PROGRESS,
GET_REMAINDER_DONATION_IN_SUCCESS,
GET_REMAINDER_DONATION_IN_ERROR
     
       } from "../../../constants/ActionType";
      
      // Get All DonationList
      export const getDonationListInProgress = (payload:any) => ({
        type: GET_DONATION_LIST_IN_PROGRESS,
        payload: payload,
      });
      
      export const getDonationListInSuccess = (payload:any) => ({
        type: GET_DONATION_LIST_SUCCESS,
        payload: payload,
      });
      
      export const getDonationListInError = (payload:any) => ({
        type: GET_DONATION_LIST_ERROR,
        payload: payload,
      });
       // Add Donations
       export const addDonationInProgress = (payload:any) => ({
        type:ADD_DONATION_IN_PROGRESS ,
        payload: payload,
      });
      
      export const addDonationListSuccess = (payload:any) => ({
        type: ADD_DONATION_IN_SUCCESS,
        payload: payload,
      });
      
      export const addDonationInError = (payload:any) => ({
        type:ADD_DONATION_IN_ERROR ,
        payload: payload,
      });
      
      // update Donation
    
      export const updateDonationInProgress = (payload:any) => ({
        type:UPDATE_DONATION_IN_PROGRESS ,
        payload: payload,
      });
      
      export const updateDonationListSuccess = (payload:any) => ({
        type: UPDATE_DONATION_IN_SUCCESS,
        payload: payload,
      });
      
      export const updateDonationInError = (payload:any) => ({
        type:UPDATE_DONATION_IN_ERROR ,
        payload: payload,
      });
       // get Family
    
       export const getFamilyInProgress = (payload:any) => ({
        type:GET_FAMILY_IN_PROGRESS ,
        payload: payload,
      });
      
      export const getFamilyListSuccess = (payload:any) => ({
        type: GET_FAMILY_IN_SUCCESS,
        payload: payload,
      });
      
      export const getFamilyInError = (payload:any) => ({
        type:GET_FAMILY_IN_ERROR ,
        payload: payload,
      });
      //get donation
      export const getDonationInProgress = (payload:any) => ({
        type:GET_DONATION_IN_PROGRESS,
        payload: payload,
      });
      
      export const getDonationSuccess = (payload:any) => ({
        type: GET_DONATION_IN_SUCCESS,
        payload: payload,
      });
      
      export const getDonationInError = (payload:any) => ({
        type:GET_DONATION_IN_ERROR,
        payload: payload,
      });
            //get donation By date
            export const getDonationBydateInProgress = (payload:any) => ({
              type:DONATION_BY_DATE_IN_PROGRESS,
              payload: payload,
            });
            
            export const getDonationByDateSuccess = (payload:any) => ({
              type: DONATION_BY_DATE_IN_SUCCESS,
              payload: payload,
            });
            
            export const getDonationByDateInError = (payload:any) => ({
              type:DONATION_BY_DATE_IN_ERROR,
              payload: payload,
            });
             //get today donation
             export const getTodayDonationInProgress = (payload:any) => ({
              type:TODAY_DONATION_IN_PROGRESS,
              payload: payload,
            });
            
            export const getTodayDonationSuccess = (payload:any) => ({
              type: TODAY_DONATION_IN_SUCCESS,
              payload: payload,
            });
            
            export const getTodayDonationInError = (payload:any) => ({
              type:TODAY_DONATION_IN_ERROR,
              payload: payload,
            });
            //donation  get report 
            export const getDonationReportInProgress = (payload:any) => ({
              type:DONATION_REPORT_IN_PROGRESS,
              payload: payload,
            });
            
            export const getDonationReportSuccess = (payload:any) => ({
              type: DONATION_REPORT_IN_SUCCESS,
              payload: payload,
            });
            
            export const getDonationReportInError = (payload:any) => ({
              type:DONATION_REPORT_IN_ERROR,
              payload: payload,
            });
             //donation  get RECENT
             export const getRecentDonationInProgress = (payload:any) => ({
              type:GET_RECENT_DONATIONS_IN_PROGRESS,
              payload: payload,
            });
            
            export const getRecentDonationSuccess = (payload:any) => ({
              type: GET_RECENT_DONATIONS_IN_SUCCESS,
              payload: payload,
            });
            
            export const getRecentDonationInError = (payload:any) => ({
              type:GET_RECENT_DONATIONS_IN_ERROR,
              payload: payload,
            });
             //donation  get CSV
            export const getCsvInProgress = (payload:any) => ({
              type:GET_CSV_IN_PROGRESS,
              payload: payload,
            });
            
            export const getCsvSuccess = (payload:any) => ({
              type: GET_CSV_IN_SUCCESS,
              payload: payload,
            });
            
            export const getCsvInError = (payload:any) => ({
              type:GET_CSV_IN_ERROR,
              payload: payload,
            });
             //remainder notification
             export const getRemainderDonationInprogress = (payload:any) => ({
              type:GET_REMAINDER_DONATION_IN_PROGRESS,
              payload: payload,
            });
            export const getRemainderDonationInSuccess = (payload:any) => ({
              type: GET_REMAINDER_DONATION_IN_SUCCESS,
              payload: payload,
            });   
            export const getRemainderDonationInError = (payload:any) => ({
              type:GET_REMAINDER_DONATION_IN_ERROR,
              payload: payload,
            });