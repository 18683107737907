import React, { useEffect, useState } from 'react';
import './calender.scss';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getDonationListInProgress } from '../../../common/redux/Donations/actions/donationAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
const localizer = momentLocalizer(moment);
const currentDate = moment();
function Calender() {
  const [viewDates, setViewDates] = useState({ start: null, end: null });
  const dispatch = useDispatch();
  const { donationList } = useSelector((state: any) => state?.donation);
  useEffect(() => {
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate = moment().endOf('month').format('YYYY-MM-DD');

    const payload = {
      pagination_required: 'false',
      from_date: viewDates.start
        ? moment(viewDates.start).format('YYYY-MM-DD')
        : startDate,
      to_date: viewDates.end
        ? moment(viewDates.end).format('YYYY-MM-DD')
        : endDate,
    };
    dispatch(getDonationListInProgress(payload));
  }, [viewDates]);

  const dat = donationList?.data?.data || [];
  const transformedData = dat?.map((item: any) => {
    return {
      title: `${item.donatedFor}: ${item.Doner_name}`,
      start: new Date(item.donatedDate),
      end: new Date(item.donatedDate),
    };
  });
  const handleSelect = ({ start, end }: any) => {
    // console.log(start);
    // console.log(end);
  };
  const handleNavigate = ({ start, end }: any) => {
    setViewDates({ start, end });
    console.log(moment(start).format('DD-MM-YYYY'));
    console.log(moment(end).format('DD-MM-YYYY'));
    console.log(moment().startOf('month').format('DD-MM-YYYY'));
  };
  return (
    <div>
      <Calendar
        views={{ month: true, agenda: true }}
        // selectable
        localizer={localizer}
        events={transformedData}
        step={60}
        startAccessor='start'
        endAccessor='end'
        // defaultDate={new Date()}
        defaultView='month'
        onSelectEvent={(event: any) => alert(event?.title)}
        style={{ height: '100vh' }}
        onSelectSlot={handleSelect}
        // onNavigate={handleNavigate}
        onRangeChange={handleNavigate}
      />
    </div>
  );
}

export default Calender;
