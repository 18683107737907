import axios from "axios";
import { getTokenfromLocalStorage } from "../../../../../utils/utilz";

const teamMemberInstance = axios.create({
  baseURL: `http://154.61.173.113:3000/`,
});
const token = getTokenfromLocalStorage();
export const getTeamMemberInstanceListEffect = (payload:any) => {
  return teamMemberInstance.request({
    url: `/teamMember/allList?pagination_required=true`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addTeamMemberInstanceListEffect = (formData:any) => {
  return teamMemberInstance.request({
    url: `/teamMember/add`,
    method: "POST",
    data:formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getRolesInstanceListEffect = (formData:any) => {
  return teamMemberInstance.request({
    url: `/roles/list`,
    method: "GET",
   
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateTeamMemberInstanceListEffect = (formData:any) => {
  return teamMemberInstance.request({
    url: `/teamMember/update`,
    method: "PUT",
    data:formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteTeamMemberInstanceListEffect = (payload:any) => {

  return teamMemberInstance.request({
    url: `/teamMember/delete/${payload?.id}`,
    method: "DELETE",
   
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

