import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import Message from "../../../components/Message/Message";
import {
  FORGET_PASSWORD_IN_PROGRESS,
  LOGIN_USER_IN_PROGRESS,
  LOGOUT_USER_IN_PROGRESS,
  OTP_SEND_IN_PROGRESS,
  OTP_VERIFICATION_IN_PROGRESS

} from "../../constants/ActionType";
import { getErrorMessageFromAPI } from "../../../utils/utilz";

import {

  UserLoginError,
  UserLoginSuccess,
  UserLogoutInProgress,
  UserLogoutSuccess,
  forgetPasswordError,
  forgetPasswordSuccess,
  otpSendError,
  otpSendSuccess,
  otpVerificationError,
  otpVerificationSuccess

} from "../auth/action/authAction";
import {  forgetPasswordEffect, otpEffect, otpVerifyEffect, userLoginEffect } from "./saga/effect/authEffect";
import cryptoEncryptionDecryption from "../../crypo";

function* UserLoginWatcher({ payload }: any) {
  try {

    let { data } = yield call(userLoginEffect, payload.data);

    if (data?.message) {
        yield call(Message as any, { type: "error", content: data?.message });


      yield put(
        UserLoginError({
          error: true,
          data: data,
        })
      );
    } else {
        if(payload.handleSuccess){
          payload.handleSuccess()
        }
     
   
      yield call(Message as any, {
        type: "success",
        content: "Logged In  Successfully",
      });
    
      localStorage.setItem('user_id',data.accessToken)
      localStorage.setItem('user_type',data?.user?.user_type)
      localStorage.setItem('firstName',data?.user?.firstName)
      localStorage.setItem(
        "role_name",
        cryptoEncryptionDecryption.Encrypt(data?.user.role_name)
      );
      localStorage.setItem("email",data?.user?.email)

  if (data?.permissions) {
    localStorage.setItem(
      "permissions",
      cryptoEncryptionDecryption.Encrypt(data?.permissions)
    );
  
  }
  
 
      yield put(
        UserLoginSuccess({
          data: data?.user,
          success: true,
        })
      );
    }
  } catch (e:any) {
    message.error(getErrorMessageFromAPI(e));
    yield put(
      UserLoginError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* UserLogoutWatcher() {

  localStorage.removeItem("permissions");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_type");
  localStorage.removeItem("email");

  yield put(
    UserLogoutSuccess({
      success: false,
    })
  );
}

function* otpSendWatcher({ payload }: any) {
  try {

    let { data } = yield call(otpEffect, payload.data);

    if (!data?.message) {
        yield call(Message as any, { type: "success", content: data?.message });


      yield put(
        otpSendError({
          error: true,
          data: data,
        })
      );
    } else {
        if(payload.handleSuccess){
          payload.handleSuccess()
        }
     
 
      yield put(
        otpSendSuccess({
          data: data?.user,
          success: true,
        })
      );
    }
  } catch (e:any) {
    message.error(getErrorMessageFromAPI(e));
    yield put(
      UserLoginError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* otpVerifyWatcher({ payload }: any) {
  try {

    let { data } = yield call(otpVerifyEffect, payload.data);

    if (!data?.message) {
        yield call(Message as any, { type: "success", content: data?.message });


      yield put(
        otpVerificationError({
          error: true,
          data: data,
        })
      );
    } else {
        if(payload.handleSuccess){
          payload.handleSuccess()
        }
     
 
      yield put(
        otpVerificationSuccess({
          data: data?.user,
          success: true,
        })
      );
    }
  } catch (e:any) {
    message.error(getErrorMessageFromAPI(e));
    yield put(
      otpVerificationError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* forgetPasswordWatcher({ payload }: any) {
  try {

    let { data } = yield call(forgetPasswordEffect, payload.data);

    if (!data?.message) {
        yield call(Message as any, { type: "success", content: data?.message });


      yield put(
        forgetPasswordError({
          error: true,
          data: data,
        })
      );
    } else {
        if(payload.handleSuccess){
          payload.handleSuccess()
        }
     
 
      yield put(
        forgetPasswordSuccess({
          data: data?.user,
          success: true,
        })
      );
    }
  } catch (e:any) {
    message.error(getErrorMessageFromAPI(e));
    yield put(
      forgetPasswordError({
        error: true,
        message: e["message"],
      })
    );
  }
}

export default function* AuthSaga() {
  yield takeLatest(LOGIN_USER_IN_PROGRESS, UserLoginWatcher);
  yield takeLatest(LOGOUT_USER_IN_PROGRESS, UserLogoutWatcher);
  yield takeLatest(OTP_SEND_IN_PROGRESS, otpSendWatcher);
  yield takeLatest(OTP_VERIFICATION_IN_PROGRESS, otpVerifyWatcher);
  yield takeLatest(FORGET_PASSWORD_IN_PROGRESS, forgetPasswordWatcher);

  
}
