import React, { useEffect } from 'react';
import CommonModal from './CommonModal';
import { Grid, Typography, useTheme } from '@mui/material';
import CustomButton from '../CustomButton';
import Separator from '../Separator';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch } from 'react-redux';
import { getDonationInProgress, getFamilyInProgress } from '../../redux/Donations/actions/donationAction';
import { useSelector } from 'react-redux';
import { PaymentType, familyMember } from '../../constants/dropDownList';
import { useTranslation } from 'react-i18next';
const ViewModel = ({ open, setOpen,donationId ,donorId}: { open: any; setOpen: any ,donationId?:any,donorId?:any}) => {
  const theme = useTheme();
  const {t}=useTranslation()
  const dispatch=useDispatch()

  useEffect(()=>{
    if(donationId){
      dispatch(getDonationInProgress(donationId))
    }

  },[donationId])
  const   {donation} = useSelector((state:any) => state);
  const donationData = donation?.getDonation?.data?.data || [];
  const familyList = donation?.
  familyList?.data?.data || [];
  useEffect(()=>{
    if(donorId){
      dispatch(getFamilyInProgress(donorId))
    }
  
  },[donorId])
  const mapRelationshipCodeToName = (code:any) => {
    const relationship = familyMember.find((member) => {
     
      return Number(member.Code) === Number(code);
    });
    return relationship ? relationship.Description : 'Unknown';
  };
  
  return (
    <CommonModal
      onClose={() => setOpen(false)}
      open={open}
      modalClassName='px-20 py-20'
      boxProps={{ width:500 }}
    >
      <Grid
        xs={12}
        display={'flex'}
        justifyContent={'flex-end'}
        className='custom-button-grid'
      >

        <CustomButton
          text={''}
          type='button'
          startIcon={HighlightOffIcon}
          startIconClassName='cancle-icon '
          className='custom-button-photo  cancle-button'
          onClick={() => setOpen(false)}
        />
      </Grid>

      <Grid xs={12} display={'flex'} justifyContent={'center'} my={1} mt={1}>
        <Typography
          fontSize={18}
          fontWeight={600}
          color={theme.palette.warning.dark}
        >
          {t('Donation_View')}
        </Typography>
      </Grid>
      <Grid xs={12} my={1} mt={3}>
        <Typography fontSize={16} fontWeight={600}>
        {t('Donation_Details')}
        </Typography>
        <Separator color={theme.palette.warning.dark} />
      </Grid>
      <Grid xs={12} display={'flex'} my={1}>
        <Typography fontSize={16} fontWeight={600}>
        {t('Donated_by')}:{' '}
        </Typography>
        <Typography> {donationData.Doner_name}</Typography>
      </Grid>
      <Grid xs={12} display={'flex'} my={1}>
        <Typography fontSize={16} fontWeight={600}>
        {t('Donated_For')}:{' '}
        </Typography>
        <Typography>{donationData.donatedFor}</Typography>
      </Grid>
      <Grid xs={12} display={'flex'} my={1}>
        <Typography fontSize={16} fontWeight={600}>
        {t('Donated_date')} :{" "}
        </Typography>
        <Typography>{donationData.donatedDate}</Typography>
      </Grid>
      <Grid xs={12} display={'flex'} my={1}>
        <Typography fontSize={16} fontWeight={600}>
        {t('Recurring_Donation')} :{' '}
        </Typography>
        <Typography>{donationData.recurringDonation===true?"Yes":"No"}</Typography>
      </Grid>
      <Grid xs={12} display={'flex'} my={1}>
        <Typography fontSize={16} fontWeight={600}>
        {t('Donate_Type')} :{' '}
        </Typography>
        <Typography>{`${PaymentType[Number(donationData.donateType)-1]?.Description}`}</Typography>
      </Grid>
      <Grid xs={12} display={'flex'} my={1}>
        <Typography fontSize={16} fontWeight={600}>
        {t('Donation_Time')}:
        </Typography>
       
      </Grid>
      <Typography>
  <ul>
    {donationData?.donationTime?.map((timeSlot: any, index: number) => (
      <li key={index}>
        {Object.entries(timeSlot).map(([key, value]:any) => (
          <span key={key}>
       
            {value.toString() === 'true' && (
              <li key={key}>{`${key.charAt(0).toUpperCase() + key.slice(1)}: Yes, `}</li>
            )}
            {value.toString() === 'false' && (
              <li key={key}>{`${key.charAt(0).toUpperCase() + key.slice(1)}: No, `}</li>
            )}
          </span>
        ))}
      </li>
    ))}
  </ul>
</Typography>
<Grid xs={12} display={'flex'} my={1}>
  <Typography fontSize={16} fontWeight={600}>
  {t('Family_Member')}:
  </Typography>
</Grid>
<ul>
  {familyList.map((familyMember: any, index: number) => (
    <li key={index}>
      <Typography>
        {`${familyMember.firstName} ${familyMember.lastName}`} - Relationship: {mapRelationshipCodeToName(familyMember.relationship_code)}
      </Typography>
    </li>
  ))}
</ul>



      {Number(donationData.donateType)!==2&&(
        <>
         <Grid xs={12} my={1} mt={4}>
         <Typography fontSize={16} fontWeight={600}>
         {t('Payment_Details')}
         </Typography>
         <Separator color={theme.palette.warning.dark} /> 
       </Grid>
       <Grid xs={12} display={'flex'} my={1}>
         <Typography fontSize={16} fontWeight={600}>
         {t('Payment_Method')}:{' '}
         </Typography>
         <Typography>{`${PaymentType[Number(donationData.donateType)-1]?.Description}`} </Typography>
       </Grid>
       <Grid xs={12} display={'flex'} my={1}>
         <Typography fontSize={16} fontWeight={600}>
         {t('Amount')}:{' '}
         </Typography>
         <Typography>{donationData.amount}</Typography>
       </Grid>
       <Grid xs={12} display={'flex'} my={1}>
         <Typography fontSize={16} fontWeight={600}>
         {t('Payment_Status')} :{' '}
         </Typography>
         <Typography>{donationData.Payment_status===1?"pending":"paid"}</Typography>
       </Grid>
      
       </> )}
       {donationData.donateType=== '2'&&(
        <>
         <Grid xs={12} my={1} mt={4}>
         <Typography fontSize={16} fontWeight={600}>
         {t('Material_Details')}
         </Typography>
         <Separator color={theme.palette.warning.dark} />
       </Grid>
       <Grid xs={12} display={'flex'} my={1}>
         <Typography fontSize={16} fontWeight={600}>
         {t('Material_Type')}:{' '}
         </Typography>
         <Typography>{donationData.materialType}</Typography>
       </Grid>
       <Grid xs={12} display={'flex'} my={1}>
         <Typography fontSize={16} fontWeight={600}>
         {t('Material_Count')} :{' '}
         </Typography>
         <Typography>{donationData.count}</Typography>
       </Grid>
       <Grid xs={12} display={'flex'} my={1}>
         <Typography fontSize={16} fontWeight={600}>
         {t('Additional_Instructions')} :{' '}
         </Typography>
         <Typography>{donationData.additionalInstructionsMaterial}</Typography>
       </Grid>
      
       </> )}
     
    </CommonModal>
  );
};

export default ViewModel;
