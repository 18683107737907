import {
UPDATE_USER_IN_PROGRESS,
UPDATE_USER_IN_SUCCESS,
UPDATE_USER_IN_ERROR,  
UPDATE_PASSWORD_IN_PROGRESS,
UPDATE_PASSWORD_IN_SUCCESS,
UPDATE_PASSWORD_IN_ERROR,
GET_PROFILE_IN_PROGRESS,
GET_PROFILE_IN_SUCCESS,
GET_PROFILE_IN_ERROR} from "../../../constants/ActionType";
        
    
        

     // UPDATE ROLES
     export const updateUserInProgress = (payload:any) => ({
      type: UPDATE_USER_IN_PROGRESS,
      payload: payload,
    });
    
    export const updateUserSuccess = (payload:any) => ({
      type: UPDATE_USER_IN_SUCCESS,
      payload: payload,
    });
    
    export const updateUserError = (payload:any) => ({
      type: UPDATE_USER_IN_ERROR,
      payload: payload,
    });
    //UPDATE PASSWORD
      
    export const updatePasswordInProgress = (payload:any) => ({
        type: UPDATE_PASSWORD_IN_PROGRESS,
        payload: payload,
      });
      
      export const updatePasswordSuccess = (payload:any) => ({
        type: UPDATE_PASSWORD_IN_SUCCESS,
        payload: payload,
      });
      
      export const updatePasswordError = (payload:any) => ({
        type: UPDATE_PASSWORD_IN_ERROR,
        payload: payload,
      });
       //GET PROFILE
      export const getProfileInProgress = (payload:any) => ({
        type: GET_PROFILE_IN_PROGRESS,
        payload: payload,
      });
      
      export const getProfileInSuccess = (payload:any) => ({
        type: GET_PROFILE_IN_SUCCESS,
        payload: payload,
      });
      
      export const getProfileInError = (payload:any) => ({
        type: GET_PROFILE_IN_ERROR,
        payload: payload,
      });
        