import React, { useEffect, useState } from 'react';
import FormInput from './ui/Forms/FormInput/FormInput';
import './common.scss';
import { Grid } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ImagePickerModal from '../common/ui/Modal/ImagePickerModal';
import CustomButton from './ui/CustomButton';
const UserProfileImage = ({
  control,
  register,
  errors,
  image,
  setValue,
  watch,
  defaultValue
}: {
  control: any;
  register: any;
  errors: any;
  image: any;
  setValue: any;
  watch: any;
  defaultValue?:any
}) => {
  const [open, setOpen] = useState(false);
  const profileImage = watch('profile_url');

  const processImage = (data: any, file: any) => {
    try {
      // const imgType = uploadType;
      const imageBase64 = data;
      const fileName = file?.target?.files?.[0]?.name;

      const generateUniqueFileName = () => {
        const timestamp = Date.now();
        const randomId = Math.random().toString(36).substring(2, 8);
        return `image_${timestamp}_${randomId}.jpg`;
      };
      const fileNames = generateUniqueFileName();

      if (imageBase64 || fileName) {
     
        setValue('profile_url', data);
        //   if (imgType === 'PROFILE_PHOTO') {
        //     dispatch(
        //       uploadProfilePhotoRequest({
        //         imgType,
        //         imageBase64,
        //         fileName: fileName || fileNames,
        //       } as any)
        //     );
        //   } else if (imgType === 'SIGNATURE') {
        //     dispatch(
        //       uploadSignaturePhotoRequest({
        //         imgType,
        //         imageBase64,
        //         fileName: fileName || fileNames,
        //       } as any)
        //     );
        //   }

        setOpen(false);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  
  const handleImage = () => {
    setOpen(true);
  };
  return (
    <div>
      <Grid>
        <img
          src={
            profileImage
              ? `data:image/png/jpg/jpeg;base64,${profileImage}`
              : defaultValue?.profile_url?defaultValue?.profile_url:image
          }
          className='profile-img-log'
        />

        <CustomButton
          text={''}
          type='button'
          startIcon={AddAPhotoIcon}
          startIconClassName='photo-icon f-40 '
          className='custom-button-photo mb-25'
          onClick={handleImage}
        />
      </Grid>

      <FormInput
        name='profileImage'
        type='text'
        placeholder='First Name..'
        control={control}
        className='input-from'
        hidden
       
        inputProps={{
          ...register('profileImage', {}),
        }}
        errors={errors}
       
      />
      <ImagePickerModal
        open={open}
        setOpen={setOpen}
        handleImage={(data: any, file: any) => processImage(data, file)}
        enableCropping
      />
    </div>
  );
};

export default UserProfileImage;
