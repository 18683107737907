//login
export const LOGIN_USER_IN_PROGRESS = "LOGIN_USER_IN_PROGRESS";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
//logout
export const LOGOUT_USER_IN_PROGRESS="LOGOUT_USER_IN_PROGRESS";
export const LOGOUT_USER_SUCCESS="LOGOUT_USER_SUCCESS";
//donerGet
export const GET_DONER_LIST_IN_PROGRESS="GET_DONER_LIST_IN_PROGRESS";
export const GET_DONER_LIST_SUCCESS="GET_DONER_LIST_SUCCESS";
export const GET_DONER_LIST_ERROR="GET_DONER_LIST_ERROR";
//donerAdd
export const ADD_DONER_IN_PROGRESS = "ADD_DONER_IN_PROGRESS";
export const ADD_DONER_IN_SUCCESS = "ADD_DONER_IN_SUCCESS";
export const ADD_DONER_IN_ERROR = "ADD_DONER_IN_ERROR";
//donerDelete
export const DELETE_DONER_IN_PROGRESS = "DELETE_DONER_IN_PROGRESS";
export const DELETE_DONER_IN_SUCCESS = "DELETE_DONER_IN_SUCCESS";
export const DELETE_DONER_IN_ERROR = "DELETE_DONER_IN_ERROR";
//donerUpdate
export const UPDATE_DONER_IN_PROGRESS = "UPDATE_DONER_IN_PROGRESS";
export const UPDATE_DONER_IN_SUCCESS = "UPDATE_DONER_IN_SUCCESS";
export const UPDATE_DONER_IN_ERROR = "UPDATE_DONER_IN_ERROR";
//donationGet
export const GET_DONATION_LIST_IN_PROGRESS="GET_DONATION_LIST_IN_PROGRESS";
export const GET_DONATION_LIST_SUCCESS="GET_DONATION_LIST_SUCCESS";
export const GET_DONATION_LIST_ERROR="GET_DONATION_LIST_ERROR";
//donationsUpdate
export const UPDATE_DONATION_IN_PROGRESS = "UPDATE_DONATION_IN_PROGRESS";
export const UPDATE_DONATION_IN_SUCCESS = "UPDATE_DONATION_IN_SUCCESS";
export const UPDATE_DONATION_IN_ERROR = "UPDATE_DONATION_IN_ERROR";
// Add donation
export const ADD_DONATION_IN_PROGRESS = "ADD_DONATION_IN_PROGRESS";
export const ADD_DONATION_IN_SUCCESS = "ADD_DONATION_IN_SUCCESS";
export const ADD_DONATION_IN_ERROR = "ADD_DONATION_IN_ERROR";
// get familyMembers
export const GET_FAMILY_IN_PROGRESS = "GET_FAMILY_IN_PROGRESS";
export const GET_FAMILY_IN_SUCCESS = "GET_FAMILY_IN_SUCCESS";
export const GET_FAMILY_IN_ERROR = "GET_FAMILY_IN_ERROR";
//get Donations
export const GET_DONATION_IN_PROGRESS = "GET_DONATION_IN_PROGRESS";
export const GET_DONATION_IN_SUCCESS = "GET_DONATION_IN_SUCCESS";
export const GET_DONATION_IN_ERROR = "GET_DONATION_IN_ERROR";
//Get Team Members
export const GET_TEAM_MEMBER_IN_PROGRESS = "GET_TEAM_MEMBER_IN_PROGRESS";
export const GET_TEAM_MEMBER_IN_SUCCESS = "GET_TEAM_MEMBER_IN_SUCCESS";
export const GET_TEAM_MEMBER_IN_ERROR = "GET_TEAM_MEMBER_IN_ERROR";
//add Team Members
export const ADD_TEAM_MEMBER_IN_PROGRESS = "ADD_TEAM_MEMBER_IN_PROGRESS";
export const ADD_TEAM_MEMBER_IN_SUCCESS = "ADD_TEAM_MEMBER_IN_SUCCESS";
export const ADD_TEAM_MEMBER_IN_ERROR = "ADD_TEAM_MEMBER_IN_ERROR";
//get roles
export const GET_ROLES_IN_PROGRESS = "ADD_ROLES_IN_PROGRESS";
export const GET_ROLES_IN_SUCCESS = "ADD_ROLES_IN_SUCCESS";
export const GET_ROLES_IN_ERROR = "ADD_ROLES_IN_ERROR";
//update Team Members
export const UPDATE_TEAM_MEMBER_IN_PROGRESS = "UPDATE_TEAM_MEMBER_IN_PROGRESS";
export const UPDATE_TEAM_MEMBER_IN_SUCCESS = "UPDATE_TEAM_MEMBER_IN_SUCCESS";
export const UPDATE_TEAM_MEMBER_IN_ERROR = "UPDATE_TEAM_MEMBER_IN_ERROR";
//delete Team Member
export const DELETE_TEAM_MEMBER_IN_PROGRESS = "DELETE_TEAM_MEMBER_IN_PROGRESS";
export const DELETE_TEAM_MEMBER_IN_SUCCESS = "DELETE_TEAM_MEMBER_IN_SUCCESS";
export const DELETE_TEAM_MEMBER_IN_ERROR = "DELETE_TEAM_MEMBER_IN_ERROR";
//Donor csv 
export const ADD_CSV_IN_PROGRESS = "ADD_CSV_IN_PROGRESS";
export const ADD_CSV_IN_SUCCESS = "ADD_CSV_IN_SUCCESS";
export const ADD_CSV_IN_ERROR = "ADD_CSV_IN_ERROR";
//Add Roles 
export const ADD_ROLES_IN_PROGRESS = "ADD_ROLES_IN_PROGRESS";
export const ADD_ROLES_IN_SUCCESS = "ADD_ROLES_IN_SUCCESS";
export const ADD_ROLES_IN_ERROR = "ADD_ROLES_IN_ERROR";
//get roles
export const GET_ROLESlIST_IN_PROGRESS = "GET_ROLESlIST_IN_PROGRESS";
export const GET_ROLESlIST_IN_SUCCESS = "GET_ROLESlIST_IN_SUCCESS";
export const GET_ROLESlIST_IN_ERROR = "GET_ROLESlIST_IN_ERROR";
//delete Roles 
export const DELETE_ROLESlIST_IN_PROGRESS = "DELETE_ROLESlIST_IN_PROGRESS";
export const DELETE_ROLESlIST_IN_SUCCESS = "DELETE_ROLESlIST_IN_SUCCESS";
export const DELETE_ROLESlIST_IN_ERROR = "DELETE_ROLESlIST_IN_ERROR";
//update Roles 
export const UPDATE_ROLESlIST_IN_PROGRESS = "UPDATE_ROLESlIST_IN_PROGRESS";
export const UPDATE_ROLESlIST_IN_SUCCESS = "UPDATE_ROLESlIST_IN_SUCCESS";
export const UPDATE_ROLESlIST_IN_ERROR = "UPDATE_ROLESlIST_IN_ERROR";
//update user
export const UPDATE_USER_IN_PROGRESS = "UPDATE_USER_IN_PROGRESS";
export const UPDATE_USER_IN_SUCCESS = "UPDATE_USER_IN_SUCCESS";
export const UPDATE_USER_IN_ERROR = "UPDATE_USER_IN_ERROR";
//update user
export const UPDATE_PASSWORD_IN_PROGRESS = "UPDATE_PASSWORD_IN_PROGRESS";
export const UPDATE_PASSWORD_IN_SUCCESS = "UPDATE_PASSWORD_IN_SUCCESS";
export const UPDATE_PASSWORD_IN_ERROR = "UPDATE_PASSWORD_IN_ERROR";
//otp send
export const OTP_SEND_IN_PROGRESS = "OTP_SEND_IN_PROGRESS";
export const OTP_SEND_SUCCESS = "OTP_SEND_SUCCESS";
export const OTP_SEND_ERROR = "OTP_SEND_ERROR";
//otp verification
export const OTP_VERIFICATION_IN_PROGRESS = "OTP_VERIFICATION_IN_PROGRESS";
export const OTP_VERIFICATION_IN_SUCCESS = "OTP_VERIFICATION_IN_SUCCESS";
export const OTP_VERIFICATION_IN_ERROR = "OTP_VERIFICATION_IN_ERROR";
//Forget Password
export const FORGET_PASSWORD_IN_PROGRESS = "FORGET_PASSWORD_IN_PROGRESS";
export const  FORGET_PASSWORD_IN_SUCCESS = " FORGET_PASSWORD_IN_SUCCESS";
export const  FORGET_PASSWORD_IN_ERROR = " FORGET_PASSWORD_IN_ERROR";
//donation get by date
export const DONATION_BY_DATE_IN_PROGRESS = "DONATION_BY_DATE_IN_PROGRESS";
export const  DONATION_BY_DATE_IN_SUCCESS = "DONATION_BY_DATE_IN_SUCCESS";
export const DONATION_BY_DATE_IN_ERROR = " DONATION_BY_DATE_IN_ERROR";
//donation get by date
export const TODAY_DONATION_IN_PROGRESS = "TODAY_DONATION_IN_PROGRESS";
export const  TODAY_DONATION_IN_SUCCESS = "TODAY_DONATION_IN_SUCCESS";
export const TODAY_DONATION_IN_ERROR = " TODAY_DONATION_IN_ERROR";
//donation  get report 
export const DONATION_REPORT_IN_PROGRESS = "DONATION_REPORT_IN_PROGRESS";
export const  DONATION_REPORT_IN_SUCCESS = "DONATION_REPORT_IN_SUCCESS";
export const DONATION_REPORT_IN_ERROR = " DONATION_REPORT_IN_ERROR";
//user getProfile 
export const GET_PROFILE_IN_PROGRESS = "GET_PROFILE_IN_PROGRESS";
export const  GET_PROFILE_IN_SUCCESS = "GET_PROFILE_IN_SUCCESS";
export const GET_PROFILE_IN_ERROR = "GET_PROFILE_IN_ERROR";
//get recent donations
export const GET_RECENT_DONATIONS_IN_PROGRESS = "GET_RECENT_DONATIONS_IN_PROGRESS";
export const  GET_RECENT_DONATIONS_IN_SUCCESS = "GET_RECENT_DONATIONS_IN_SUCCESS";
export const GET_RECENT_DONATIONS_IN_ERROR = "GET_RECENT_DONATIONS_IN_ERROR";
//get recent donations
export const GET_CSV_IN_PROGRESS = "GET_CSV_IN_PROGRESS";
export const  GET_CSV_IN_SUCCESS = "GET_CSV_IN_SUCCESS";
export const GET_CSV_IN_ERROR = "GET_CSV_IN_ERROR";
//get remainder donations
export const GET_REMAINDER_DONATION_IN_PROGRESS = "GET_REMAINDER_DONATION_IN_PROGRESS";
export const  GET_REMAINDER_DONATION_IN_SUCCESS = "GET_REMAINDER_DONATION_IN_SUCCESS";
export const GET_REMAINDER_DONATION_IN_ERROR = "GET_REMAINDER_DONATION_IN_ERROR";