import {
   ADD_ROLES_IN_PROGRESS,
   ADD_ROLES_IN_SUCCESS,
   ADD_ROLES_IN_ERROR,
   GET_ROLESlIST_IN_PROGRESS,
   GET_ROLESlIST_IN_SUCCESS,
   GET_ROLESlIST_IN_ERROR,
   DELETE_ROLESlIST_IN_PROGRESS,
   DELETE_ROLESlIST_IN_SUCCESS,
   DELETE_ROLESlIST_IN_ERROR,
   UPDATE_ROLESlIST_IN_PROGRESS,
   UPDATE_ROLESlIST_IN_SUCCESS,
   UPDATE_ROLESlIST_IN_ERROR
      } from "../../../constants/ActionType";
      
      const defaultStatus = {
        progressing: false,
        success: false,
        error: false,
        data: [],
      };
      const initialState = {
       
        addRoles: { ...defaultStatus },
        rolesList: { ...defaultStatus },
        deleteRoles:{...defaultStatus},
        updateRoles:{...defaultStatus}
    
       
      };
      const rolesReducer = (state = initialState, action:any) => {
        const { type, payload } = action;
        switch (type) {
       
            //add roles
            case ADD_ROLES_IN_PROGRESS:
              return {
                ...state,
                 addRoles: { ...defaultStatus, ...payload, progressing: true },
              };
            case ADD_ROLES_IN_SUCCESS:
              return {
                ...state,
                 addRoles: { ...defaultStatus, ...payload, success: true },
              };
            case ADD_ROLES_IN_ERROR:
              return {
                ...state,
                 addRoles: { ...defaultStatus, ...payload, error: true },
              };
              
                    //rolesList
                    case  GET_ROLESlIST_IN_PROGRESS:

                      return {
                        ...state,
                        rolesList: { ...defaultStatus, ...payload, progressing: true },
                      };
                    case GET_ROLESlIST_IN_SUCCESS:
                      return {
                        ...state,
                        rolesList: { ...defaultStatus, ...payload, success: true },
                      };
                    case GET_ROLESlIST_IN_ERROR:
                      return {
                        ...state,
                        rolesList: {
                          ...defaultStatus,
                          ...payload,
                          error: true,
                        },
                      };
                      //DELETE ROLES
                    case  DELETE_ROLESlIST_IN_PROGRESS:

                    return {
                      ...state,
                      deleteRoles: { ...defaultStatus, ...payload, progressing: true },
                    };
                  case DELETE_ROLESlIST_IN_SUCCESS:
                    return {
                      ...state,
                      deleteRoles: { ...defaultStatus, ...payload, success: true },
                    };
                  case DELETE_ROLESlIST_IN_ERROR:
                    return {
                      ...state,
                      deleteRoles: {
                        ...defaultStatus,
                        ...payload,
                        error: true,
                      },
                    };
                      //UPDATE ROLES
            case UPDATE_ROLESlIST_IN_PROGRESS:
              return {
                ...state,
                 updateRoles: { ...defaultStatus, ...payload, progressing: true },
              };
            case UPDATE_ROLESlIST_IN_SUCCESS:
              return {
                ...state,
                 updateRoles: { ...defaultStatus, ...payload, success: true },
              };
            case UPDATE_ROLESlIST_IN_ERROR:
              return {
                ...state,
                 updateRoles: { ...defaultStatus, ...payload, error: true },
              };
              
           default:
            return state;
        }
      };
      
      export default rolesReducer;
      