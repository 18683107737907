    import { Grid, Typography, useTheme } from '@mui/material';
    import React, { useEffect, useState } from 'react';
    import Select from 'react-select';
    import SvgConverter from '../../../common/SvgConverter';
    import LabelWithIcon from '../../../common/ui/LabelWithIcon';
    import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
    import CustomButton from '../../../common/ui/CustomButton';
    import { Controller, useForm } from 'react-hook-form';
    import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
    import AddIcon from '@mui/icons-material/Add';
    import ArrowBackIcon from '@mui/icons-material/ArrowBack';
    import { useLocation, useNavigate } from 'react-router-dom';
    import { APP_ROUTES } from '../../../common/constants/Routes';
    import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
    import { bloodGroup, gender } from '../../../common/constants/dropDownList';
    import { addTeamMemberInProgress, getTeamMemberRolesListInProgress, updateTeamMemberInProgress } from '../../../common/redux/teamMembers/action/teamMemberAction';
    import { useDispatch } from 'react-redux';
    import { getRolesListInProgress } from '../../../common/redux/roles/action/rolesAction';
    import { useSelector } from 'react-redux';
import { convertToYYYYMMDD } from '../../../utils/utilz';
import { useTranslation } from 'react-i18next';
    const TeamMemberForm = () => {
      const {t}=useTranslation()
      const theme = useTheme();
      const location = useLocation();
      const navigate = useNavigate();
      const dispatch=useDispatch();
      const [passwordMatch, setPasswordMatch] = useState(true);

      const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
        trigger,
        getValues,
       
        resetField
      } = useForm({
        defaultValues: {
          firstName: location.state?.data?.firstName || '',
          lastName: location.state?.data?.lastName || '',
          email: location.state?.data?.email || '',
          phone: location.state?.data?.contact || '',
          gender_type: location.state?.data?.gender_type || '',
          dob:location.state?.data?.dob? convertToYYYYMMDD(location.state?.data?.dob) || '':"",
          blood_group_id: location.state?.data?.bloodGroup || '',
          address: location.state?.data?.address || '',
          role_id:location.state?.data?.role_id||"",
          password:location.state?.data?.password||"",
          confirmPassword:""
        },
      });
      const handleReset = () => {
        setValue('firstName',"")
        setValue('lastName',"")
        setValue('email',"")
        setValue('phone',"")
        setValue('gender_type',"")
        setValue('dob',"")
        setValue('blood_group_id',"")
        setValue('address',"")
        setValue('role_id',"")
        setValue('password',"")
        setValue('confirmPassword',"")
       };
     
      const  {roles}= useSelector((state:any) => state);
      const rolesData = roles?.rolesList?.data?.data || [];    
      const handleChange = (e:any) => {
        const { name, value } = e.target;
        const newPassword = getValues('password');
        if (name === 'confirmPassword' && newPassword !== value) {
          setPasswordMatch(false);
        } else {
          setPasswordMatch(true);
        }
      };
      useEffect(()=>{
        const paylod ={
          pageNo:1
        }
    
        dispatch(getRolesListInProgress(paylod))
      },[])
    
      const  {teamMember}= useSelector((state:any) => state);
  
      const handleCategory = (value: any) => {
        setValue('role_id', value, {
          shouldValidate: true,
        });
      };
    
      const onSubmit = (data: any) => {
        data.id = location.state?.data?.id;
        data.user_id = location.state?.data?.user_id;
        if(location?.state?.type === 'edit'){
          const handleSucces=()=>{
            navigate(APP_ROUTES.TEAM_MEMBER)
          }
          const payload={
            handleSucces,
            data
          }
        
          
          dispatch(updateTeamMemberInProgress(payload))
        }
        else{ 
          const handleSucces=()=>{
            navigate(APP_ROUTES.TEAM_MEMBER)
          }
          const payload={
            handleSucces,
            data
          }
          dispatch(addTeamMemberInProgress(payload))}
        // navigate(APP_ROUTES.TEAM_MEMBER);
      };
      const mobileNo = watch('phone');
      const parseMobileNo = (mobileNo: any) => {
        const allowedFirstDigits = ['6', '7', '8', '9'];
        const numberPattern = /\d+/g;
        const matches: any[] | null = String(mobileNo).match(numberPattern);
        if (matches) {
          const digits = matches.join('');
          if (allowedFirstDigits.includes(digits.charAt(0))) {
            return digits.substring(0, 10);
          }
        }
        return '';
      };
      useEffect(() => {
        if (mobileNo) {
          setValue('phone', parseMobileNo(mobileNo));
        }
      }, [mobileNo]);
      const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
        const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
        setValue('email', emailValue, { shouldValidate: true });
        trigger('email');
      };
      return (

        <Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              display='flex'
              justifyContent='center'
              // className='login-form'
            >
              <Grid
                item
                xs={12}
                p={2}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color={theme.palette.info.dark}
                >
                  {location?.state?.type === 'edit' ? `${t('Edit_Team_Member')}` : `${t('Add_Team_Member')}`}
                </Typography>
                <CustomButton
                  type='button'
                  text={`${t('Go_Back')}`}
                  startIcon={SubdirectoryArrowRightIcon}
                  color={'primary'}
                  className='p-8 color-gray'
                  onClick={() => {
                    navigate(APP_ROUTES.TEAM_MEMBER);
                  }}
                />
              </Grid>

              <Grid item  xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={`${t('First_Name')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6  '
                />
                <FormInput
                  name='firstName'
                  type='text'
                  placeholder={`${t('First_Name')}...`}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                      value: true,
                      message: `${t('PLEASE_ENTER_FIRST_NAME')}`,
                    },
                  }}
                  inputProps={{
                    ...register('firstName', {}),
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid item   xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={`${t('Last_Name')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6 ml-3'
                />
                <FormInput
                  name='lastName'
                  type='text'
                  placeholder={`${t('Last_Name')}...`}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                      value: true,
                      message:`${t('PLEASE_ENTER_LAST_NAME')}`,
                    },
                  }}
                  inputProps={{
                    ...register('lastName', {}),
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid item  xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={`${t('EMAIL')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6 ml-3'
                />
                <FormInput
                  name='email'
                  type='text'
                  placeholder={`${t('EMAIL')}...`}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                        value: true,
                        message: `${t('PLEASE_ENTER_EMAIL')}`,
                    },
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: `${t('INVALID_EMAIL_ADDRESS')}`,
                    },
                }}
                  inputProps={{
                    ...register('email', {}),
                    onChange: handleEmailChange 
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid item  xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={`${t('MOBILE_NUMBER')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6 ml-3'
                />
                <FormInput
                  name='phone'
                  type='text'
                  placeholder={`${t('MOBILE_NUMBER')}...`}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                        value: true,
                        message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
                    },
                    maxLength: {
                        value: 10,
                        message: `${t('MOBILE_NUMBER_SHOULD_NOT_EXCEED')}`,
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: `${t('ONLY_NUMERIC_CHARACTERS_ALLOWED')}`,
                  },
                  minLength: {
                    value: 10,
                    message: `${t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG')}`,
                },
                }}
                  inputProps={{
                    ...register('phone', {}),
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid item  xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
              <LabelWithIcon
                  label={`${t('Gender')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6 '
                />
                <CustomSelectBox
                  options={gender}
                  ClassName='form-select'
                  placeholder={`${t('Gender')}...`}
                  inputProps={register('gender_type', {
                    required: {
                      value: true,
                      message: `${t('Select_Gender')}`,
                    },
                  })}
                  name={'gender_type'}
                  error={errors}
                  defaultValue={location.state?.data?.gender_type}
                />
              </Grid>
              <Grid item  xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={`${t('DOB')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6 ml-3'
                />
                <FormInput
                  name='dob'
                  type='date'
                  placeholder={`${t('DOB')}...`}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                      value: true,
                      message: `${t('PLEASE_ENTER_DOB')}`,
                    },
                  }}
                  inputProps={{
                    ...register('dob', {}),
                    max: new Date().toISOString().split('T')[0]
                  }}
                  errors={errors}
                  defaultValue={location.state?.data?.dob}
                />
              </Grid>
              <Grid item  xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
              <LabelWithIcon
                  label={`${t('BLOOD_GROUP')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6 '
                />
                <CustomSelectBox
                  options={bloodGroup}
                  ClassName='form-select'
                  placeholder={`${t('BLOOD_GROUP')}...`}
                  name={'blood_group_id'}
                  inputProps={register('blood_group_id', {
                 
                  })}
                  error={errors}
                  defaultValue={location.state?.data?.bloodGroup}
                />
              </Grid>
              <Grid item   xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
              <LabelWithIcon
                    label={`${t('Role')} <span style="color: red;font-size:20px;">*</span>`}
                    justifyContent='flex-start'
                    className='mb-6'
                  />
                  <Controller
                    control={control}
                    name='role_id'
                    render={({ field }) => (
                      <Select
                        options={rolesData}
                        isSearchable
                        placeholder={`${t('Role')}...`}
                        value={
                          rolesData.find(
                            (option: any) => option.id === watch('role_id')
                          ) || null
                        }
                        getOptionLabel={(option: any) => `${option.name}`}
                        getOptionValue={(option: any) => option.id}
                        onChange={(selectedOption: any) => {
                          handleCategory(selectedOption?.id || '');
                          field.onChange(selectedOption?.id || '');
                        }}
                        onBlur={() => field.onBlur()}
                        isClearable
                        defaultValue={location.state?.data?.role_id}                  
                      />
                      
                    )}
                    rules={{ required: `${t('Donor_is_required')}` }}
                  />
                  {errors?.role_id?.message ? (
                    <Typography color={theme.palette.error.main} fontSize={12}>
                    <>{errors?.role_id?.message}</> 
                    </Typography>
                  ) : null}
              </Grid>
              <Grid item  xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <LabelWithIcon
                  label={`${t('ADDRESS')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6 ml-3'
                />
                <FormInput
                  hideCountButton
                  multipleLine
                  name='address'
                  type='text'
                  placeholder={`${t('ADDRESS')}...`}
                  control={control}
                  className='input-from-text'
                 
                  inputProps={{
                    ...register('address', {}),
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid item  xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                <Grid>
              <LabelWithIcon
                  label={`${t('PASSWORD')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6 ml-3'
                />
                <FormInput
                
                  name='password'
                  type='password'
                  placeholder={`${t('PASSWORD')}...`}
                  control={control}
                  className='input-from'
                  rules={{
                    required: {
                      value:location?.state?.type === 'edit'?false: true,
                      message: `${t('PLEASE_ENTER_YOUR_PASSWORD')}`,
                    },
                  }}
                  inputProps={{
                    ...register('password', {}),
                    onChange: handleChange
                  }}
                  errors={errors}
                  defaultValue={location.state?.data?.password}
                />
                </Grid>
                <Grid mt={2} >
                <LabelWithIcon
                  label={`${t('CONFIRM_YOUR_PASSWORD')} <span style="color: red;font-size:20px;">*</span>`}
                  justifyContent='flex-start'
                  className='mb-6 ml-3'
                  
                />
              </Grid>
              <Grid >
                <FormInput
                  placeholder={`${t('CONFIRM_YOUR_PASSWORD')}`}
                  className='input-from'
                  name='confirmPassword'
                  type='password'
                  control={control}
                  rules={{
                    required: {
                      value: location?.state?.type === 'edit'?false:true,
                      message: `${t('PLEASE_ENTER_CONFIRM_PASSWORD')}`,
                    },
                  }}
                  inputProps={{
                  
                    ...register('confirmPassword'),
                    onChange: handleChange
                  }}
                  errors={errors}
                />
                {!passwordMatch && <Typography color={"red"}>{t('PASSWORD_NOT_MATCH')}</Typography>}
              </Grid>
              
              </Grid>
              <Grid item xs={12} justifyContent='flex-end' display={'flex'} my={5}>
                <CustomButton
                  type='button'
                  text={`${t('Reset')}`}
                  // startIcon={ArrowBackIcon}
                  color={'primary'}
                  className='p-12 mx-10 color-gray'
                  onClick={handleReset}
                />
                <CustomButton
                  type='submit'
                  // startIcon={AddIcon}
                  text='Save Team Member'
                  color={'warning'}
                  disabled={!passwordMatch}
                  className='p-12'
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      );
    };

    export default TeamMemberForm;
